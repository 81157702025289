import { Alert, Grid } from "@mui/material";
import { Modal, IModalProps } from "../../../../components/core/Modal";
import { FormSelect } from "../../../../components/hook-form/FormSelect";
import { FormFieldLabel } from "../../../../components/hook-form/FormFieldLabel";
import { useArchiveDataModal } from "../useArchiveDataModal";
import { ConfirmModal } from "../ConfirmModal";
import Button from "@mui/material/Button";
import { Spinner } from "../../../../components/core/Spinner";
import BackButton from "../../../../components/core/BackButton";
import { ButtonWrapper } from "../../../../components/core/EntityTable/index.style";
import { FormFieldInfo } from "../../../../components/core/FormFieldInfo/FormFieldInfo";

export interface ArchiveDataESModalProps {
  open: IModalProps["open"];
  onClose: IModalProps["handleClose"];
}

export const ArchiveDataESModal = (props: ArchiveDataESModalProps) => {
  const { open, onClose } = props;

  const {
    onSubmit,
    isOpen,
    control,
    executeRes,
    years,
    yearsIsFetching,
    yearsSelectOptions,
    yearsError,
    handleCloseArchiveModal,
    isExecuting,
    handleChange,
    handleCloseConfirmModal,
    handleOpenConfirmModal,
    executeError,
    shouldRenderExecuteBtn,
  } = useArchiveDataModal({
    country: "ES",
    onClose: onClose,
  });

  return (
    <Modal
      title="TTDF Archive Data ES"
      open={open}
      handleClose={onClose}
      actions={
        <ButtonWrapper>
          {shouldRenderExecuteBtn && (
            <Button variant="contained" onClick={handleOpenConfirmModal}>
              Execute
            </Button>
          )}
          <BackButton
            onClick={handleCloseArchiveModal}
            disabled={isExecuting}
          />
        </ButtonWrapper>
      }
    >
      <ConfirmModal
        openModal={isOpen}
        handleCloseModal={handleCloseConfirmModal}
        isExecuting={isExecuting}
        error={executeError}
        executeRes={executeRes}
        form="archive-data-es"
      />
      {yearsError && <Alert severity="error">{yearsError}</Alert>}
      {yearsIsFetching && <Spinner height="65px" width="600px" />}
      {!yearsError && !yearsIsFetching && (
        <form
          id="archive-data-es"
          data-testid="archive-data-es"
          onSubmit={onSubmit}
          style={{ marginTop: "20px" }}
        >
          <Grid container justifyContent="center">
            <Grid item container xs={6} flexDirection="column" rowSpacing={2}>
              <Grid item>
                <FormSelect
                  control={control}
                  name="chooseYear"
                  label={
                    <FormFieldLabel isRequired>Choose Year</FormFieldLabel>
                  }
                  options={yearsSelectOptions}
                  fullWidth
                  handleChange={handleChange}
                  size="small"
                  autoComplete="off"
                  rules={{ required: "Please select a year." }}
                />
                {years && years.length === 0 && (
                  <FormFieldInfo children="No data ES to archive" />
                )}
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Modal>
  );
};
