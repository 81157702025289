export const calculateDifference = ({
  balanceImport,
  adjustedBalance,
}: {
  balanceImport: number;
  adjustedBalance: number;
}) => Math.round((balanceImport - adjustedBalance) * 100) / 100;

export const calculateAdjustedBalance = ({
  zhr,
  sg,
  hk,
  lux,
  mco,
  bhs,
  dailyBalance,
}: {
  zhr: number;
  sg: number;
  hk: number;
  lux: number;
  mco: number;
  bhs: number;
  dailyBalance: number;
}) =>
  Math.round((dailyBalance - (zhr + sg + hk + lux + mco + bhs)) * 100) / 100;
