export const defaultValues = {
  amount: "",
  exoCode: "",
  taxFlag: "",
  taxAmount: "",
  quantityTransferred: "",
  quantityTransferredNet: "",
  amountOfAcquisition: "",
  taxBaseDeclared: "",
  intCom: "",
  extCom: "",
};

export const extendedDefaultValues = {
  ...defaultValues,
  trxReference: "",
  typeOfTrans: "",
  bc: "",
  nettedTaFlag: false,
  isin: "",
  tradeDate: "",
  setImpDate: "",
  loadDate: "",
  quantity: "",
  unit: "",
  placeOfTrade: "",
  land: "",
  reportDate: "",
  fileBc: "",
  safekeepCountry: "",
  exoMapping: "",
};
