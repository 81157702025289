import { Breadcrumbs } from "../../components/core/Breadcrumbs";
import { UpsertReconciliationMappingForm } from "../../features/parameters/reconciliation-mapping/UpsertReconciliationMappingForm";
import { AuthenticatedLayout } from "../../features/user/layouts/AuthendicatedLayout";

export const CreateReconciliationMapping = () => {
  return (
    <AuthenticatedLayout>
      <Breadcrumbs />
      <UpsertReconciliationMappingForm />
    </AuthenticatedLayout>
  );
};
