import React from "react";
import { AuthenticatedLayout } from "../../features/user/layouts/AuthendicatedLayout";
import { Breadcrumbs } from "../../components/core/Breadcrumbs";
import { UpsertImportFileMappingForm } from "../../features/parameters/import-file-mapping/UpsertImportFileMappingForm";

export const CreateImportFileMapping = (): React.ReactElement => {
  return (
    <AuthenticatedLayout>
      <Breadcrumbs />
      <UpsertImportFileMappingForm />
    </AuthenticatedLayout>
  );
};

export default CreateImportFileMapping;
