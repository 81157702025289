import { useState, useMemo, useCallback, ReactNode } from "react";
import { visuallyHidden } from "@mui/utils";
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
} from "@mui/material";
import { SortConfig, SortOrder } from "./types";
import { useTable } from "./useTable";

export interface HeadProps {
  sortConfig: SortConfig;
  additionalHeader?: ReactNode;
}

export const Head = ({ sortConfig, additionalHeader }: HeadProps) => {
  const { columns, setPage } = useTable();
  const [order, setOrder] = useState<SortOrder>(sortConfig.order ?? "asc");
  const [orderBy, setOrderBy] = useState(sortConfig.field ?? columns[0].id);

  const sortingEnabled = useMemo(() => {
    return sortConfig.enabled ?? true;
  }, [sortConfig.enabled]);

  const handleColumnSort = useCallback(
    (field: string) => (_event: React.MouseEvent<HTMLSpanElement>) => {
      const direction = order === "asc" ? "desc" : "asc";
      setOrder(direction);
      setOrderBy(field);
      setPage(0);

      if (sortingEnabled && sortConfig?.onOrderChange) {
        sortConfig.onOrderChange(field, direction);
      }
    },
    [order, setPage, sortConfig, sortingEnabled]
  );

  const GroupLabels = useMemo(
    () =>
      columns.map((column) => {
        if (column.id) {
          return (
            <TableCell
              key={column.id}
              align={column.numeric ? "right" : "left"}
              sortDirection={orderBy === column.id ? order : false}
              sx={{ minWidth: column?.minWidth }}
            >
              {!column.sortDisabled ? (
                <TableSortLabel
                  active={sortingEnabled && orderBy === column.id}
                  direction={orderBy === column.id ? order : "asc"}
                  onClick={handleColumnSort(column.id)}
                >
                  {column.label}
                  {orderBy === column.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                column.label
              )}
            </TableCell>
          );
        }

        return null;
      }),
    [columns, handleColumnSort, order, orderBy, sortingEnabled]
  );

  return (
    <TableHead>
      {additionalHeader}
      <TableRow>
        <TableCell padding="checkbox" />
        {GroupLabels}
      </TableRow>
    </TableHead>
  );
};

Head.defaultProps = {
  sortConfig: {
    order: "asc",
    field: "id",
    enabled: true,
  },
};
