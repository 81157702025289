import { UserControllerService } from "../../../openapi";
import { useEffect, useState } from "react";
import { mapGrantsFuncToUserPermissions } from "../utils/userPermissionUtils";
import authorizedCall from "../../../utils/authorizedCall";
import { UserPermissions } from "../types";

export const useUserPermissions = () => {
  const [userPermissions, setUserPermissions] =
    useState<UserPermissions>(undefined);
  const [userPermissionsIsFetching, setUserPermissionsIsFetching] =
    useState(false);
  const [userPermissionsError, setUserPermissionsError] = useState("");

  useEffect(() => {
    setUserPermissionsIsFetching(true);
    authorizedCall(UserControllerService.getGrantsAndFunctions, {
      size: 100,
    })
      .then((data) => {
        const userActions = mapGrantsFuncToUserPermissions(data);
        setUserPermissionsIsFetching(false);
        setUserPermissions(userActions);
      })
      .catch((error) => {
        setUserPermissionsIsFetching(false);
        setUserPermissionsError(error.message);
      });
  }, []);

  return {
    userPermissions,
    userPermissionsIsFetching,
    userPermissionsError,
  };
};

export default useUserPermissions;
