const isValidYearMonth = (month: number): boolean => month >= 1 && month <= 12;

const isValidDay = (day: number): boolean => day >= 1 && day <= 31;

const isLeapYear = (year: number): boolean =>
  year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);

export const isValidDate = (
  dateString: string,
  format: "YYYYMMDD" | "YYYYMM"
) => {
  if (isNaN(Number(dateString))) {
    return false;
  }

  const year = parseInt(dateString.slice(0, 4));
  const month = parseInt(dateString.slice(4, 6));
  const day = parseInt(dateString.slice(6, 8));

  const isValidYearMonthResult = isValidYearMonth(month);

  if (!isValidYearMonthResult) {
    return false;
  }

  if (format === "YYYYMMDD") {
    const isValidDayResult = isValidDay(day);

    if (!isValidDayResult) return false;

    if (month === 2) {
      const isLeapYearResult = isLeapYear(year);
      if (day > 29 || (day === 29 && !isLeapYearResult)) {
        return false;
      }
    } else if ([4, 6, 9, 11].includes(month) && day > 30) {
      return false;
    }
  } else if (format === "YYYYMM" && !/^\d{6}$/.test(dateString)) {
    return false;
  }

  return true;
};
