import { Grid, SelectChangeEvent } from "@mui/material";
import { FormInput } from "../../../../components/hook-form/FormInput";
import { FormSelect } from "../../../../components/hook-form/FormSelect";
import { FormCheckbox } from "../../../../components/hook-form/FormCheckbox";
import { FormFieldLabel } from "../../../../components/hook-form/FormFieldLabel";
import { SelectOption } from "../../../../components/core/Select";
import { Control } from "react-hook-form";
import { isValidDate } from "../../../../utils/dateUtils";

export interface IFileImportForm {
  handleSubmit: () => void;
  formatsFieldOptions: SelectOption[];
  control: Control<any>;
  handleFormatSelect: (event: SelectChangeEvent<unknown>) => void;
  handleReportMonthChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleDateCreateChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  shouldDisableInput: boolean;
  shouldDisableCheckbox: boolean;
  handleCheck: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

export const FileImportForm = (props: IFileImportForm) => {
  const {
    handleSubmit,
    formatsFieldOptions,
    control,
    handleFormatSelect,
    handleReportMonthChange,
    handleDateCreateChange,
    shouldDisableInput,
    shouldDisableCheckbox,
    handleCheck,
  } = props;

  return (
    <form
      id="file-import-form"
      data-testid="file-import-form"
      onSubmit={handleSubmit}
      style={{ marginTop: "20px", width: "824px" }}
    >
      <Grid container columnSpacing={2}>
        <Grid item xs={6}>
          <FormSelect
            control={control}
            name="formatId"
            label={<FormFieldLabel isRequired>Choose a file</FormFieldLabel>}
            options={formatsFieldOptions}
            fullWidth
            handleChange={handleFormatSelect}
            size="small"
            autoComplete="off"
            sx={{ mb: 2 }}
            rules={{ required: "Please select a file format." }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            control={control}
            name="reportMonth"
            type="number"
            handleChange={handleReportMonthChange}
            inputProps={{ type: "number" }}
            label={
              <FormFieldLabel isRequired>Report Month (YYYYMM)</FormFieldLabel>
            }
            fullWidth
            size="small"
            hideArrows
            autoComplete="off"
            sx={{ mb: 2 }}
            disabled={shouldDisableInput}
            rules={{
              required: "Please insert a report month.",
              validate: (value) =>
                isValidDate(value, "YYYYMM") ||
                "Please insert a valid Report Month format.",
            }}
          />

          <FormInput
            control={control}
            name="dateCreate"
            label={
              <FormFieldLabel isRequired>Date Create (YYYYMMDD)</FormFieldLabel>
            }
            fullWidth
            handleChange={handleDateCreateChange}
            size="small"
            type="number"
            hideArrows
            inputProps={{ type: "number" }}
            autoComplete="off"
            sx={{ mb: 2 }}
            disabled={shouldDisableInput}
            rules={{
              required: "Please insert a creation date.",
              validate: (value) =>
                isValidDate(value, "YYYYMMDD") ||
                "Please insert a valid Report Month format.",
            }}
          />

          <FormInput
            control={control}
            name="importName"
            label="Import File (Search)"
            fullWidth
            size="small"
            autoComplete="off"
            sx={{ mb: 2 }}
            disabled
          />

          <FormCheckbox
            name="isMissing"
            label="File Missing"
            control={control}
            disabled={shouldDisableCheckbox}
            handleChange={handleCheck}
          />
        </Grid>
      </Grid>
    </form>
  );
};
