import { Breadcrumbs } from "../../components/core/Breadcrumbs";
import { SafekeepingMappingTable } from "../../features/parameters/safekeeping-mapping/SafekeepingMappingTable/SafekeepingMappingTable";
import { AuthenticatedLayout } from "../../features/user/layouts/AuthendicatedLayout";

export const SafeKeepingMapping = () => {
  return (
    <AuthenticatedLayout>
      <Breadcrumbs />
      <SafekeepingMappingTable />
    </AuthenticatedLayout>
  );
};
