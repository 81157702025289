/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CollectionModelUserActionDto } from '../models/CollectionModelUserActionDto';
import type { PagedModelEntityModelGrantAndFunctionDto } from '../models/PagedModelEntityModelGrantAndFunctionDto';
import type { UserActionRequest } from '../models/UserActionRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserControllerService {

    /**
     * Trace a relevant user action on the db for audit purpose, like an insert, update, or something else
     * @returns CollectionModelUserActionDto The traced user action with its assigned id
     * @throws ApiError
     */
    public static addUserAction({
authorization,
requestBody,
}: {
authorization: string,
requestBody: UserActionRequest,
}): CancelablePromise<CollectionModelUserActionDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user-action',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get all the grants and functions related to a role
     * @returns PagedModelEntityModelGrantAndFunctionDto A list of grants and a list of functions related to the role
     * @throws ApiError
     */
    public static getGrantsAndFunctions({
authorization,
page,
size = 10,
sort,
}: {
authorization: string,
/**
 * Zero-based page index (0..N)
 */
page?: number,
/**
 * The size of the page to be returned
 */
size?: number,
/**
 * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
 */
sort?: Array<string>,
}): CancelablePromise<PagedModelEntityModelGrantAndFunctionDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/grants-funcs',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'page': page,
                'size': size,
                'sort': sort,
            },
        });
    }

}
