import { FileExportForm } from "./FileExportForm";
import { Modal, IModalProps } from "../../../../components/core/Modal";
import { useFileExportModal } from "./useFileExportModal";
import { ConfirmModal } from "./ConfirmModal";
import { Button, Alert } from "@mui/material";
import { Spinner } from "../../../../components/core/Spinner";
import BackButton from "../../../../components/core/BackButton";
import { ButtonWrapper } from "../../../../components/core/EntityTable/index.style";

export interface IFileExportModal {
  open: IModalProps["open"];
  onClose: IModalProps["handleClose"];
}

export const FileExportModal = (props: IFileExportModal) => {
  const { open, onClose } = props;

  const {
    control,
    handleSubmit,
    formatsFieldOptions,
    isExecuting,
    executeError,
    formatsError,
    isOpen,
    handleCloseConfirmModal,
    handleOpenConfirmModal,
    handleCloseExportModal,
    handleChange,
    executeRes,
    formatsIsFetching,
  } = useFileExportModal({ onClose });

  return (
    <Modal
      title="TTDF File Export"
      open={open}
      handleClose={handleCloseExportModal}
      actions={
        <ButtonWrapper>
          {!formatsError &&
            !formatsIsFetching &&
            !formatsError &&
            formatsFieldOptions.length > 0 && (
              <Button variant="contained" onClick={handleOpenConfirmModal}>
                Execute
              </Button>
            )}
          <BackButton onClick={handleCloseExportModal} disabled={isExecuting} />
        </ButtonWrapper>
      }
    >
      <ConfirmModal
        openModal={isOpen}
        handleCloseModal={handleCloseConfirmModal}
        isExecuting={isExecuting}
        error={executeError}
        executeRes={executeRes}
      />
      {formatsIsFetching && <Spinner height="65px" width="600px" />}
      {formatsError && (
        <Alert severity="error" sx={{ whiteSpace: "pre" }}>
          {formatsError}
        </Alert>
      )}

      {!formatsIsFetching && !formatsError && (
        <FileExportForm
          handleChange={handleChange}
          formatsFieldOptions={formatsFieldOptions}
          control={control}
          handleSubmit={handleSubmit}
        />
      )}
    </Modal>
  );
};
