import { EntityTable } from "../../../../components/core/EntityTable";
import { ReconciliationMappingControllerService } from "../../../../openapi";
import columns from "./config.json";

export const ReconciliationMappingTable = () => (
  <EntityTable
    title="TTDF Reconciliation Mapping"
    columns={columns}
    editRoute="/table/reconciliation-mapping/edit"
    addRoute="/table/reconciliation-mapping/add"
    deleteRoute="/table/reconciliation-mapping/delete"
    getService={
      ReconciliationMappingControllerService.getReconciliationMappings
    }
    deleteService={
      ReconciliationMappingControllerService.deleteReconciliationMapping
    }
  />
);
