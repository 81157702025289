import { LoadingButton } from "@mui/lab";
import { Box, Alert } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Modal } from "../../../components/core/Modal";
import BackButton from "../../../components/core/BackButton";
import { ButtonWrapper } from "../../../components/core/EntityTable/index.style";
import { ArchiveDataRes } from "./types";

type ConfirmModalProps = {
  handleCloseModal: () => void;
  openModal: boolean;
  error: string;
  isExecuting: boolean;
  executeRes?: ArchiveDataRes;
  form: string;
};

export const ConfirmModal = ({
  handleCloseModal,
  openModal,
  error,
  isExecuting,
  executeRes,
  form,
}: ConfirmModalProps) => {
  return (
    <Modal
      title={<Box sx={{ display: "flex", alignItems: "center" }}>TTDF</Box>}
      open={openModal}
      minWidth={500}
      handleClose={handleCloseModal}
      actions={
        <ButtonWrapper>
          {!error && !executeRes && (
            <LoadingButton
              autoFocus
              variant="contained"
              color="primary"
              loading={isExecuting}
              type="submit"
              id="archive-button"
              form={form}
            >
              Execute
            </LoadingButton>
          )}
          <BackButton onClick={handleCloseModal} disabled={isExecuting} />
        </ButtonWrapper>
      }
    >
      {error && <Alert severity="error">{error}</Alert>}
      {executeRes && (
        <Box>
          {executeRes?.success ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <InfoIcon color="primary" sx={{ mr: "5px" }} fontSize="large" />
              {executeRes?.message}
            </Box>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ErrorOutlineIcon
                color="error"
                sx={{ mr: "5px" }}
                fontSize="large"
              />
              {executeRes?.message}
            </Box>
          )}
        </Box>
      )}
      {!executeRes && !error && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <InfoIcon color="primary" sx={{ mr: "5px" }} fontSize="large" />
          Would you like to ARCHIVE the data ?
        </Box>
      )}
    </Modal>
  );
};
