import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs } from "../../components/core/Breadcrumbs";
import { UpsertAdjustmentForm } from "../../features/adjustment/components/UpsertAdjustmentForm";
import { AuthenticatedLayout } from "../../features/user/layouts/AuthendicatedLayout";

export const CreateAdjustment = () => {
  const { countryTypeId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!countryTypeId) {
      navigate("/");
    }
  }, [countryTypeId, navigate]);

  return (
    <AuthenticatedLayout>
      <Breadcrumbs />
      <UpsertAdjustmentForm countryTypeId={countryTypeId} />
    </AuthenticatedLayout>
  );
};
