import {
  CancelablePromise,
  EntityModelFileImportMapping,
  EntityModelFiscalYearDto,
  EntityModelTaxFormOutputDto,
  FileImportControllerService,
  NonStandardTaxDto,
  TaxFormControllerService,
} from "../../../../openapi";
import authorizedCall from "../../../../utils/authorizedCall";
import { SelectOption } from "../../../../components/core/Select";
import { aggregateStandardData, prepareNonStandardData } from "./utils";

export type FakeApiResponse<T> = {
  _embedded: {
    entities: Array<T>;
  };
  page: {
    totalElements: number;
  };
};

export type AggregatedStandardTaxDto = {
  id?: number;
  month?: string;
  exclusionsCode?: string;
  exclusionsDescription?: string;
  p1?: "Share";
  p2?: "Derivate";
  p1CashAmountCnt?: number;
  p2CashAmountCnt?: number;
  p1CashAmount?: number;
  p2CashAmount?: number;
  p1TaxAmount?: number;
  p2TaxAmount?: number;
};

export type PreparedNonStandardTaxDto = NonStandardTaxDto & {
  id?: number;
};

export type TaxForm = {
  standardTaxList: Array<AggregatedStandardTaxDto>;
  nonStandardTaxList: Array<PreparedNonStandardTaxDto>;
  totalAmount: number;
};

let serviceCache: TaxForm | undefined = undefined;

const getTaxFormAPICall = async (): Promise<EntityModelTaxFormOutputDto> => {
  const data = await authorizedCall(
    TaxFormControllerService.getTaxForms,
    {},
    true
  );
  serviceCache = {
    standardTaxList: aggregateStandardData(data?.standardTaxList) ?? [],
    nonStandardTaxList: prepareNonStandardData(data?.nonStandardTaxList) ?? [],
    totalAmount: data?.totalAmountOwed ?? 0,
  };
  return data;
};

export const clearCache = () => {
  serviceCache = undefined;
};

export const getStandardTaxList = async (): CancelablePromise<
  FakeApiResponse<AggregatedStandardTaxDto>
> => {
  if (!serviceCache) {
    await getTaxFormAPICall();
  }

  const list = serviceCache?.standardTaxList ?? [];
  return {
    _embedded: {
      entities: list,
    },
    page: { totalElements: list.length },
  };
};

export const getNonStandardTaxList = async (): CancelablePromise<
  FakeApiResponse<PreparedNonStandardTaxDto>
> => {
  if (!serviceCache) {
    await getTaxFormAPICall();
  }

  const list = serviceCache?.nonStandardTaxList ?? [];
  return {
    _embedded: {
      entities: list,
    },
    page: { totalElements: list.length },
  };
};

export const getTaxForm = async (): Promise<TaxForm> => {
  if (!serviceCache) {
    await getTaxFormAPICall();
  }
  return serviceCache!;
};

export const getBookingCenters = async (): Promise<SelectOption[]> => {
  const data: EntityModelFileImportMapping[] = await authorizedCall(
    FileImportControllerService.getFileImportMapping,
    {}
  );

  const bcs: SelectOption[] = [];

  const filtered = data.filter(
    (i, index, self) =>
      i.taxformName &&
      index ===
        self.findIndex(
          (t) =>
            t.taxformName === i.taxformName &&
            t.taxformBookingCenter === i.taxformBookingCenter
        )
  );

  for (const bc of filtered) {
    bcs.push({
      value: bc.taxformBookingCenter ?? "",
      label: bc.taxformName + " | " + bc.taxformBookingCenter,
    });
  }

  return bcs;
};

export const getFiscalYears = async (): Promise<SelectOption[]> => {
  const data: EntityModelFiscalYearDto[] = await authorizedCall(
    TaxFormControllerService.getFiscalYears,
    {}
  );

  const yearsOptions: SelectOption[] =
    data?.map((obj) => ({
      value: String(obj.year),
      label: String(obj.year),
    })) ?? [];

  return yearsOptions;
};

export const postTaxRecalculation = async (
  bookingCenter: string,
  fiscalYear: number
): Promise<EntityModelTaxFormOutputDto> => {
  const data = await authorizedCall(
    TaxFormControllerService.recalculateTax,
    { requestBody: { bookingCenter, fiscalYear } },
    true
  );
  serviceCache = {
    standardTaxList: aggregateStandardData(data?.standardTaxList) ?? [],
    nonStandardTaxList: prepareNonStandardData(data?.nonStandardTaxList) ?? [],
    totalAmount: data?.totalAmountOwed ?? 0,
  };
  return data;
};
