/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagedModelEntityModelReconciliation } from '../models/PagedModelEntityModelReconciliation';
import type { ReconciliationDto } from '../models/ReconciliationDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReconciliationControllerService {

    /**
     * Updates the offset field and calculates the new reconciliation
     * @returns any Reconciliation updated
     * @throws ApiError
     */
    public static updateReconciliation({
authorization,
reconciliationId,
requestBody,
}: {
authorization: string,
reconciliationId: number,
requestBody: ReconciliationDto,
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/reconciliations/{reconciliationId}',
            path: {
                'reconciliationId': reconciliationId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Unauthorized user`,
                404: `Reconciliation record not found`,
            },
        });
    }

    /**
     * Returns a paged list of Reconciliation entities
     * @returns PagedModelEntityModelReconciliation Paged list of Reconciliation entities
     * @throws ApiError
     */
    public static getReconciliations({
authorization,
page,
size = 10,
sort,
search,
offsetMco,
offsetHk,
archive,
tradeDate,
offsetSg,
imported,
dailyBalance,
reconciliationMapping,
comment,
id,
offsetBhs,
balanceImport,
offsetLux,
account,
offsetZrh,
status,
}: {
authorization: string,
/**
 * Zero-based page index (0..N)
 */
page?: number,
/**
 * The size of the page to be returned
 */
size?: number,
/**
 * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
 */
sort?: Array<string>,
search?: string,
offsetMco?: number,
offsetHk?: number,
archive?: boolean,
tradeDate?: string,
offsetSg?: number,
imported?: boolean,
dailyBalance?: number,
reconciliationMapping?: string,
comment?: string,
id?: number,
offsetBhs?: number,
balanceImport?: number,
offsetLux?: number,
account?: string,
offsetZrh?: number,
status?: boolean,
}): CancelablePromise<PagedModelEntityModelReconciliation> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/reconciliations',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'page': page,
                'size': size,
                'sort': sort,
                'search': search,
                'offsetMco': offsetMco,
                'offsetHk': offsetHk,
                'archive': archive,
                'tradeDate': tradeDate,
                'offsetSg': offsetSg,
                'imported': imported,
                'dailyBalance': dailyBalance,
                'reconciliationMapping': reconciliationMapping,
                'comment': comment,
                'id': id,
                'offsetBhs': offsetBhs,
                'balanceImport': balanceImport,
                'offsetLux': offsetLux,
                'account': account,
                'offsetZrh': offsetZrh,
                'status': status,
            },
            errors: {
                403: `Unauthorized user`,
            },
        });
    }

}
