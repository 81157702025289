import { EntityTable } from "../../../../components/core/EntityTable";
import { SafeKeepingMappingControllerService } from "../../../../openapi";
import columns from "./config.json";

export const SafekeepingMappingTable = () => (
  <EntityTable
    title="TTDF Safekeeping Mapping"
    columns={columns}
    editRoute="/table/safekeeping-mapping/edit"
    addRoute="/table/safekeeping-mapping/add"
    deleteRoute="/table/safekeeping-mapping/delete"
    getService={SafeKeepingMappingControllerService.getSafeKeepingMapping}
    deleteService={SafeKeepingMappingControllerService.deleteSafeKeepingMapping}
  />
);
