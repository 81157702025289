import { useState, useMemo, useEffect } from "react";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import {
  EntityModelFileImportMapping,
  FileImportControllerService,
} from "../../../../openapi";
import authorizedCall from "../../../../utils/authorizedCall";

export const useAdjustmentTable = () => {
  const [formats, setFormats] = useState<EntityModelFileImportMapping[]>([]);
  const [countryTypeId, setCountryTypeId] = useLocalStorage(
    "countryTypeId",
    "setCountryTypeId"
  );

  useEffect(() => {
    authorizedCall(FileImportControllerService.getFileImportMappingGrouped, {
      size: 100,
      _case: "COUNTRY_TYPE",
    })
      .then((formats: EntityModelFileImportMapping[]) => {
        const filteredFormats = formats.filter(
          (format) => format.adjustmentName !== null
        );
        setFormats(filteredFormats);
      })
      .catch((err) => console.warn(err.body.message));
  }, []);

  const formatsFieldOptions = useMemo(
    () =>
      formats.map(({ id, adjustmentName }) => {
        return {
          value: String(id) ?? "",
          label: String(adjustmentName) ?? "",
        };
      }),
    [formats]
  );

  const appliedFilters = useMemo(() => {
    const formatObj = formats.find(
      (format) => String(format.id) === countryTypeId
    );

    if (!formatObj) return {};

    const { id } = formatObj;

    return {
      fileImportMappingId: id,
    };
  }, [countryTypeId, formats]);

  const handleCountryTypeChange = (event: any) =>
    setCountryTypeId(event.target.value);

  return {
    handleCountryTypeChange,
    appliedFilters,
    formatsFieldOptions,
    countryTypeId,
  };
};
