/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CollectionModelEntityModelProcessOutputDto } from '../models/CollectionModelEntityModelProcessOutputDto';
import type { ExportInputDto } from '../models/ExportInputDto';
import type { PagedModelEntityModelExportFileNameDto } from '../models/PagedModelEntityModelExportFileNameDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExportControllerService {

    /**
     * Lists all available export file names
     * @returns PagedModelEntityModelExportFileNameDto Export file names list
     * @throws ApiError
     */
    public static getExportFiles({
authorization,
page,
size = 10,
sort,
}: {
authorization: string,
/**
 * Zero-based page index (0..N)
 */
page?: number,
/**
 * The size of the page to be returned
 */
size?: number,
/**
 * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
 */
sort?: Array<string>,
}): CancelablePromise<PagedModelEntityModelExportFileNameDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/export',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'page': page,
                'size': size,
                'sort': sort,
            },
            errors: {
                403: `Unauthorized user`,
            },
        });
    }

    /**
     * Executes a file export containing transactions, based on the country
     * @returns CollectionModelEntityModelProcessOutputDto File exported successfully
     * @throws ApiError
     */
    public static exportFile({
authorization,
requestBody,
}: {
authorization: string,
requestBody: ExportInputDto,
}): CancelablePromise<CollectionModelEntityModelProcessOutputDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/export',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Unauthorized user`,
                404: `File not found`,
            },
        });
    }

}
