import { Breadcrumbs } from "../../components/core/Breadcrumbs";
import { UpsertExportParameterForm } from "../../features/parameters/export-parameter/UpsertExportParameterForm";
import { AuthenticatedLayout } from "../../features/user/layouts/AuthendicatedLayout";

export const CreateExportParameter = () => {
  return (
    <AuthenticatedLayout>
      <Breadcrumbs />
      <UpsertExportParameterForm />
    </AuthenticatedLayout>
  );
};
