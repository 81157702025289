import React from "react";
import { AuthenticatedLayout } from "../../features/user/layouts/AuthendicatedLayout";
import { Breadcrumbs } from "../../components/core/Breadcrumbs";
import ExportFileLogTable from "../../features/export/export-file-log/ExportFileLogTable/ExportFileLogTable";

export const ExportFileLog = (): React.ReactElement => {
  return (
    <AuthenticatedLayout>
      <Breadcrumbs />
      <ExportFileLogTable />
    </AuthenticatedLayout>
  );
};

export default ExportFileLog;
