/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ArchiveDto } from '../models/ArchiveDto';
import type { CollectionModelEntityModelProcessOutputDto } from '../models/CollectionModelEntityModelProcessOutputDto';
import type { PagedModelEntityModelArchiveBookingCenterDto } from '../models/PagedModelEntityModelArchiveBookingCenterDto';
import type { PagedModelEntityModelArchiveYearsDto } from '../models/PagedModelEntityModelArchiveYearsDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ArchiveControllerService {

    /**
     * Archives tables referencing on a specific country
     * @returns CollectionModelEntityModelProcessOutputDto Tables archived successfully
     * @throws ApiError
     */
    public static archive({
authorization,
country,
requestBody,
}: {
authorization: string,
country: string,
requestBody: ArchiveDto,
}): CancelablePromise<CollectionModelEntityModelProcessOutputDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/country/{country}/archive',
            path: {
                'country': country,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Unauthorized user`,
            },
        });
    }

    /**
     * Returns a paged list of years available to be used in a specific country archive operation.
     * @returns PagedModelEntityModelArchiveYearsDto An object containing a list of years to use as filter
     * @throws ApiError
     */
    public static getArchiveYearsFilter({
authorization,
country,
bookingCenter,
page,
size = 10,
sort,
}: {
authorization: string,
country: string,
bookingCenter?: string,
/**
 * Zero-based page index (0..N)
 */
page?: number,
/**
 * The size of the page to be returned
 */
size?: number,
/**
 * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
 */
sort?: Array<string>,
}): CancelablePromise<PagedModelEntityModelArchiveYearsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/country/{country}/archive-years',
            path: {
                'country': country,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'bookingCenter': bookingCenter,
                'page': page,
                'size': size,
                'sort': sort,
            },
            errors: {
                403: `Unauthorized user`,
            },
        });
    }

    /**
     * Returns a paged list of years available to be used in a specific country archive operation.
     * @returns PagedModelEntityModelArchiveBookingCenterDto An object containing a list of booking center to use as filter
     * @throws ApiError
     */
    public static getArchiveBookingCenter({
authorization,
page,
size = 10,
sort,
}: {
authorization: string,
/**
 * Zero-based page index (0..N)
 */
page?: number,
/**
 * The size of the page to be returned
 */
size?: number,
/**
 * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
 */
sort?: Array<string>,
}): CancelablePromise<PagedModelEntityModelArchiveBookingCenterDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/country/archive-bc',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'page': page,
                'size': size,
                'sort': sort,
            },
            errors: {
                403: `Unauthorized user`,
            },
        });
    }

}
