/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagedModelEntityModelReconciliationMapping } from '../models/PagedModelEntityModelReconciliationMapping';
import type { ReconciliationMappingDto } from '../models/ReconciliationMappingDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReconciliationMappingControllerService {

    /**
     * Returns a paged list of ReconciliationMapping records
     * @returns PagedModelEntityModelReconciliationMapping Paged list of ReconciliationMapping records
     * @throws ApiError
     */
    public static getReconciliationMappings({
authorization,
page,
size = 10,
sort,
search,
country,
fileBookingCenter,
bookingCenter,
comment,
id,
fileType,
account,
}: {
authorization: string,
/**
 * Zero-based page index (0..N)
 */
page?: number,
/**
 * The size of the page to be returned
 */
size?: number,
/**
 * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
 */
sort?: Array<string>,
search?: string,
country?: string,
fileBookingCenter?: string,
bookingCenter?: string,
comment?: string,
id?: number,
fileType?: number,
account?: string,
}): CancelablePromise<PagedModelEntityModelReconciliationMapping> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/mapping/reconciliation',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'page': page,
                'size': size,
                'sort': sort,
                'search': search,
                'country': country,
                'fileBookingCenter': fileBookingCenter,
                'bookingCenter': bookingCenter,
                'comment': comment,
                'id': id,
                'fileType': fileType,
                'account': account,
            },
            errors: {
                403: `Unauthorized user`,
            },
        });
    }

    /**
     * Adds a ReconciliationMapping record
     * @returns PagedModelEntityModelReconciliationMapping Record inserted successfully
     * @throws ApiError
     */
    public static addReconciliationMapping({
authorization,
requestBody,
}: {
authorization: string,
requestBody: ReconciliationMappingDto,
}): CancelablePromise<PagedModelEntityModelReconciliationMapping> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/mapping/reconciliation',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Unauthorized user`,
            },
        });
    }

    /**
     * Delete an existing ExemptionCodeMapping record
     * @returns void 
     * @throws ApiError
     */
    public static deleteReconciliationMapping({
authorization,
id,
}: {
authorization: string,
id: number,
}): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/mapping/reconciliation/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                403: `Unauthorized user`,
                404: `Record not found`,
            },
        });
    }

    /**
     * Updates an existing ReconciliationMapping record
     * @returns void 
     * @throws ApiError
     */
    public static updateReconciliationMapping({
authorization,
id,
requestBody,
}: {
authorization: string,
id: number,
requestBody: ReconciliationMappingDto,
}): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/mapping/reconciliation/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Unauthorized user`,
                404: `Record not found`,
            },
        });
    }

}
