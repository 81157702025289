import { useState, useEffect, useCallback, useMemo } from "react";
import {
  EntityModelFileImportMapping,
  FileImportControllerService,
  InsertFileImportMappingDto,
} from "../../../../openapi";
import { useForm, SubmitHandler } from "react-hook-form";
import authorizedCall from "../../../../utils/authorizedCall";
import { WithNull } from "../../../../types/Utility";
import { useNavigate } from "react-router-dom";

export interface UseUpsertImportFileMappingFormParams {
  id?: string;
}

export interface UpsertInputFileMappingFormInputs {
  importName: string;
  fileName: string;
  country: string;
  bookingCenter: string;
}

export const useUpsertImportFileMapping = (
  params: UseUpsertImportFileMappingFormParams
) => {
  const navigate = useNavigate();
  const [importFileMapping, setImportFileMapping] =
    useState<EntityModelFileImportMapping>();
  const [importFileMappingError, setImportFileMappingError] = useState("");
  const [importFileMappingIsFetching, setImportFileMappingIsFetching] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccessfull, setIsSubmitSuccessfull] = useState(false);
  const [submitError, setSubmittingError] = useState("");

  const { id } = params;

  const defaultValues = useMemo(
    () => ({
      importName: "",
      fileName: "",
      country: "",
      bookingCenter: "",
    }),
    []
  );

  const form = useForm<UpsertInputFileMappingFormInputs>({
    defaultValues,
  });

  const { reset, handleSubmit, control, formState } = form;

  const handleBack = useCallback(() => {
    navigate("/table/import-file-mapping");
  }, [navigate]);

  useEffect(() => {
    if (id) {
      setImportFileMappingIsFetching(true);
      authorizedCall(FileImportControllerService.getFileImportMapping, {
        id: Number(id),
      })
        .then((data) => {
          const _fileImportMapping = (
            data as EntityModelFileImportMapping[]
          )[0];

          setImportFileMapping(_fileImportMapping);

          reset({
            importName: _fileImportMapping.importName ?? "",
            fileName: _fileImportMapping.fileName ?? "",
            country: _fileImportMapping.country ?? "",
            bookingCenter: _fileImportMapping.bookingCenter ?? "",
          });
        })
        .catch((error) => {
          setImportFileMappingError(error.message);
        })
        .finally(() => {
          setImportFileMappingIsFetching(false);
        });
    }
  }, [id, reset]);

  useEffect(() => {
    if (submitSuccessfull && !id) {
      reset(defaultValues);
    }
  }, [reset, submitSuccessfull, defaultValues, id]);

  const submitSuccessMessage = importFileMapping
    ? "Data has been updated correctly"
    : "Record inserted";

  const cardTitle = importFileMapping
    ? "Edit Import File Mapping"
    : "Add Import File Mapping";

  const onSubmit: SubmitHandler<UpsertInputFileMappingFormInputs> = useCallback(
    (data) => {
      const { importName, fileName, country, bookingCenter } = data;

      const bodyReq: WithNull<EntityModelFileImportMapping> = {
        importName: null,
        fileName: null,
        country: null,
        bookingCenter: null,
      };

      setIsSubmitSuccessfull(false);
      setIsSubmitting(true);
      setSubmittingError("");

      if (importName) bodyReq.importName = importName;
      if (fileName) bodyReq.fileName = fileName;
      if (country) bodyReq.country = country;
      if (bookingCenter) bodyReq.bookingCenter = bookingCenter;

      if (id) {
        authorizedCall(FileImportControllerService.updateFileImportMapping, {
          id: Number(id),
          requestBody: bodyReq as InsertFileImportMappingDto,
        })
          .then(() => {
            setIsSubmitting(false);
            setIsSubmitSuccessfull(true);
          })
          .catch((err) => {
            setIsSubmitting(false);
            setSubmittingError(err.body.message);
          });
      } else {
        authorizedCall(FileImportControllerService.addFileImportMapping, {
          requestBody: bodyReq as InsertFileImportMappingDto,
        })
          .then(() => {
            setIsSubmitting(false);
            setIsSubmitSuccessfull(true);
          })
          .catch((err) => {
            setIsSubmitting(false);
            setSubmittingError(err.body.message);
          });
      }
    },
    [id]
  );

  return {
    isSubmitting,
    formState,
    submitError,
    submitSuccessfull,
    control,
    cardTitle,
    submitSuccessMessage,
    importFileMapping,
    importFileMappingError,
    importFileMappingIsFetching,
    setImportFileMappingError,
    onSubmit: handleSubmit(onSubmit),
    onBack: handleBack,
  };
};

export default useUpsertImportFileMapping;
