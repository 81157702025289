import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../features/user/hooks/useUser";
import useUserPermissions from "../../../features/user/hooks/useUserPermissions";
import { hasRoutePermission } from "../../../features/user/utils/userPermissionUtils";
import {
  ITableItem,
  ITableHeader,
  SortOrder,
  SortConfig,
} from "../Table/types";
import { Service } from "./types";

export interface UseEntityTableParams {
  editRoute?: string;
  addRoute?: string;
  addRoutePathParams?: string;
  deleteRoute?: string;
  getService: Service;
  deleteService?: Service;
  columns: ITableHeader[];
  filters?: any;
  fetchDataOnMount?: boolean;
  sortConfig?: SortConfig;
}

export const useEntityTable = (params: UseEntityTableParams) => {
  const [items, setItems] = useState<ITableItem[]>([]);
  const [isFetchingItems, setIsFetchingItems] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<ITableItem>();
  const [enabledActions, setEnabledActions] = useState(false);
  const [sortOrder, setSortOrder] = useState<SortOrder>(
    params.sortConfig?.order ?? "asc"
  );
  const [orderByField, setOrderByField] = useState(
    params.sortConfig?.field ?? params.columns[1].id
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const {
    getService,
    editRoute,
    addRoute,
    deleteService,
    deleteRoute,
    addRoutePathParams,
    columns,
    filters,
    fetchDataOnMount = true,
  } = params;

  const { token } = useUser();
  const navigate = useNavigate();
  const { userPermissions } = useUserPermissions();

  const getData = useCallback(() => {
    if (token) {
      let _params: any = {
        authorization: `Bearer ${token}`,
        size: pageSize,
        page,
      };

      if (orderByField !== columns[0].id) {
        _params.sort = orderByField + "," + sortOrder;
      }

      if (searchTerm !== "") {
        _params.search = searchTerm;
      }

      if (filters) {
        _params = {
          ..._params,
          ...filters,
        };
      }

      setIsFetchingItems(true);

      getService(_params)
        .then((data: any) => {
          setIsFetchingItems(false);

          if (
            totalItems === 0 ||
            totalItems !== data._embedded?.entities?.length
          ) {
            setTotalItems(data.page.totalElements);
          }

          setItems(data._embedded?.entities);
        })
        .catch(() => {
          setIsFetchingItems(false);
        });
    }
  }, [
    columns,
    filters,
    getService,
    orderByField,
    page,
    pageSize,
    searchTerm,
    sortOrder,
    token,
    totalItems,
  ]);

  useEffect(() => {
    if (fetchDataOnMount || Object.keys(filters).length > 0) {
      getData();
    }
  }, [fetchDataOnMount, filters, getData]);

  const canEditItem = useMemo(() => {
    if (!editRoute) {
      return false;
    }
    return hasRoutePermission(userPermissions, editRoute);
  }, [editRoute, userPermissions]);

  const canDeleteItem = useMemo(() => {
    if (!deleteRoute) {
      return false;
    }

    return hasRoutePermission(userPermissions, deleteRoute);
  }, [deleteRoute, userPermissions]);

  const canAddItem = useMemo(() => {
    if (!addRoute) {
      return false;
    }
    return hasRoutePermission(userPermissions, addRoute);
  }, [addRoute, userPermissions]);

  const handleOpenModal = () => {
    setError("");
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const onEditItem = useCallback(
    () => navigate(`${editRoute}/${selectedRow?.id}`),
    [navigate, selectedRow?.id, editRoute]
  );

  const onAddItem = useCallback(
    () =>
      navigate(
        `${addRoute}${addRoutePathParams ? "/" + addRoutePathParams : ""}`
      ),
    [addRoute, addRoutePathParams, navigate]
  );

  const onDeleteItem = useCallback(() => {
    if (token) {
      setIsDeleting(true);
      deleteService?.({ id: selectedRow?.id, authorization: `Bearer ${token}` })
        .then(() => {
          getData();
          handleCloseModal();
          setSelectedRow(undefined);
          setEnabledActions(false);
        })
        .catch((err) => {
          setError(err?.body?.message);
        })
        .finally(() => {
          setIsDeleting(false);
        });
    }
  }, [deleteService, getData, selectedRow?.id, token]);

  const onBackItem = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onRowSelect = (newSelected: ITableItem, enabled: boolean) => {
    setSelectedRow(newSelected);
    setEnabledActions(enabled);
  };

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const onSortOrderChange = (newOrderByField: string, sortOrder: SortOrder) => {
    setOrderByField(newOrderByField);
    setSortOrder(sortOrder);
  };

  const onSearchTermChange = useCallback(
    (searched: string) => {
      if (page !== 0) {
        setPage(0);
      }
      setSearchTerm(searched);
    },
    [page]
  );

  return {
    items,
    setItems,
    pageSize,
    page,
    canEditItem,
    selectedRow,
    enabledActions,
    sortOrder,
    orderByField,
    searchTerm,
    totalItems,
    setPage,
    onEditItem,
    onRowSelect,
    onPageSizeChange,
    onSortOrderChange,
    onSearchTermChange,
    onAddItem,
    handleCloseModal,
    handleOpenModal,
    openModal,
    onDeleteItem,
    canDeleteItem,
    canAddItem,
    onBackItem,
    error,
    isDeleting,
    rowsPerPage,
    setRowsPerPage,
    isFetchingItems,
  };
};
