import { EntityTable } from "../../../../components/core/EntityTable";
import { ExportParameterMappingControllerService } from "../../../../openapi";
import columns from "./config.json";

export const ExportParameterTable = () => (
  <EntityTable
    title="TTDF Export Parameter"
    columns={columns}
    editRoute="/table/export-parameter/edit"
    addRoute="/table/export-parameter/add"
    deleteRoute="/table/export-parameter/delete"
    getService={
      ExportParameterMappingControllerService.getExportParameterMappings
    }
    deleteService={
      ExportParameterMappingControllerService.deleteExportParameterMapping
    }
  />
);
