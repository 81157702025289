import React from "react";
import { useUpsertExemptionCodeMappingForm } from "./useUpsertExemptionCodeMappingForm";
import { Alert, Box, Grid } from "@mui/material";
import { Spinner } from "../../../../components/core/Spinner";
import { FormInput } from "../../../../components/hook-form/FormInput";
import { FormFieldLabel } from "../../../../components/hook-form/FormFieldLabel";
import { AlertMessage } from "../../../../components/core/AlertMessage";
import { Card } from "../../../../components/core/Card";
import { FormSelect } from "../../../../components/hook-form/FormSelect";
import EditActionButtons from "../../../../components/core/EditActionButtons";

export type UpsertExemptionCodeMappingProps = {
  id?: string;
};

export const UpsertExemptionCodeMappingForm = (
  props: UpsertExemptionCodeMappingProps
): React.ReactElement => {
  const { id } = props;

  const {
    exemptionCodeMapping,
    exemptionCodeMappingError,
    exemptionCodeMappingIsFetching,
    submitSuccessMessage,
    exemptionCodesIsFetching,
    exemptionCodesError,
    cardTitle,
    onSubmit,
    onBack,
    isSubmitting,
    submitSuccessfull,
    submitError,
    control,
    exemptionCodesOptions,
    onExemptionCodeSelected,
  } = useUpsertExemptionCodeMappingForm({ id });

  return (
    <Card
      title={cardTitle}
      style={{ width: "1512px" }}
      actions={
        (exemptionCodeMapping || !id) && (
          <EditActionButtons
            form="upsert-exemption-code-mapping-form"
            loading={isSubmitting}
            onBack={onBack}
          />
        )
      }
      Component={() => (
        <Box sx={{ margin: "20px" }}>
          {exemptionCodeMappingError && (
            <Alert severity={"error"}>{exemptionCodeMappingError}</Alert>
          )}

          {exemptionCodesError && (
            <Alert severity={"error"}>{exemptionCodesError}</Alert>
          )}

          {(exemptionCodeMappingIsFetching || exemptionCodesIsFetching) && (
            <Spinner height={"353px"} />
          )}
          {(exemptionCodeMapping || !id) &&
            exemptionCodesOptions.length > 0 && (
              <form
                id={"upsert-exemption-code-mapping-form"}
                data-testid={"upsert-exemption-code-mapping-form"}
                onSubmit={onSubmit}
              >
                <Grid container columnSpacing={2} rowSpacing={5}>
                  <Grid item xs={4}>
                    <FormInput
                      control={control}
                      name="exemptionCodeCountry"
                      label={
                        <FormFieldLabel>Exemption Code Country</FormFieldLabel>
                      }
                      fullWidth
                      autoComplete="off"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormInput
                      control={control}
                      name="exemptionCodeTc"
                      label={<FormFieldLabel>Exemption Code TC</FormFieldLabel>}
                      fullWidth
                      autoComplete="off"
                      rules={{ required: "Please insert Exemption Code TC." }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormSelect
                      control={control}
                      name="exemptionCodeId"
                      options={exemptionCodesOptions}
                      handleChange={onExemptionCodeSelected}
                      label={<FormFieldLabel>Exemption Code</FormFieldLabel>}
                      fullWidth
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
              </form>
            )}

          {submitError && (
            <Alert severity="error" sx={{ mt: "10px" }}>
              {submitError}
            </Alert>
          )}

          <AlertMessage
            show={submitSuccessfull}
            message={submitSuccessMessage}
          />
        </Box>
      )}
    />
  );
};

export default UpsertExemptionCodeMappingForm;
