import { useCallback, useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import authorizedCall from "../../../../utils/authorizedCall";
import {
  EntityModelImportOutputDto,
  EntityModelExportFileNameDto,
  ExportControllerService,
} from "../../../../openapi";

export type FileExportFormInput = {
  formatId: string;
};

type Args = {
  onClose: () => void;
};

export const useFileExportModal = (args: Args) => {
  const [isExecuting, setIsExecuting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [formats, setFormats] = useState<EntityModelExportFileNameDto[]>([]);
  const [formatsIsFetching, setFormatsIsFetching] = useState(false);
  const [formatsError, setFormatsError] = useState("");
  const [executeRes, setExecuteRes] = useState<EntityModelImportOutputDto>();
  const [executeError, setExecuteError] = useState("");

  const { onClose } = args;

  const form = useForm<FileExportFormInput>({
    defaultValues: {
      formatId: "",
    },
  });

  const { handleSubmit, control, trigger, clearErrors, setValue } = form;

  const formatId = useWatch({ control, name: "formatId" });

  const getFormats = useCallback(() => {
    setFormatsIsFetching(true);
    authorizedCall(ExportControllerService.getExportFiles, {
      size: 100,
    })
      .then((data) => {
        setFormats(data.filter((obj: any) => obj.exportName));
      })
      .catch((err) => setFormatsError(err.body.message))
      .finally(() => {
        setFormatsIsFetching(false);
      });
  }, []);

  useEffect(() => {
    getFormats();
  }, [getFormats]);

  const handleOpenConfirmModal = useCallback(async () => {
    const canOpen = await trigger(["formatId"]);

    setExecuteRes(undefined);
    setExecuteError("");

    if (canOpen) {
      setIsOpen(true);
    }
  }, [trigger]);

  const handleCloseConfirmModal = () => {
    setIsOpen(false);
  };

  const handleCloseExportModal = useCallback(() => {
    setFormatsError("");
    onClose();
  }, [onClose]);

  const handleChange = useCallback(() => {
    clearErrors();
  }, [clearErrors]);

  const formatsFieldOptions = useMemo(
    () =>
      formats?.map(({ exportName, fileName }) => ({
        value: exportName ?? "",
        label: exportName ?? "",
        fileName,
      })),
    [formats]
  );

  const onSubmit: SubmitHandler<FileExportFormInput> = (data) => {
    const { formatId } = data;
    setIsExecuting(true);

    const selected = formats.find((format) => format.exportName === formatId);

    authorizedCall(
      ExportControllerService.exportFile,
      {
        requestBody: {
          fileName: selected?.fileName ?? "",
          exportName: selected?.exportName ?? "",
          country: selected?.country ?? "",
          bookingCenter: selected?.bookingCenter ?? "",
          taxablePersonTaxNumber: selected?.taxablePersonTaxNumber ?? null,
        },
      },
      true
    )
      .then((data) => {
        setIsExecuting(false);
        setExecuteRes(data);
        setValue("formatId", "");
        setFormats([]);
        getFormats();
      })
      .catch((err) => {
        if (err.status === 504) {
          setIsExecuting(false);
          setExecuteError(
            "Process is taking more time than expected. Please check export log after few minutes."
          );
        } else {
          setIsExecuting(false);
          setExecuteError(err.body.message);
        }
      });
  };

  return {
    handleSubmit: handleSubmit(onSubmit),
    control,
    formats,
    isOpen,
    executeRes,
    formatsFieldOptions,
    formatId,
    isExecuting,
    formatsError,
    executeError,
    handleChange,
    handleCloseConfirmModal,
    handleOpenConfirmModal,
    handleCloseExportModal,
    formatsIsFetching,
  };
};
