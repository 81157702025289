import { useEffect, useState } from "react";
import { getRoles } from "../../../utils/jwtUtils";
import { useAuth } from "react-oidc-context";
import { User } from "oidc-client-ts";

export const useUser = () => {
  const auth = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [token, setToken] = useState("");

  useEffect(() => {
    if (!auth.isAuthenticated) {
      setIsAdmin(false);
    } else {
      if (auth.user) {
        setUser(auth.user);
        setToken(auth.user?.access_token);

        for (const role of getRoles()) {
          if (role.toLowerCase().includes("admin")) {
            setIsAdmin(true);
            break;
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [auth.isAuthenticated, auth.user?.access_token]);

  return { isAdmin, token, user, isAuthenticated: auth.isAuthenticated };
};
