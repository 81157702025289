import { Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Modal, IModalProps } from "../../../../components/core/Modal";
import { FileImportForm } from "./FileImportForm";
import { ConfirmModal } from "./ConfirmModal";
import { useFileImportModal } from "./useFileImportModal";
import { Spinner } from "../../../../components/core/Spinner";
import BackButton from "../../../../components/core/BackButton";
import { ButtonWrapper } from "../../../../components/core/EntityTable/index.style";

export interface IFileImportModal {
  open: IModalProps["open"];
  onClose: IModalProps["handleClose"];
}

export const FileImportModal = (props: IFileImportModal) => {
  const { open, onClose } = props;

  const {
    handleFormatSelect,
    handleSubmit,
    control,
    formats,
    formatsIsFetching,
    formatsFieldOptions,
    handleReportMonthChange,
    handleDateCreateChange,
    shouldDisableInput,
    shouldDisableCheckbox,
    fileToImportError,
    formatsError,
    isConfirmModalOpen,
    executeError,
    isExecuting,
    executeRes,
    handleCloseConfirmModal,
    handleOpenConfirmModal,
    handleCheck,
  } = useFileImportModal({ onClose });

  return (
    <Modal
      title="TTDF File Import"
      open={open}
      handleClose={onClose}
      actions={
        <ButtonWrapper>
          {!fileToImportError && !formatsIsFetching && !formatsError && (
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleOpenConfirmModal}
            >
              Execute
            </LoadingButton>
          )}
          <BackButton onClick={onClose} />
        </ButtonWrapper>
      }
    >
      <ConfirmModal
        openModal={isConfirmModalOpen}
        handleCloseModal={handleCloseConfirmModal}
        error={executeError}
        isExecuting={isExecuting}
        executeRes={executeRes}
      />
      {formatsIsFetching && <Spinner height="230px" width="824px" />}
      {formatsError && <Alert severity="error">{formatsError}</Alert>}
      {formats.length > 0 && (
        <FileImportForm
          control={control}
          formatsFieldOptions={formatsFieldOptions}
          handleFormatSelect={handleFormatSelect}
          handleReportMonthChange={handleReportMonthChange}
          handleDateCreateChange={handleDateCreateChange}
          handleSubmit={handleSubmit}
          shouldDisableCheckbox={shouldDisableCheckbox}
          shouldDisableInput={shouldDisableInput}
          handleCheck={handleCheck}
        />
      )}
      {fileToImportError && <Alert severity="error">{fileToImportError}</Alert>}
    </Modal>
  );
};
