import { useEffect, useMemo, useState, useCallback } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import {
  ArchiveControllerService,
  CollectionModelEntityModelProcessOutputDto,
  EntityModelArchiveBookingCenterDto,
  EntityModelArchiveYearsDto,
} from "../../../openapi";
import authorizedCall from "../../../utils/authorizedCall";
import { ArchiveDataRes } from "./types";

export type ArchiveDataFormInput = {
  chooseBookingCenter?: string;
  chooseYear: string;
};

type UseArchiveDataModalProps = {
  onClose: () => void;
  country: "ES" | "FR" | "IT";
};

export const useArchiveDataModal = ({
  onClose,
  country,
}: UseArchiveDataModalProps) => {
  const [isExecuting, setIsExecuting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [executeError, setExecuteError] = useState("");
  const [executeRes, setExecuteRes] = useState<ArchiveDataRes>();
  const [years, setYears] = useState<EntityModelArchiveYearsDto[]>();
  const [yearsIsFetching, setYearsIsFetching] = useState(false);
  const [yearsError, setYearsError] = useState("");

  const [bookingCenters, setBookingCenters] = useState<
    EntityModelArchiveBookingCenterDto[]
  >([]);
  const [bookingCenterIsFetching, setBookingCenterIsFetching] = useState(false);
  const [bookingCentersError, setBookingCentersError] = useState("");

  const {
    formState,
    handleSubmit,
    control,
    trigger,
    clearErrors,
    resetField,
    setValue,
  } = useForm<ArchiveDataFormInput>({
    defaultValues: {
      chooseBookingCenter: "",
      chooseYear: "",
    },
  });

  const bookingCenterValue = useWatch({ control, name: "chooseBookingCenter" });

  const getArchiveYearsFilter = useCallback(
    async (bookingCenterId?: string) => {
      setYearsIsFetching(true);
      try {
        const data: EntityModelArchiveYearsDto[] = await authorizedCall(
          ArchiveControllerService.getArchiveYearsFilter,
          {
            country: country,
            bookingCenter: bookingCenterId,
            size: 100,
          }
        );
        setYears(data);
      } catch (err: any) {
        setYearsError(err.body?.message ?? err);
      } finally {
        setYearsIsFetching(false);
      }
    },
    [country]
  );

  const getArchiveBookingCenter = useCallback(async () => {
    try {
      setBookingCenterIsFetching(true);
      const data: EntityModelArchiveBookingCenterDto[] = await authorizedCall(
        ArchiveControllerService.getArchiveBookingCenter,
        {
          size: 100,
        }
      );
      setBookingCenters(data);
    } catch (err: any) {
      setBookingCentersError(err.body?.message ?? err);
    } finally {
      setBookingCenterIsFetching(false);
    }
  }, []);

  useEffect(() => {
    if (country === "IT") {
      getArchiveBookingCenter();
    } else {
      getArchiveYearsFilter();
    }
  }, [country, getArchiveBookingCenter, getArchiveYearsFilter]);

  const bookingCentersSelectOptions = useMemo(
    () =>
      bookingCenters?.map(({ name, bookingCenter, country }) => ({
        label: `${name}|${bookingCenter}|${country}`,
        value: bookingCenter ?? "",
      })),
    [bookingCenters]
  );

  const yearsSelectOptions = useMemo(
    () =>
      years?.map(({ year }) => ({
        value: String(year),
        label: String(year),
      })) ?? [],
    [years]
  );

  const handleBookingCenterChange = useCallback(
    (evt: any) => {
      resetField("chooseYear");
      clearErrors();
      const bcValue = evt.target.value;

      if (bcValue) {
        getArchiveYearsFilter(bcValue);
      } else {
        setYears(undefined);
      }
    },
    [clearErrors, getArchiveYearsFilter, resetField]
  );

  const handleCloseConfirmModal = () => {
    setIsOpen(false);
  };

  const handleChange = useCallback(() => {
    clearErrors();
  }, [clearErrors]);

  const handleOpenConfirmModal = useCallback(async () => {
    const canOpen = await trigger(["chooseYear", "chooseBookingCenter"]);

    setExecuteError("");
    setExecuteRes(undefined);

    if (canOpen) {
      setIsOpen(true);
    }
  }, [trigger]);

  const handleCloseArchiveModal = useCallback(() => {
    setBookingCentersError("");
    setYearsError("");
    onClose();
  }, [onClose]);

  const onSubmit: SubmitHandler<ArchiveDataFormInput> = async (data) => {
    try {
      setIsExecuting(true);
      const { chooseBookingCenter, chooseYear } = data;
      const bcCountry = bookingCenters.find(
        (bc) => bc.bookingCenter === chooseBookingCenter
      )?.country;
      const res: CollectionModelEntityModelProcessOutputDto =
        await authorizedCall(
          ArchiveControllerService.archive,
          {
            country: bcCountry ?? country,
            requestBody: {
              bookingCenter: chooseBookingCenter,
              year: Number(chooseYear),
            },
          },
          true
        );
      setValue("chooseYear", "");
      setYears([]);

      if (country === "IT") {
        await getArchiveYearsFilter(bookingCenterValue);
      } else {
        getArchiveYearsFilter();
      }

      setExecuteRes({
        success: res?._embedded?.entities?.[0].success ?? false,
        message: res?._embedded?.entities?.[0].message ?? "",
      });
    } catch (err: any) {
      setExecuteError(err.body?.message ?? err);
    } finally {
      setIsExecuting(false);
    }
  };

  const shouldRenderExecuteBtn = useMemo(() => {
    return (
      !(yearsError || bookingCentersError) &&
      !(bookingCenterIsFetching || yearsIsFetching) &&
      years &&
      years?.length > 0
    );
  }, [
    bookingCenterIsFetching,
    bookingCentersError,
    years,
    yearsError,
    yearsIsFetching,
  ]);

  return {
    onSubmit: handleSubmit(onSubmit),
    control,
    yearsIsFetching,
    bookingCenterIsFetching,
    yearsSelectOptions,
    bookingCentersSelectOptions,
    formState,
    isExecuting,
    setIsExecuting,
    executeError,
    executeRes,
    bookingCentersError,
    yearsError,
    handleCloseConfirmModal,
    handleChange,
    handleOpenConfirmModal,
    handleCloseArchiveModal,
    isOpen,
    bookingCenters,
    years,
    handleBookingCenterChange,
    shouldRenderExecuteBtn,
  };
};
