import { useState, useEffect, useCallback, useMemo, ReactNode } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import authorizedCall from "../../../../utils/authorizedCall";
import { UpsertAdjustmentFormProps } from "./UpsertAdjustmentForm";
import { defaultValues, extendedDefaultValues } from "./config";
import { WithNull } from "../../../../types/Utility";
import {
  EntityModelTransaction,
  TransactionControllerService,
  UpdateTransactionDto,
  FileImportControllerService,
  EntityModelFileImportMapping,
  InsertTransactionDto,
  EntityModelSafekeepingCountryDto,
  EntityModelExemptionCode,
  ExemptionCodeMappingControllerService,
} from "../../../../openapi";
import {
  UpsertImportedTransactionFormInputs,
  UpsertManualTransactionFormInputs,
} from "./types";
import { useNavigate } from "react-router-dom";
import { SelectOption } from "../../../../components/core/Select";

export const useUpsertAdjustmentForm = ({
  id,
  countryTypeId,
}: UpsertAdjustmentFormProps) => {
  const navigate = useNavigate();
  const [transaction, setTransaction] = useState<EntityModelTransaction>();
  const [fetchingError, setFetchingError] = useState("");
  const [safekeepCountry, setSafekeepCountry] =
    useState<EntityModelSafekeepingCountryDto[]>();
  const [isFetching, setFetching] = useState(false);
  const [exomappingCodes, setExomappingCodes] =
    useState<EntityModelExemptionCode[]>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccessful, setIsSubmitSuccessful] = useState(false);
  const [submitError, setSubmittingError] = useState("");
  const [countryTypeAdjustmentName, setCountryTypeAdjustmentName] =
    useState("");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [abortCreate, setAbortCreate] = useState<boolean | undefined>();
  const [fileImportMapping, setFileImportMapping] =
    useState<EntityModelFileImportMapping>();

  const form = useForm<UpsertManualTransactionFormInputs>({
    defaultValues:
      transaction?.recordSource === "I" ? defaultValues : extendedDefaultValues,
    mode: "onBlur",
  });

  const { reset, formState, handleSubmit, control, getValues } = form;

  const taxFlag = useWatch({ control, name: "taxFlag" });
  const nettedTaFlag = useWatch({ control, name: "nettedTaFlag" });
  const isTaxFlagY = taxFlag === "Y";
  const isTaxFlagN = taxFlag === "N";

  const handleBack = useCallback(() => {
    navigate("/table/adjustment");
  }, [navigate]);

  const addTransactionDefaultValues = useMemo(
    () => ({
      ...extendedDefaultValues,
      quantity: "0",
      amount: "0.00",
      taxAmount: "0.00",
      quantityTransferred: "0.00",
      quantityTransferredNet: "0.00",
      amountOfAcquisition: "0.00",
      taxBaseDeclared: "0.00",
      bc: fileImportMapping?.bookingCenter,
      land: fileImportMapping?.country,
      fileBc: fileImportMapping?.fileBookingCenter,
      intCom: "",
      extCom: "",
      recordSource: "M",
      recordType: "R",
    }),
    [
      fileImportMapping?.bookingCenter,
      fileImportMapping?.country,
      fileImportMapping?.fileBookingCenter,
    ]
  );

  const getSafekeepCountries = async (country: string) => {
    const safekeepingCountries: EntityModelSafekeepingCountryDto[] =
      await authorizedCall(
        TransactionControllerService.getSafekeepingCountries,
        { country: country }
      );
    const result = safekeepingCountries ?? [];
    setSafekeepCountry(result);
    return result;
  };

  const getTransaction = useCallback(async () => {
    const _transactions: EntityModelTransaction[] = await authorizedCall(
      TransactionControllerService.getTransactions,
      {
        id: Number(id),
      }
    );
    const result = _transactions[0] ?? {};
    setTransaction(result);
    return result;
  }, [id]);

  const getFileImportMapping = useCallback(async () => {
    const fileImportMappingId = Number(countryTypeId);
    const fileImportMappings: EntityModelFileImportMapping[] =
      await authorizedCall(FileImportControllerService.getFileImportMapping, {
        id: fileImportMappingId,
      });
    const result = fileImportMappings[0] ?? {};
    setFileImportMapping(result);
    return result;
  }, [countryTypeId]);

  const setupEditAdjustment = useCallback(async () => {
    try {
      setFetching(true);
      const _transaction = await getTransaction();
      await getSafekeepCountries(_transaction.country ?? "");

      const initialformData:
        | UpsertImportedTransactionFormInputs
        | UpsertManualTransactionFormInputs = {
        amount: String(_transaction.cashAmount ?? ""),
        exoCode: _transaction?.exonerationCode ?? "",
        taxFlag: _transaction?.taxableFlag ?? "",
        taxAmount: String(_transaction?.taxAmount ?? ""),
        quantityTransferred: String(_transaction?.quantityTransferred ?? ""),
        quantityTransferredNet: String(
          _transaction?.quantityTransferredNet ?? ""
        ),
        taxBaseDeclared: String(_transaction?.declaredTaxBase ?? ""),
        amountOfAcquisition: String(_transaction?.acquisitionAmount ?? ""),
        intCom: _transaction?.comment ?? "",
        safekeepCountry: String(_transaction.skMap?.id ?? ""),
        extCom: _transaction?.accountComment ?? "",
        trxReference: _transaction.transactionReference ?? "",
        typeOfTrans: _transaction.declarationType ?? "",
        bc: _transaction.bookingCenter ?? "",
        nettedTaFlag: _transaction.nettedTransactionFlag ?? false,
        isin: _transaction.isinCode ?? "",
        tradeDate: _transaction.tradeDate ?? "",
        setImpDate: _transaction.settlementDate ?? "",
        quantity: String(_transaction.quantity ?? ""),
        unit: _transaction.unit ?? "",
        placeOfTrade: _transaction?.micListByCountry?.mic ?? "",
        land: _transaction.country ?? "",
        reportDate: _transaction.reportingDate ?? "",
        fileBc: _transaction.fileBookingCenter ?? "",
        exoMapping: String(_transaction.exemptionCode?.id ?? ""),
        fileType: _transaction?.fileType as number,
        loadDate: _transaction?.loadDate ?? "",
        recordSource: (_transaction?.recordSource as "I" | "M") ?? "",
        recordType: _transaction?.recordType ?? "",
      };

      reset(initialformData);
    } catch (err: any) {
      setFetchingError(err.body?.message ?? err);
    } finally {
      setFetching(false);
    }
  }, [getTransaction, reset]);

  const setupAddAdjustment = useCallback(async () => {
    try {
      setFetching(true);
      const _mapping = await getFileImportMapping();
      reset({
        ...addTransactionDefaultValues,
        bc: _mapping.bookingCenter,
        land: _mapping.country,
        fileBc: _mapping.fileBookingCenter,
      });
      setCountryTypeAdjustmentName(_mapping.adjustmentName ?? "");
      await getSafekeepCountries(_mapping.country ?? "");
    } catch (err: any) {
      setFetchingError(err.body?.message ?? err);
    } finally {
      setFetching(false);
    }
  }, [addTransactionDefaultValues, getFileImportMapping, reset]);

  useEffect(() => {
    if (!id) {
      setupAddAdjustment();
    } else {
      setupEditAdjustment();
    }
  }, [id, setupAddAdjustment, setupEditAdjustment, transaction?.recordSource]);

  useEffect(() => {
    if (submitSuccessful && !id) {
      reset(addTransactionDefaultValues);
    }
  }, [addTransactionDefaultValues, id, reset, submitSuccessful]);

  useEffect(() => {
    authorizedCall(
      ExemptionCodeMappingControllerService.getExemptionCodes,
      {}
    ).then((data) => {
      setExomappingCodes(data);
    });
  }, []);

  const safekeepCountryOptions = useMemo(() => {
    return (
      safekeepCountry?.map(
        ({ targetCodeId, swiftAddress, micListByCountryId }) => ({
          value: String(micListByCountryId),
          label: `${targetCodeId}|${swiftAddress}`,
        })
      ) ?? []
    );
  }, [safekeepCountry]);

  const taxableOptions: SelectOption[] = useMemo(() => {
    return ["Y", "N", "E", "S"].map((_value) => {
      return {
        label: _value,
        value: _value,
      };
    });
  }, []);

  const exomappingOptions: SelectOption[] = useMemo(() => {
    return (
      exomappingCodes?.map(({ code, id }) => ({
        label: String(code) ?? "",
        value: String(id) ?? "",
      })) ?? []
    );
  }, [exomappingCodes]);

  const fileTypeValue: number | null = useMemo(() => {
    switch (countryTypeAdjustmentName) {
      case "I-FTT GG Shares":
      case "I-FTT CH Shares": {
        return 1;
      }
      case "F-FTT GG":
      case "F-FTT CH":
      case "S-FTT GG":
      case "S-FTT CH": {
        return 0;
      }
      case "I-FTT GG Derivatives":
      case "I-FTT CH Derivatives": {
        return 2;
      }
      default:
        return null;
    }
  }, [countryTypeAdjustmentName]);

  const handleOpenConfirmModal = () => setIsConfirmModalOpen(true);

  const handleCloseConfirmModal = () => setIsConfirmModalOpen(false);

  const handleCreation = () => {
    setAbortCreate(true);
    setIsConfirmModalOpen(false);
  };

  const handleAbortCreation = () => {
    setAbortCreate(false);
    setIsConfirmModalOpen(false);
  };

  const createAlertMessage = useCallback((): ReactNode => {
    const data = getValues();
    let warnings = [];

    if (Number(data.taxAmount) === 0) {
      warnings.push(<li key="warning-tax-amount">Tax Amount is 0.</li>);
    }

    if (
      data.nettedTaFlag &&
      (Number(data.quantityTransferred) === 0 ||
        Number(data.quantityTransferredNet) === 0 ||
        Number(data.amountOfAcquisition) === 0 ||
        Number(data.taxBaseDeclared) === 0)
    ) {
      warnings.push(
        <li key="warning-netting-details">No netting details entered.</li>
      );
    }

    let message = [];
    if (warnings.length > 0) {
      message.push(
        <p style={{ marginTop: "8px", marginBottom: "0px" }}>Warning:</p>
      );
      message.push(<ul style={{ marginTop: "4px" }}>{warnings}</ul>);
      message.push(<p>Would you like to save the data anyway?</p>);
    } else {
      message.push(
        <p style={{ marginTop: "8px", marginBottom: "0px" }}>
          Would you like to save the data?
        </p>
      );
    }

    return message;
  }, [getValues]);

  const mapFormInputsToTransactionDto = (
    data: UpsertImportedTransactionFormInputs &
      UpsertManualTransactionFormInputs
  ): WithNull<InsertTransactionDto | UpdateTransactionDto> => {
    return {
      cashAmount: data.amount === "" ? null : parseFloat(data.amount),
      exonerationCode: data.exoCode ?? null,
      taxableFlag: data.taxFlag ?? null,
      taxAmount: data.taxAmount === "" ? null : parseFloat(data.taxAmount),
      quantityTransferred:
        data.quantityTransferred === ""
          ? null
          : parseFloat(data.quantityTransferred),
      quantityTransferredNet:
        data.quantityTransferredNet === ""
          ? null
          : parseFloat(data.quantityTransferredNet),
      acquisitionAmount:
        data.amountOfAcquisition === ""
          ? null
          : parseFloat(data.amountOfAcquisition),
      declaredTaxBase:
        data.taxBaseDeclared === "" ? null : parseFloat(data.taxBaseDeclared),
      declarationType: data.typeOfTrans ?? null,
      bookingCenter: data.bc === "" ? null : data.bc,
      nettedTrcFlag: data.nettedTaFlag,
      isinCode: data.isin ?? null,
      tradeDateTime: data.tradeDate ?? null,
      settlementDateTime: data.setImpDate ?? null,
      quantity: data.quantity === "" ? null : parseFloat(data.quantity),
      unit: data.unit ?? null,
      reportingDateTime: data.reportDate ?? null,
      fileBookingCenter: data.fileBc ?? null,
      country: data.land ?? null,
      exemptionCodeId:
        data.exoMapping === "" ? null : parseFloat(data.exoMapping),
      skMapId:
        data.safekeepCountry === "" ? null : parseFloat(data.safekeepCountry),
      trcRef: data.trxReference ?? null,
      placeOfTrade: data.placeOfTrade ?? null,
      comment: data.intCom ?? null,
      accountComment: data.extCom ?? null,
      loadDateTime: data.loadDate ?? null,
      recordSource: data.recordSource ? (data.recordSource as "I" | "M") : null,
      recordType: data.recordType ? (data.recordType as "R" | "H") : null,
    };
  };

  const onSubmit: SubmitHandler<
    UpsertImportedTransactionFormInputs & UpsertManualTransactionFormInputs
  > = useCallback(
    (data) => {
      setAbortCreate(undefined);
      setIsSubmitSuccessful(false);

      if (!abortCreate) {
        setIsConfirmModalOpen(true);
      } else {
        setIsSubmitSuccessful(false);
        setIsSubmitting(true);
        setSubmittingError("");

        let bodyReq = mapFormInputsToTransactionDto(data);

        if (transaction) {
          authorizedCall(TransactionControllerService.updateTransaction, {
            id: Number(id),
            requestBody: bodyReq as UpdateTransactionDto,
          })
            .then(() => {
              setIsSubmitting(false);
              setIsSubmitSuccessful(true);
            })
            .catch((err) => {
              setIsSubmitting(false);
              setSubmittingError(err.body.message);
            });
        } else {
          bodyReq = {
            ...bodyReq,
            fileType: fileTypeValue,
          };
          authorizedCall(TransactionControllerService.addTransaction, {
            requestBody: bodyReq as InsertTransactionDto,
          })
            .then(() => {
              setIsSubmitting(false);
              setIsSubmitSuccessful(true);
            })
            .catch((err) => {
              setIsSubmitting(false);
              setSubmittingError(err.body.message);
            });
        }
      }
    },
    [fileTypeValue, id, transaction, abortCreate]
  );

  const isLoading = useMemo(() => {
    return isFetching;
  }, [isFetching]);

  const canRender = useMemo(() => {
    if (transaction?.recordSource === "I") {
      return true;
    }

    if (!id || transaction?.recordSource === "M") {
      return !isLoading;
    }
  }, [id, isLoading, transaction?.recordSource]);

  const submitSuccessMessage = id
    ? "Data has been updated correctly"
    : "Record inserted";

  const isNettedFlagRequired =
    (isTaxFlagY || isTaxFlagN) &&
    !["IT", "FR", ""].includes(
      fileImportMapping?.country?.toUpperCase() ?? transaction?.country ?? ""
    );

  const isNettedTaFlagDisabled =
    transaction?.recordSource === "I" ||
    ["IT", "FR"].includes(
      fileImportMapping?.country?.toUpperCase() ?? transaction?.country ?? ""
    );

  return {
    formState,
    isSubmitting,
    control,
    canRender,
    isLoading,
    submitError,
    transaction,
    fetchingError,
    submitSuccessful,
    safekeepCountryOptions,
    exomappingOptions,
    isTaxFlagY,
    isTaxFlagN,
    taxableOptions,
    submitSuccessMessage,
    onSubmit: handleSubmit(onSubmit),
    onBack: handleBack,
    transactionSource: transaction?.recordSource,
    isConfirmModalOpen,
    alertMessage: createAlertMessage(),
    handleOpenConfirmModal,
    handleCloseConfirmModal,
    handleAbortCreation,
    handleCreation,
    country: fileImportMapping?.country,
    isNettedFlagRequired,
    isNettedTaFlagDisabled,
    nettedTaFlag,
  };
};
