export const splitElementsInColumns = <T>(
  n_columns: number,
  n_rows: number,
  elements: Array<T>
) => {
  let columns = [];

  const currentColumnsNumber = elements.length / n_rows;
  let currentRowsPerColumnNumber = n_rows;

  if (currentColumnsNumber > n_columns)
    currentRowsPerColumnNumber = Math.floor(elements.length / n_columns);

  for (let i = 0; i < n_columns; i++) {
    const start = i * currentRowsPerColumnNumber;
    const end = start + currentRowsPerColumnNumber;
    const currentColumn = elements.slice(start, end);
    columns.push(currentColumn);
  }

  const startIndexRemainingElements = currentRowsPerColumnNumber * n_columns;

  for (
    let i = startIndexRemainingElements;
    i < elements.length;
    i = i + n_columns
  ) {
    for (let y = 0; y < n_columns; y++) {
      if (elements[i + y]) columns[y].push(elements[i + y]);
    }
  }

  return columns;
};

export const splitElementsInColumnsByParam = <T>(
  elements: Array<T>,
  param: string
): { [key: string]: Array<T> } => {
  let columns: { [key: string]: Array<T> } = {};

  for (const element of elements) {
    const key = (element as any)[param] as string;
    if (!columns.hasOwnProperty(key)) {
      columns[key] = [];
    }

    columns[key].push(element);
  }

  return columns;
};
