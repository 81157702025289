import React from "react";
import { Box, SelectChangeEvent } from "@mui/material";
import { Select, SelectProps } from "../../core/Select";
import {
  Controller,
  ControllerRenderProps,
  Path,
  FieldError,
  ControllerFieldState,
  UseFormStateReturn,
} from "react-hook-form";
import type { UseControllerProps } from "react-hook-form";
import type { FieldValues } from "react-hook-form/dist/types/fields";

export type FormSelectProps<T extends FieldValues = FieldValues> = Omit<
  UseControllerProps<T>,
  "defaultValue"
> &
  SelectProps;

type InternalFormSelectProps<T extends FieldValues = FieldValues> =
  FormSelectProps<T> & {
    field: ControllerRenderProps<T, Path<T>>;
    error: FieldError | undefined;
  };

const InternalSelect = <T extends FieldValues = FieldValues>(
  props: InternalFormSelectProps<T>
) => {
  const {
    name,
    label,
    field,
    handleChange,
    onFocus,
    error,
    options,
    ...restProps
  } = props;

  const onChange = React.useCallback(
    (value: React.FormEvent<HTMLLabelElement> | SelectChangeEvent<unknown>) => {
      field.onChange(value);
      handleChange?.(value);
    },
    [field, handleChange]
  );

  return (
    <Box sx={{ minWidth: 120 }}>
      <Select
        name={name}
        label={label}
        onChange={onChange}
        onBlur={field.onBlur}
        onFocus={onFocus}
        value={field.value}
        error={error}
        disabled={options.length === 0}
        data-testid={name}
        options={options}
        {...restProps}
      />
    </Box>
  );
};

export const FormSelect = <T extends FieldValues = FieldValues>(
  props: FormSelectProps<T>
) => {
  const { name, control, rules, shouldUnregister, ...restProps } = props;

  const renderInternalSelect = React.useCallback(
    ({
      field,
      fieldState: { error },
    }: {
      field: ControllerRenderProps<T, Path<T>>;
      fieldState: ControllerFieldState;
      formState: UseFormStateReturn<T>;
    }) => {
      return (
        <InternalSelect
          name={name}
          field={field}
          error={error}
          {...restProps}
        />
      );
    },
    [name, restProps]
  );

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={renderInternalSelect}
    />
  );
};
