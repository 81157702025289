import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AuthenticatedLayout } from "../../features/user/layouts/AuthendicatedLayout";
import { Breadcrumbs } from "../../components/core/Breadcrumbs";
import { UpsertExemptionCodeMappingForm } from "../../features/parameters/exemption-code-mapping/UpsertExemptionCodeMappingForm";

export const EditExemptionCodeMapping = (): React.ReactElement => {
  const { id } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!id) {
      navigate("/");
    }
  }, [id, navigate]);

  return (
    <AuthenticatedLayout>
      <Breadcrumbs />
      <UpsertExemptionCodeMappingForm id={id} />
    </AuthenticatedLayout>
  );
};

export default EditExemptionCodeMapping;
