/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CollectionModelEntityModelSafekeepingCountryDto } from '../models/CollectionModelEntityModelSafekeepingCountryDto';
import type { InsertTransactionDto } from '../models/InsertTransactionDto';
import type { PagedModelEntityModelTransaction } from '../models/PagedModelEntityModelTransaction';
import type { UpdateTransactionDto } from '../models/UpdateTransactionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TransactionControllerService {

    /**
     * Returns a paged list of Transaction entities
     * @returns PagedModelEntityModelTransaction A paged list of Transaction entities
     * @throws ApiError
     */
    public static getTransactions({
authorization,
page,
size = 10,
sort,
fileImportMappingId,
search,
nettedTransactionFlag,
country,
accountComment,
loadDt,
isinTranslation,
origin,
quantityTransferredNet,
cashAmount,
reportingDt,
tradingCashAmount,
tradingCountryCode,
acquisitionAmount,
bookingCenter,
recon,
id,
taxType,
recordSource,
declarationType,
exemptionCode,
skMap,
brs,
quantity,
transactionReference,
recordType,
narrative,
taxableFlag,
archive,
quantityTransferred,
settlementDt,
unit,
micListByCountry,
declaredTaxBase,
tradeDt,
fileBookingCenter,
transactionExternalNumber,
comment,
exonerationCode,
tradingTaxRate,
tradingRecalc,
taxAmount,
accountBalance,
fileType,
isinCode,
}: {
authorization: string,
/**
 * Zero-based page index (0..N)
 */
page?: number,
/**
 * The size of the page to be returned
 */
size?: number,
/**
 * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
 */
sort?: Array<string>,
fileImportMappingId?: number,
search?: string,
nettedTransactionFlag?: boolean,
country?: string,
accountComment?: string,
loadDt?: string,
isinTranslation?: string,
origin?: string,
quantityTransferredNet?: number,
cashAmount?: number,
reportingDt?: string,
tradingCashAmount?: number,
tradingCountryCode?: string,
acquisitionAmount?: number,
bookingCenter?: string,
recon?: boolean,
id?: number,
taxType?: string,
recordSource?: 'I' | 'M',
declarationType?: string,
exemptionCode?: string,
skMap?: string,
brs?: string,
quantity?: number,
transactionReference?: string,
recordType?: 'R' | 'H',
narrative?: string,
taxableFlag?: string,
archive?: boolean,
quantityTransferred?: number,
settlementDt?: string,
unit?: string,
micListByCountry?: string,
declaredTaxBase?: number,
tradeDt?: string,
fileBookingCenter?: string,
transactionExternalNumber?: number,
comment?: string,
exonerationCode?: string,
tradingTaxRate?: number,
tradingRecalc?: boolean,
taxAmount?: number,
accountBalance?: string,
fileType?: number,
isinCode?: string,
}): CancelablePromise<PagedModelEntityModelTransaction> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/transactions',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'page': page,
                'size': size,
                'sort': sort,
                'fileImportMappingId': fileImportMappingId,
                'search': search,
                'nettedTransactionFlag': nettedTransactionFlag,
                'country': country,
                'accountComment': accountComment,
                'loadDt': loadDt,
                'isinTranslation': isinTranslation,
                'origin': origin,
                'quantityTransferredNet': quantityTransferredNet,
                'cashAmount': cashAmount,
                'reportingDt': reportingDt,
                'tradingCashAmount': tradingCashAmount,
                'tradingCountryCode': tradingCountryCode,
                'acquisitionAmount': acquisitionAmount,
                'bookingCenter': bookingCenter,
                'recon': recon,
                'id': id,
                'taxType': taxType,
                'recordSource': recordSource,
                'declarationType': declarationType,
                'exemptionCode': exemptionCode,
                'skMap': skMap,
                'brs': brs,
                'quantity': quantity,
                'transactionReference': transactionReference,
                'recordType': recordType,
                'narrative': narrative,
                'taxableFlag': taxableFlag,
                'archive': archive,
                'quantityTransferred': quantityTransferred,
                'settlementDt': settlementDt,
                'unit': unit,
                'micListByCountry': micListByCountry,
                'declaredTaxBase': declaredTaxBase,
                'tradeDt': tradeDt,
                'fileBookingCenter': fileBookingCenter,
                'transactionExternalNumber': transactionExternalNumber,
                'comment': comment,
                'exonerationCode': exonerationCode,
                'tradingTaxRate': tradingTaxRate,
                'tradingRecalc': tradingRecalc,
                'taxAmount': taxAmount,
                'accountBalance': accountBalance,
                'fileType': fileType,
                'isinCode': isinCode,
            },
            errors: {
                400: `countryId or type is null`,
                403: `Unauthorized user`,
            },
        });
    }

    /**
     * Manually adds a new transaction and recalculates the reconciliation
     * @returns any Transaction added successfully
     * @throws ApiError
     */
    public static addTransaction({
authorization,
requestBody,
}: {
authorization: string,
requestBody: InsertTransactionDto,
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/transactions',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Unauthorized user`,
            },
        });
    }

    /**
     * Physically deletes a manual transaction and recalculates the reconciliation
     * @returns any Transaction deleted successfully
     * @throws ApiError
     */
    public static deleteTransaction({
authorization,
id,
}: {
authorization: string,
id: number,
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/transactions/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                403: `Unauthorized user`,
                404: `Transaction record not found`,
            },
        });
    }

    /**
     * Updates some transaction fields
     * @returns any Transaction updated successfully
     * @throws ApiError
     */
    public static updateTransaction({
authorization,
id,
requestBody,
}: {
authorization: string,
id: number,
requestBody: UpdateTransactionDto,
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/transactions/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Unauthorized user`,
                404: `Transaction record not found`,
            },
        });
    }

    /**
     * Returns a list of safekeeping countries to show in the dropdown named 'Safekeep. Country'
     * @returns CollectionModelEntityModelSafekeepingCountryDto A list of SafekeepingCountryDto
     * @throws ApiError
     */
    public static getSafekeepingCountries({
authorization,
country,
}: {
authorization: string,
country: string,
}): CancelablePromise<CollectionModelEntityModelSafekeepingCountryDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/transactions/safekeep-country/{country}',
            path: {
                'country': country,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                403: `Unauthorized user`,
            },
        });
    }

}
