/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagedModelEntityModelSafeKeepingMapping } from '../models/PagedModelEntityModelSafeKeepingMapping';
import type { SafeKeepingMappingDto } from '../models/SafeKeepingMappingDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SafeKeepingMappingControllerService {

    /**
     * Returns a paged list of SafeKeepingMapping records
     * @returns PagedModelEntityModelSafeKeepingMapping Paged list of SafeKeepingMapping records
     * @throws ApiError
     */
    public static getSafeKeepingMapping({
authorization,
page,
size = 10,
sort,
search,
country,
sourceCountryMicList,
swiftAddress,
id,
targetCountryMicList,
}: {
authorization: string,
/**
 * Zero-based page index (0..N)
 */
page?: number,
/**
 * The size of the page to be returned
 */
size?: number,
/**
 * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
 */
sort?: Array<string>,
search?: string,
country?: string,
sourceCountryMicList?: string,
swiftAddress?: string,
id?: number,
targetCountryMicList?: string,
}): CancelablePromise<PagedModelEntityModelSafeKeepingMapping> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/mapping/safekeeping',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'page': page,
                'size': size,
                'sort': sort,
                'search': search,
                'country': country,
                'sourceCountryMicList': sourceCountryMicList,
                'swiftAddress': swiftAddress,
                'id': id,
                'targetCountryMicList': targetCountryMicList,
            },
            errors: {
                403: `Unauthorized user`,
            },
        });
    }

    /**
     * Adds a new SafeKeepingMapping record
     * @returns PagedModelEntityModelSafeKeepingMapping Record added successfully
     * @throws ApiError
     */
    public static addSafeKeepingMapping({
authorization,
requestBody,
}: {
authorization: string,
requestBody: SafeKeepingMappingDto,
}): CancelablePromise<PagedModelEntityModelSafeKeepingMapping> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/mapping/safekeeping',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Unauthorized user`,
            },
        });
    }

    /**
     * Physically deletes an existing record
     * @returns void 
     * @throws ApiError
     */
    public static deleteSafeKeepingMapping({
authorization,
id,
}: {
authorization: string,
id: number,
}): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/mapping/safekeeping/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                403: `Unauthorized user`,
                404: `Record not found`,
            },
        });
    }

    /**
     * Updates some SafeKeepingMapping fields
     * @returns void 
     * @throws ApiError
     */
    public static updateSafeKeepingMapping({
authorization,
id,
requestBody,
}: {
authorization: string,
id: number,
requestBody: SafeKeepingMappingDto,
}): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/mapping/safekeeping/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Unauthorized user`,
                404: `Record not found`,
            },
        });
    }

}
