import { EntityTable } from "../../../../components/core/EntityTable";
import { ReconciliationControllerService } from "../../../../openapi";
import columns from "./config.json";

export const ReconciliationTable = () => (
  <EntityTable
    title="TTDF Reconciliation"
    columns={columns}
    editRoute="/table/reconciliation/edit"
    getService={ReconciliationControllerService.getReconciliations}
    sortConfig={{ order: "desc" }}
  />
);
