import env from "../../../config/env";

const Watermark = () => {
  const { REACT_APP_ENV, REACT_APP_PDF_VERSION } = env;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        marginLeft: "auto",
      }}
    >
      {REACT_APP_ENV && (
        <>
          <b>Env:</b> {REACT_APP_ENV} &nbsp;
        </>
      )}
      <b> Vers:</b> {REACT_APP_PDF_VERSION}
    </div>
  );
};

export default Watermark;
