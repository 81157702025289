import { Alert } from "@mui/material";

export interface FormFieldInfoProps {
  children: string;
}

export const FormFieldInfo = ({ children }: FormFieldInfoProps) => {
  return (
    <Alert
      severity="info"
      sx={{
        background: "transparent",
        height: 14,
        display: "flex",
        padding: 0,
        alignItems: "center",
        mt: 1,
        color: "secondary.main",
      }}
    >
      {children}
    </Alert>
  );
};
