import React from "react";
import { Alert, Grid, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../../../components/core/Spinner";
import { splitElementsInColumnsByParam } from "../../../../utils/layoutUtils";
import { useUserPermissions } from "../../hooks/useUserPermissions";
import { UserPermission } from "../../types";
import { UploadButton } from "../../../../components/core/UploadButton";

export const StyledButton = styled(Button)`
  border-radius: 0;
  text-transform: none;
  font-weight: bold;
  width: 300px;
`;

export const UserActions = () => {
  const {
    userPermissions: userActions,
    userPermissionsIsFetching,
    userPermissionsError,
  } = useUserPermissions();

  const [modal, setModal] = React.useState<any>();

  const navigate = useNavigate();

  const columns = React.useMemo(() => {
    let cols: any = {};

    if (userActions && userActions.length > 0) {
      cols = splitElementsInColumnsByParam(userActions, "functionClass");
    }

    return cols;
  }, [userActions]);

  const handleAction = React.useCallback(
    (permission: UserPermission) => () => {
      if (permission.codeGrant.includes("E")) {
        const ModalComponent = permission.modal
          ?.modal as React.FunctionComponent<any>;
        setModal(
          <ModalComponent
            open={true}
            onClose={() => {
              setModal(undefined);
            }}
          />
        );
      } else if (permission.codeGrant.includes("R")) {
        const url = permission?.paths[0] ?? "/";
        navigate(url);
      }
    },
    [navigate]
  );

  return (
    <Grid container sx={{ pl: "45px" }}>
      {userPermissionsIsFetching && <Spinner width="1512px" height="520px" />}
      {userPermissionsError && (
        <Alert
          children={userPermissionsError}
          sx={{ width: "100%", ml: "-25px" }}
          severity="error"
        />
      )}
      {userActions &&
        userActions.length === 0 &&
        !userPermissionsError &&
        !userPermissionsIsFetching && (
          <Alert
            children="Access denied. Please make the ServiceNow request to receive access"
            sx={{ width: "100%", ml: "-25px" }}
            severity="error"
          />
        )}
      {userActions && userActions.length > 0 && (
        <>
          {Object.keys(columns).map((column: string) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              style={{ textAlign: "center" }}
              key={column}
            >
              <Typography
                variant="h6"
                color="white"
                noWrap
                sx={{
                  flexGrow: 1,
                  backgroundColor: "#001489",
                  marginBottom: "10px",
                }}
              >
                {column}
              </Typography>
              {columns[column].map((permission: UserPermission) => (
                <div key={permission?.id}>
                  {permission.codeFunction === "Explorer Import" ||
                  permission.codeFunction === "Explorer Export" ? (
                    <div>
                      <UploadButton>
                        <Typography noWrap>
                          {permission?.description}
                        </Typography>
                      </UploadButton>
                    </div>
                  ) : (
                    <div>
                      <StyledButton
                        variant="outlined"
                        color="secondary"
                        onClick={handleAction(permission)}
                      >
                        <Typography noWrap>
                          {permission?.description}
                        </Typography>
                      </StyledButton>
                    </div>
                  )}

                  <br />
                </div>
              ))}
            </Grid>
          ))}
        </>
      )}
      {modal}
    </Grid>
  );
};
