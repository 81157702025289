/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EntityModelImportOutputDto } from '../models/EntityModelImportOutputDto';
import type { ImportInputDto } from '../models/ImportInputDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ImportControllerService {

    /**
     * Executes a file import looking at a shared folder, stores imported transactions and start the reconciliation calculation.
     * @returns EntityModelImportOutputDto File imported successfully
     * @throws ApiError
     */
    public static importFile({
authorization,
requestBody,
}: {
authorization: string,
requestBody: ImportInputDto,
}): CancelablePromise<EntityModelImportOutputDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/import',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Unauthorized user`,
                404: `File not found`,
            },
        });
    }

}
