import { User } from "oidc-client-ts";
import env from "../config/env";

export function getAccessToken() {
    const oidcStorage = localStorage.getItem(
        `oidc.user:${env.REACT_APP_ADFS_AUTHORITY as string}:${
            env.REACT_APP_ADFS_CLIENT_ID as string
        }`
    );

    if (!oidcStorage) {
        return null;
    }

    return User.fromStorageString(oidcStorage)?.access_token;
}

export function getRoles() {
    const _token = getAccessToken();
    let roles = parseJwt(_token ?? "")?.Roles ?? [];
    roles = roles ?? [];

    if (typeof roles === "string") {
        roles = [roles];
    }

    return roles;
}

export const parseJwt = (token: string) => {
    if (token === "") {
      return "";
    }
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  
    return JSON.parse(jsonPayload);
  };
  