import { Box, Alert, Grid } from "@mui/material";
import { Card } from "../../../../components/core/Card";
import { Spinner } from "../../../../components/core/Spinner";
import { FormFieldLabel } from "../../../../components/hook-form/FormFieldLabel";
import { AlertMessage } from "../../../../components/core/AlertMessage";
import { useReconciliationForm } from "./useReconciliationForm";
import { FormCurrencyInput } from "../../../../components/hook-form/FormCurrencyInput/FormCurrencyInput";
import EditActionButtons from "../../../../components/core/EditActionButtons";

export interface EditReconciliationFormProps {
  id?: string;
}

export const EditReconciliationForm = (props: EditReconciliationFormProps) => {
  const { id } = props;

  const {
    canRender,
    error,
    isFetching,
    onSubmit,
    onBack,
    control,
    submitError,
    submitSuccessfull,
    isSubmitting,
    handleOffsetChange,
  } = useReconciliationForm({ id });

  return (
    <Card
      title="Edit Reconciliation"
      style={{ width: "1512px" }}
      actions={
        canRender && (
          <EditActionButtons
            form="edit-reconciliation-form"
            loading={isSubmitting}
            onBack={onBack}
          />
        )
      }
      Component={() => (
        <Box sx={{ margin: "20px" }}>
          {error && <Alert severity="error">{error}</Alert>}
          {isFetching && <Spinner height="353px" />}
          {!isFetching && (
            <form
              id="edit-reconciliation-form"
              data-testid="edit-reconciliation-form"
              onSubmit={onSubmit}
            >
              <Grid container columnSpacing={2} rowSpacing={5}>
                <Grid item xs={6}>
                  <FormCurrencyInput
                    name="saldoImport"
                    fixedDecimalScale
                    decimalScale={2}
                    control={control}
                    label={<FormFieldLabel>Saldo Import</FormFieldLabel>}
                    fullWidth
                    allowNegative={true}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormCurrencyInput
                    name="difference"
                    fixedDecimalScale
                    decimalScale={2}
                    control={control}
                    label={<FormFieldLabel>Difference</FormFieldLabel>}
                    fullWidth
                    allowNegative={true}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormCurrencyInput
                    name="dailyBalance"
                    fixedDecimalScale
                    control={control}
                    label={<FormFieldLabel>Daily Balance</FormFieldLabel>}
                    fullWidth
                    decimalScale={2}
                    allowNegative={true}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormCurrencyInput
                    fixedDecimalScale
                    name="saldoAdjusted"
                    control={control}
                    label={<FormFieldLabel>Saldo Adjusted</FormFieldLabel>}
                    fullWidth
                    decimalScale={2}
                    allowNegative={true}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormCurrencyInput
                    name="offsetZrh"
                    fixedDecimalScale
                    decimalScale={2}
                    control={control}
                    label={<FormFieldLabel>Offset ZRH</FormFieldLabel>}
                    fullWidth
                    allowNegative={true}
                    autoComplete="off"
                    rules={{ required: "Enter a value" }}
                    onBlur={handleOffsetChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormCurrencyInput
                    name="offsetLux"
                    fixedDecimalScale
                    decimalScale={2}
                    control={control}
                    label={<FormFieldLabel>Offset LUX</FormFieldLabel>}
                    fullWidth
                    allowNegative={true}
                    autoComplete="off"
                    rules={{ required: "Enter a value" }}
                    onBlur={handleOffsetChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormCurrencyInput
                    name="offsetSg"
                    fixedDecimalScale
                    decimalScale={2}
                    control={control}
                    label={<FormFieldLabel>Offset SG</FormFieldLabel>}
                    fullWidth
                    allowNegative={true}
                    autoComplete="off"
                    rules={{ required: "Enter a value" }}
                    onBlur={handleOffsetChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormCurrencyInput
                    name="offsetMco"
                    fixedDecimalScale
                    decimalScale={2}
                    control={control}
                    label={<FormFieldLabel>Offset MCO</FormFieldLabel>}
                    fullWidth
                    allowNegative={true}
                    autoComplete="off"
                    rules={{ required: "Enter a value" }}
                    onBlur={handleOffsetChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormCurrencyInput
                    name="offsetBhs"
                    fixedDecimalScale
                    decimalScale={2}
                    control={control}
                    label={<FormFieldLabel>Offset BHS</FormFieldLabel>}
                    fullWidth
                    allowNegative={true}
                    autoComplete="off"
                    rules={{ required: "Enter a value" }}
                    onBlur={handleOffsetChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormCurrencyInput
                    name="offsetHk"
                    fixedDecimalScale
                    decimalScale={2}
                    control={control}
                    label={<FormFieldLabel>Offset HK</FormFieldLabel>}
                    fullWidth
                    allowNegative={true}
                    autoComplete="off"
                    rules={{ required: "Enter a value" }}
                    onBlur={handleOffsetChange}
                  />
                </Grid>
              </Grid>
            </form>
          )}

          {submitError && (
            <Alert severity="error" sx={{ mt: "10px" }}>
              {submitError}
            </Alert>
          )}

          {!isFetching && (
            <AlertMessage
              show={submitSuccessfull}
              message="Data has been updated correctly"
            />
          )}
        </Box>
      )}
    />
  );
};
