import { useContext } from "react";
import { TableContext } from "./Context";

export const useTable = () => {
    const context = useContext(TableContext);
    if (!context) {
      throw new Error("This component must be used within a Table component.");
    }
    return context;
  };
  