import { Breadcrumbs } from "../../components/core/Breadcrumbs";
import { UpsertExemptionCodeMappingForm } from "../../features/parameters/exemption-code-mapping/UpsertExemptionCodeMappingForm";
import { AuthenticatedLayout } from "../../features/user/layouts/AuthendicatedLayout";

export const CreateExemptionCodeMapping = () => {
    return (
        <AuthenticatedLayout>
            <Breadcrumbs />
            <UpsertExemptionCodeMappingForm />
        </AuthenticatedLayout>
    );
};
