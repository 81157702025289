import { Box } from "@mui/material";
import Button from "../Button";
import { Modal } from "../Modal";

type ConfirmModalProps = {
  message: string;
  open: boolean;
  onClose: () => void;
};

const ConfirmModal = ({ message, open, onClose }: ConfirmModalProps) => {
  return (
    <Modal
      title="TTDF"
      open={open}
      handleClose={onClose}
      actions={
        <Button onClick={onClose} variant="contained" color="primary">
          Ok
        </Button>
      }
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>{message}</Box>
    </Modal>
  );
};

export default ConfirmModal;
