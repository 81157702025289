import { useEffect, useMemo, useState, useCallback } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { SelectChangeEvent } from "@mui/material";
import authorizedCall from "../../../../utils/authorizedCall";
import {
  FileImportControllerService,
  ImportControllerService,
  EntityModelFileImportMapping,
  EntityModelEarliestFile,
} from "../../../../openapi";

export type FileImportFormInput = {
  formatId: string;
  reportMonth: string;
  dateCreate: string;
  importName: string;
  isMissing: boolean;
};

type Args = {
  onClose?: () => void;
};

const defaultValues = {
  formatId: "",
  reportMonth: "",
  dateCreate: "",
  importName: "",
  isMissing: false,
};

export const useFileImportModal = (params: Args) => {
  const [formats, setFormats] = useState<EntityModelFileImportMapping[]>([]);
  const [formatsIsFetching, setFormatsIsFetching] = useState(false);
  const [formatsError, setFormatsError] = useState("");
  const [fileToImport, setFileToImport] = useState<EntityModelEarliestFile>();
  const [fileToImportIsFetching, setFileToImportIsFetching] = useState(false);
  const [fileToImportError, setFileToImportError] = useState(false);
  const [executeRes, setExecuteRes] = useState();
  const [isExecuting, setIsExecuting] = useState(false);
  const [executeError, setExecuteError] = useState("");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [originalImportName, setOriginalImportName] = useState("");

  useEffect(() => {
    setFormatsIsFetching(true);
    authorizedCall(FileImportControllerService.getFileImportMappingGrouped, {
      size: 100,
      _case: "IMPORT",
    })
      .then((data) => {
        setFormats(data);
      })
      .catch((err) => setFormatsError(err.body.message))
      .finally(() => {
        setFormatsIsFetching(false);
      });
  }, []);

  const form = useForm<FileImportFormInput>({
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    control,
    setValue,
    getValues,
    trigger,
    clearErrors,
  } = form;

  const formatId = useWatch({ control, name: "formatId" });
  const reportMonth = useWatch({ control, name: "reportMonth" });
  const dateCreate = useWatch({ control, name: "dateCreate" });
  const isMissing = useWatch({ control, name: "isMissing" });

  useEffect(() => {
    if (
      !dateCreate &&
      !reportMonth &&
      !fileToImport &&
      !fileToImportIsFetching
    ) {
      const fallbackImportName =
        formats.find((format) => format.id === Number(formatId))?.fileName ??
        "";
      setValue("importName", fallbackImportName);
    }
  }, [
    dateCreate,
    fileToImport,
    fileToImportIsFetching,
    formatId,
    formats,
    isMissing,
    originalImportName,
    reportMonth,
    setValue,
  ]);

  const importNamePrefix =
    originalImportName.split("_").slice(0, 4).join("_") + "_";

  const importNameExt = originalImportName.slice(
    originalImportName.lastIndexOf(".")
  );

  const shouldDisableInput = !formatId || !isMissing;

  const shouldDisableCheckbox =
    !formatId || (!!fileToImport && Object.keys(fileToImport).length > 0);

  const handleCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (!checked) {
        clearErrors();
      }
      setValue("dateCreate", checked ? "" : String(fileToImport?.month ?? ""));
      setValue(
        "reportMonth",
        checked ? "" : String(fileToImport?.creationDate ?? "")
      );
      setValue("importName", String(fileToImport?.fileName));
    },
    [
      clearErrors,
      fileToImport?.creationDate,
      fileToImport?.fileName,
      fileToImport?.month,
      setValue,
    ]
  );

  const handleDateCreateChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const dateCreate = event.currentTarget.value;

      if (!dateCreate) {
        setValue(
          "importName",
          `${importNamePrefix}${reportMonth}_[CreationDate]${importNameExt}`
        );
      } else {
        setValue(
          "importName",
          `${importNamePrefix}${reportMonth}_${dateCreate}${importNameExt}`
        );
      }
    },
    [importNameExt, importNamePrefix, reportMonth, setValue]
  );

  const handleReportMonthChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const reportMonth = event.currentTarget.value;

      if (!reportMonth) {
        setValue(
          "importName",
          `${importNamePrefix}[ReportMonth]_${dateCreate}${importNameExt}`
        );
      } else {
        setValue(
          "importName",
          `${importNamePrefix}${reportMonth}_${dateCreate}${importNameExt}`
        );
      }
    },
    [dateCreate, importNameExt, importNamePrefix, setValue]
  );

  const getEarliestFileToImport = useCallback(
    (formatId: string) => {
      if (formatId === "") {
        reset(defaultValues);
      } else {
        authorizedCall(FileImportControllerService.getEarliestFileToImport, {
          id: Number(formatId),
        })
          .then((data) => {
            const file = data[0];
            setFileToImport(file);

            if (file) {
              reset({
                ...getValues(),
                reportMonth: file?.month ? String(file?.month) : "",
                dateCreate: file?.creationDate
                  ? String(file?.creationDate)
                  : "",
                importName: file.fileName ?? "",
                isMissing: false,
              });

              setOriginalImportName(file.fileName ?? "");
            } else {
              const fallbackImportName =
                formats.find((format) => format.id === Number(formatId))
                  ?.fileName ?? "";

              if (fallbackImportName) {
                reset({
                  ...getValues(),
                  reportMonth: "",
                  dateCreate: "",
                  importName: fallbackImportName,
                  isMissing: true,
                });
                setOriginalImportName(fallbackImportName);
              }
            }
          })
          .catch((err) => setFileToImportError(err.body.message))
          .finally(() => {
            setFileToImportIsFetching(false);
          });
      }
    },
    [formats, getValues, reset]
  );

  const handleFormatSelect = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      setOriginalImportName("");
      setFileToImportIsFetching(true);
      getEarliestFileToImport(String(event.target.value));
    },
    [getEarliestFileToImport]
  );

  const handleCloseConfirmModal = useCallback(() => {
    setIsConfirmModalOpen(false);

    if (executeRes) {
      getEarliestFileToImport(formatId);
    }
  }, [executeRes, formatId, getEarliestFileToImport]);

  const handleOpenConfirmModal = useCallback(async () => {
    const canOpenConfirmModal = await trigger([
      "formatId",
      "dateCreate",
      "reportMonth",
      "importName",
    ]);

    setExecuteRes(undefined);
    setExecuteError("");

    if (canOpenConfirmModal) {
      setIsConfirmModalOpen(true);
    }
  }, [trigger]);

  const formatsFieldOptions = useMemo(
    () =>
      formats?.map(({ id, importName: formatId, fileName }) => ({
        value: String(id) ?? "",
        label: formatId ?? "",
        fileName,
      })),
    [formats]
  );

  const onSubmit: SubmitHandler<FileImportFormInput> = (data) => {
    const { formatId, reportMonth, dateCreate, importName } = data;

    const importNameSelected = formats.find(
      (f) => f.id === Number(formatId)
    )?.importName;

    setIsExecuting(true);

    authorizedCall(
      ImportControllerService.importFile,
      {
        requestBody: {
          fileImportMappingId: Number(formatId),
          fileName: importName,
          reportMonth: reportMonth,
          dateCreate: dateCreate,
          importNameSelected: String(importNameSelected),
          fileMissing: isMissing,
        },
      },
      true
    )
      .then((data) => {
        setExecuteRes(data);
      })
      .catch((err) => {
        if (err.status === 504) {
          setExecuteError(
            "Process is taking more time than expected. Please check import log after few minutes."
          );
        } else {
          setExecuteError(err.body.message);
        }
      })
      .finally(() => {
        setIsExecuting(false);
      });
  };

  return {
    control,
    formats,
    fileToImportError,
    executeRes,
    executeError,
    formatsError,
    isExecuting,
    handleCloseConfirmModal,
    isConfirmModalOpen,
    fileToImport,
    shouldDisableInput,
    shouldDisableCheckbox,
    formatsFieldOptions,
    handleFormatSelect,
    handleReportMonthChange,
    handleDateCreateChange,
    fileToImportIsFetching,
    handleOpenConfirmModal,
    handleCheck,
    handleSubmit: handleSubmit(onSubmit),
    setFormatsIsFetching,
    formatsIsFetching,
  };
};
