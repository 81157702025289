export const columns = [
  {
    label: "Monat",
    id: "month",
    numeric: false,
  },
  {
    label: "Exclusions Code",
    id: "exclusionsCode",
    numeric: false,
  },
  {
    label: "Exclusions Code Description",
    id: "exclusionsDescription",
    numeric: false,
  },
  {
    label: "Type",
    id: "p1|p2",
  },
  {
    label: "Number of transactions",
    id: "p1CashAmountCnt|p2CashAmountCnt",
    numeric: true,
  },
  {
    label: "Taxable amount",
    id: "p1CashAmount|p2CashAmount",
    numeric: true,
    fillWithZeros: true,
  },
  {
    label: "Amount owed",
    id: "p1TaxAmount|p2TaxAmount",
    numeric: true,
    fillWithZeros: true,
  },
];
