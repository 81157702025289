import React from "react";
import { AuthenticatedLayout } from "../../features/user/layouts/AuthendicatedLayout";
import { Breadcrumbs } from "../../components/core/Breadcrumbs";
import ImportFileLogTable from "../../features/import/import-file-log/ImportFileLogTable/ImportFileLogTable";

export const ImportFileLog = (): React.ReactElement => {
  return (
    <AuthenticatedLayout>
      <Breadcrumbs />
      <ImportFileLogTable />
    </AuthenticatedLayout>
  );
};

export default ImportFileLog;
