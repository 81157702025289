import { Breadcrumbs } from "../../components/core/Breadcrumbs";
import { UpsertSafekeepingMappingForm } from "../../features/parameters/safekeeping-mapping/UpsertSafekeepingMappingForm";

import { AuthenticatedLayout } from "../../features/user/layouts/AuthendicatedLayout";

export const CreateSafekeepingMapping = () => {
  return (
    <AuthenticatedLayout>
      <Breadcrumbs />
      <UpsertSafekeepingMappingForm />
    </AuthenticatedLayout>
  );
};
