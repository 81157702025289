import { CSSProperties } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";

const StyledBackButton = styled(Button)`
  border-radius: 0;
  text-transform: none;
  font-weight: bold;
`;

type BackButtonProps = {
  onClick: () => void;
  disabled: boolean;
  style: CSSProperties;
};

const BackButton = ({ onClick, disabled, style }: BackButtonProps) => {
  return (
    <StyledBackButton
      onClick={onClick}
      variant="outlined"
      color="secondary"
      startIcon={<ArrowBackOutlinedIcon />}
      disabled={disabled}
      sx={style}
    >
      Back
    </StyledBackButton>
  );
};

BackButton.defaultProps = {
  onClick: () => {},
  disabled: false,
  style: {},
};

export default BackButton;
