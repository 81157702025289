import {
  Control,
  Controller,
  ControllerProps,
  FieldError,
  Path,
} from "react-hook-form";
import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
  FormHelperText,
  useTheme,
} from "@mui/material";
import { FieldValues } from "react-hook-form/dist/types/fields";

export type FormCheckboxProps<T extends FieldValues = FieldValues> = Omit<
  CheckboxProps,
  "name"
> & {
  rules?: ControllerProps["rules"];
  name: Path<T>;
  parseError?: (error: FieldError) => string;
  label?: FormControlLabelProps["label"];
  helperText?: string;
  control?: Control<any, any>;
  fullWidth?: boolean;
  variant?: "outlined";
  handleChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
};

export const FormCheckbox = <T extends FieldValues = FieldValues>({
  name,
  rules = {},
  required,
  parseError,
  label,
  control,
  helperText,
  fullWidth = false,
  handleChange,
  variant,
  ...rest
}: FormCheckboxProps<T>): JSX.Element => {
  const theme = useTheme();

  if (required) {
    rules.required = "This field is required";
  } else {
    rules.required = false;
  }

  const outlinedBoxStyle = (error: boolean) => {
    return {
      border: `1px solid ${error ? theme.palette.error.main : "#00000040"}`,
      padding: "6px 14px",
      width: "inherit",
    };
  };

  const checkboxStyle = (error: boolean) => {
    return {
      ...rest.sx,
      color: error ? theme.palette.error.main : undefined,
    };
  };

  const labelStyle = (error: boolean) => {
    return {
      color: error ? theme.palette.error.main : theme.palette.text.primary,
    };
  };

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        const isError = Boolean(error?.message);
        const parsedHelperText = error
          ? typeof parseError === "function"
            ? parseError(error)
            : error.message
          : helperText;
        return (
          <FormControl
            required={required}
            error={isError}
            sx={{ width: fullWidth ? "100%" : "auto" }}
          >
            <FormGroup row sx={{ width: "inherit" }}>
              <Box sx={variant === "outlined" ? outlinedBoxStyle(isError) : {}}>
                <FormControlLabel
                  label={label || ""}
                  sx={labelStyle(isError)}
                  control={
                    <Checkbox
                      {...rest}
                      color={rest.color || "primary"}
                      sx={checkboxStyle(isError)}
                      value={value}
                      checked={Boolean(value)}
                      onChange={() => {
                        handleChange?.(value, !value);
                        onChange(!value);
                      }}
                    />
                  }
                />
              </Box>
            </FormGroup>
            {parsedHelperText && (
              <FormHelperText error={isError}>
                {parsedHelperText}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};
