import {
  FormControl,
  FormControlProps,
  InputLabelProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  SelectProps as MuiSelectProps,
  Box,
  Select as MuiSelect,
  Grid,
  IconButton,
  SelectChangeEvent,
} from "@mui/material";
import { FieldError } from "react-hook-form";
import { CloseOutlined } from "@mui/icons-material";
import { useMemo, useCallback } from "react";

export type SelectOption = {
  label: string;
  value: string;
};

export type SelectProps = Omit<
  Omit<InputLabelProps, "size" | "onChange"> &
    Omit<MuiSelectProps, "size" | "onChange"> & {
      options: SelectOption[];
      size?: FormControlProps["size"];
      handleChange?: (...event: any[]) => void;
      onChange?: (event: any) => void;
    },
  "error"
> & {
  error?: FieldError;
};

export const Select = ({
  error,
  size,
  name,
  label,
  onChange,
  onBlur,
  value,
  options,
  style,
  ...restProps
}: SelectProps) => {
  const selected = useMemo(() => {
    return Boolean(value) ?? false;
  }, [value]);

  const onValueSelected = useCallback(
    (event: SelectChangeEvent<{}>) => {
      if (!onChange) {
        return;
      }
      onChange(event);
    },
    [onChange]
  );

  const onClear = useCallback(() => {
    if (!onChange) {
      return;
    }
    onChange({
      target: {
        value: "",
      },
    });
  }, [onChange]);

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth error={!!error?.message} size={size}>
        <InputLabel id={`${name}-label`}>{label}</InputLabel>
        <MuiSelect
          name={name}
          id={`${name}-select`}
          labelId={`${name}-label`}
          onChange={onValueSelected}
          onBlur={onBlur}
          value={value ?? ""}
          error={Boolean(error)}
          label={label}
          disabled={options.length === 0}
          data-testid={name}
          style={{
            ...style,
            backgroundColor: options.length === 0 ? "#f2f2f2" : "#fff",
          }}
          {...restProps}
          endAdornment={
            selected ? (
              <IconButton onClick={onClear}>
                <CloseOutlined />
              </IconButton>
            ) : null
          }
        >
          {options.map((option) => {
            return (
              <MenuItem
                key={`${option.label}${option.value}`}
                value={option.value}
              >
                {option.label.includes("|") ? (
                  <Grid container columnSpacing={8}>
                    {option.label.split("|").map((word, index) => (
                      <Grid
                        item
                        xs={12 / option.label.split("|").length}
                        key={index}
                      >
                        {word}
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Grid>{option.label}</Grid>
                )}
              </MenuItem>
            );
          })}
        </MuiSelect>
        <FormHelperText>{error?.message ?? null}</FormHelperText>
      </FormControl>
    </Box>
  );
};
