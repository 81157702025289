import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "./authentication-templates";
import { Routes, Route, Navigate } from "react-router-dom";
import { Adjustment } from "../pages/Adjustment";
import { CreateAdjustment } from "../pages/CreateAdjustment";
import { EditAdjustment } from "../pages/EditAdjustment";
import { EditReconciliation } from "../pages/EditReconciliation";
import { Home } from "../pages/Home";
import { Login } from "../pages/Login";
import { Reconciliation } from "../pages/Reconciliation";
import { ProtectedRoute } from "./ProtectedRoute";
import { TaxForm } from "../pages/TaxForm";
import { ReconciliationMapping } from "../pages/ReconciliationMapping";
import { EditReconciliationMapping } from "../pages/EditReconciliationMapping";
import { CreateReconciliationMapping } from "../pages/CreateReconciliationMapping";
import ImportFileMapping from "../pages/ImportFileMapping/ImportFileMapping";
import EditImportFileMapping from "../pages/EditImportFileMapping/EditImportFileMapping";
import CreateImportFileMapping from "../pages/CreateImportFileMapping/CreateImportFileMapping";
import ImportFileLog from "../pages/ImportFileLog/ImportFileLog";
import { ExportParameter } from "../pages/ExportParameter";
import { EditExportParameter } from "../pages/EditExportParameter/EditExportParameter";
import { CreateExportParameter } from "../pages/CreateExportParameter";
import { SafeKeepingMapping } from "../pages/SafekeepingMapping";
import { EditSafekeepingMapping } from "../pages/EditSafekeepingMapping";
import { CreateSafekeepingMapping } from "../pages/CreateSafekeepingMapping";
import ExportFileLog from "../pages/ExportFileLog/ExportFileLog";
import ExemptionCodeMapping from "../pages/ExemptionCodeMapping/ExemptionCodeMapping";
import { CreateExemptionCodeMapping } from "../pages/CreateExemptionCodeMapping";
import EditExemptionCodeMapping from "../pages/EditExemptionCodeMapping/EditExemptionCodeMapping";

export const AppRoutes = () => {
  return (
    <>
      <UnauthenticatedTemplate>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route
            path="table/reconciliation"
            element={
              <ProtectedRoute>
                <Reconciliation />
              </ProtectedRoute>
            }
          />
          <Route
            path="table/adjustment"
            element={
              <ProtectedRoute>
                <Adjustment />
              </ProtectedRoute>
            }
          />
          <Route
            path="table/import-file-log"
            element={
              <ProtectedRoute>
                <ImportFileLog />
              </ProtectedRoute>
            }
          />
          <Route
            path="table/export-file-log"
            element={
              <ProtectedRoute>
                <ExportFileLog />
              </ProtectedRoute>
            }
          />
          <Route
            path="table/reconciliation/edit/:id"
            element={
              <ProtectedRoute>
                <EditReconciliation />
              </ProtectedRoute>
            }
          />
          <Route
            path="table/adjustment/edit/:id"
            element={
              <ProtectedRoute>
                <EditAdjustment />
              </ProtectedRoute>
            }
          />
          <Route
            path="table/adjustment/add/:countryTypeId"
            element={
              <ProtectedRoute>
                <CreateAdjustment />
              </ProtectedRoute>
            }
          />
          <Route
            path="table/tax-form"
            element={
              <ProtectedRoute>
                <TaxForm />
              </ProtectedRoute>
            }
          />

          <Route
            path="table/exemption-code-mapping"
            element={
              <ProtectedRoute>
                <ExemptionCodeMapping />
              </ProtectedRoute>
            }
          />

          <Route
            path="table/exemption-code-mapping/add"
            element={
              <ProtectedRoute>
                <CreateExemptionCodeMapping />
              </ProtectedRoute>
            }
          />
          <Route
            path="table/exemption-code-mapping/edit/:id"
            element={
              <ProtectedRoute>
                <EditExemptionCodeMapping />
              </ProtectedRoute>
            }
          />

          <Route
            path="table/import-file-mapping"
            element={
              <ProtectedRoute>
                <ImportFileMapping />
              </ProtectedRoute>
            }
          />
          <Route
            path="table/import-file-mapping/edit/:id"
            element={
              <ProtectedRoute>
                <EditImportFileMapping />
              </ProtectedRoute>
            }
          />
          <Route
            path="table/import-file-mapping/add"
            element={
              <ProtectedRoute>
                <CreateImportFileMapping />
              </ProtectedRoute>
            }
          />
          <Route
            path="table/reconciliation-mapping"
            element={
              <ProtectedRoute>
                <ReconciliationMapping />
              </ProtectedRoute>
            }
          />

          <Route
            path="table/reconciliation-mapping/edit/:id"
            element={
              <ProtectedRoute>
                <EditReconciliationMapping />
              </ProtectedRoute>
            }
          />

          <Route
            path="table/reconciliation-mapping/add"
            element={
              <ProtectedRoute>
                <CreateReconciliationMapping />
              </ProtectedRoute>
            }
          />

          <Route
            path="table/export-parameter"
            element={
              <ProtectedRoute>
                <ExportParameter />
              </ProtectedRoute>
            }
          />
          <Route
            path="table/export-parameter/edit/:id"
            element={
              <ProtectedRoute>
                <EditExportParameter />
              </ProtectedRoute>
            }
          />

          <Route
            path="table/export-parameter/add"
            element={
              <ProtectedRoute>
                <CreateExportParameter />
              </ProtectedRoute>
            }
          />

          <Route
            path="table/safekeeping-mapping"
            element={
              <ProtectedRoute>
                <SafeKeepingMapping />
              </ProtectedRoute>
            }
          />
          <Route
            path="table/safekeeping-mapping/edit/:id"
            element={
              <ProtectedRoute>
                <EditSafekeepingMapping />
              </ProtectedRoute>
            }
          />

          <Route
            path="table/safekeeping-mapping/add"
            element={
              <ProtectedRoute>
                <CreateSafekeepingMapping />
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthenticatedTemplate>
    </>
  );
};
