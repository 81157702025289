import React from "react";
import { EntityTable } from "../../../../components/core/EntityTable";
import columns from "./config.json";
import { LogControllerService } from "../../../../openapi";

export const ImportFileLogTable = (): React.ReactElement => {
  return (
    <EntityTable
      title={"TTDF Import File Log"}
      columns={columns}
      getService={(
        params: Parameters<typeof LogControllerService.getLogs>[0]
      ) =>
        LogControllerService.getLogs({
          ...params,
          type: "IMPORT",
        })
      }
      sortConfig={{ field: "fileTimestamp", order: "desc" }}
    />
  );
};

export default ImportFileLogTable;
