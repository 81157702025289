/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExemptionCodeMappingDto } from '../models/ExemptionCodeMappingDto';
import type { PagedModelEntityModelExemptionCode } from '../models/PagedModelEntityModelExemptionCode';
import type { PagedModelEntityModelExemptionCodeMapping } from '../models/PagedModelEntityModelExemptionCodeMapping';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExemptionCodeMappingControllerService {

    /**
     * Returns a paged list of ExemptionCodeMapping records
     * @returns PagedModelEntityModelExemptionCodeMapping Paged list of ExemptionCodeMapping records
     * @throws ApiError
     */
    public static getExemptionCodeMapping({
authorization,
page,
size = 10,
sort,
search,
exemptionCode,
exemptionCodeTc,
id,
}: {
authorization: string,
/**
 * Zero-based page index (0..N)
 */
page?: number,
/**
 * The size of the page to be returned
 */
size?: number,
/**
 * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
 */
sort?: Array<string>,
search?: string,
exemptionCode?: string,
exemptionCodeTc?: string,
id?: number,
}): CancelablePromise<PagedModelEntityModelExemptionCodeMapping> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/mapping/exemption/code',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'page': page,
                'size': size,
                'sort': sort,
                'search': search,
                'exemptionCode': exemptionCode,
                'exemptionCodeTc': exemptionCodeTc,
                'id': id,
            },
            errors: {
                403: `Unauthorized user`,
            },
        });
    }

    /**
     * Adds a new ExemptionCodeMapping record
     * @returns PagedModelEntityModelExemptionCodeMapping Record added successfully
     * @throws ApiError
     */
    public static addExemptionCodeMapping({
authorization,
requestBody,
}: {
authorization: string,
requestBody: ExemptionCodeMappingDto,
}): CancelablePromise<PagedModelEntityModelExemptionCodeMapping> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/mapping/exemption/code',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Unauthorized user`,
            },
        });
    }

    /**
     * Deletes an existing ExemptionCodeMapping record
     * @returns void 
     * @throws ApiError
     */
    public static deleteExemptionCodeMapping({
authorization,
id,
}: {
authorization: string,
id: number,
}): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/mapping/exemption/code/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                403: `Unauthorized user`,
                404: `Record not found`,
            },
        });
    }

    /**
     * Update some ExemptionCodeMapping fields
     * @returns void 
     * @throws ApiError
     */
    public static updateExemptionCodeMapping({
authorization,
id,
requestBody,
}: {
authorization: string,
id: number,
requestBody: ExemptionCodeMappingDto,
}): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/mapping/exemption/code/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Unauthorized user`,
                404: `Record not found`,
            },
        });
    }

    /**
     * Returns a paged list of ExemptionCode records
     * @returns PagedModelEntityModelExemptionCode Paged list of ExemptionCode records
     * @throws ApiError
     */
    public static getExemptionCodes({
authorization,
page,
size = 10,
sort,
search,
country,
code,
exemptionCodeMappings,
description,
id,
}: {
authorization: string,
/**
 * Zero-based page index (0..N)
 */
page?: number,
/**
 * The size of the page to be returned
 */
size?: number,
/**
 * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
 */
sort?: Array<string>,
search?: string,
country?: string,
code?: string,
exemptionCodeMappings?: string,
description?: string,
id?: number,
}): CancelablePromise<PagedModelEntityModelExemptionCode> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/mapping/exemption',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'page': page,
                'size': size,
                'sort': sort,
                'search': search,
                'country': country,
                'code': code,
                'exemptionCodeMappings': exemptionCodeMappings,
                'description': description,
                'id': id,
            },
            errors: {
                403: `Unauthorized user`,
            },
        });
    }

}
