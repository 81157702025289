import { useState, useEffect, useCallback, useMemo } from "react";
import { TaxFormNonStandardTable } from "./TaxFormNonStandardTable";
import { TaxFormStandardTable } from "./TaxFormStandardTable";
import { StyledSection } from "../../../../components/core/EntityTable/index.style";
import { Grid, Container } from "@mui/material";
import { Card } from "../../../../components/core/Card";
import { TaxFormTableActions } from "./TaxFormTableActions";
import {
  TaxForm,
  clearCache,
  getTaxForm,
  postTaxRecalculation,
} from "./services";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../../../../components/core/ConfirmModal/ConfirmModal";
import { ITableItem } from "../../../../components/core/Table/types";

export const TaxFormTable = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [taxForm, setTaxForm] = useState<TaxForm>();

  const recalculateTax = async (bookingCenter: string, fiscalYear: string) => {
    try {
      await postTaxRecalculation(bookingCenter, Number(fiscalYear));
      await updateTaxForm();
      setModalMessage("All data has been calculated.");
    } catch (err: any) {
      setModalMessage(err.body?.message ?? err);
    } finally {
      setModalOpen(true);
    }
  };

  const updateTaxForm = async () => {
    const data = await getTaxForm();
    setTaxForm(data);
  };

  const handleBack = useCallback(() => {
    navigate("/");
  }, [navigate]);

  useEffect(() => {
    clearCache();
    updateTaxForm();
  }, []);

  const totalAmountOwed: number = useMemo(() => {
    return taxForm?.totalAmount ?? 0;
  }, [taxForm?.totalAmount]);

  const standardTaxItems: ITableItem[] = useMemo(() => {
    return (taxForm?.standardTaxList ?? []) as any;
  }, [taxForm?.standardTaxList]);

  const nonStandardTaxItems: ITableItem[] = useMemo(() => {
    return (taxForm?.nonStandardTaxList ?? []) as any;
  }, [taxForm?.nonStandardTaxList]);

  return (
    <>
      <ConfirmModal
        message={modalMessage}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
      <Card
        title={"TTDF Tax Form"}
        Component={StyledSection}
        style={{ width: "100%" }}
        actions={
          <TaxFormTableActions
            onSearchTermChange={(searched: string) => {}}
            onRecalculateClick={recalculateTax}
            handleBack={handleBack}
          />
        }
      >
        <Grid item xs={12}>
          <Container maxWidth="xl">
            <Grid container spacing={3}>
              <TaxFormStandardTable data={standardTaxItems} />
            </Grid>
          </Container>
        </Grid>
        <p style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}>
          Total amount owed: {totalAmountOwed}
        </p>
        <Grid item xs={12}>
          <Container maxWidth="xl">
            <Grid container spacing={3}>
              <TaxFormNonStandardTable data={nonStandardTaxItems} />
            </Grid>
          </Container>
        </Grid>
      </Card>
    </>
  );
};
