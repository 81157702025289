import { EntityTable } from "../../../../components/core/EntityTable";
import { ExemptionCodeMappingControllerService } from "../../../../openapi";
import columns from "./config.json";

export const ExemptionCodeMappingTable = () => (
  <EntityTable
    title={"TTDF Exemption Code Mapping"}
    columns={columns}
    editRoute={"/table/exemption-code-mapping/edit"}
    addRoute={"/table/exemption-code-mapping/add"}
    deleteRoute={"/table/exemption-code-mapping/delete"}
    getService={ExemptionCodeMappingControllerService.getExemptionCodeMapping}
    deleteService={
      ExemptionCodeMappingControllerService.deleteExemptionCodeMapping
    }
  />
);

export default ExemptionCodeMappingTable;
