import { useEffect } from "react";
import { columns } from "./non-standard-config";
import { getNonStandardTaxList } from "../services";
import { TaxTableBase } from "../components/TaxTableBase";
import { useEntityTable } from "../../../../../components/core/EntityTable/useEntityTable";
import { Box, TableCell, TableRow } from "@mui/material";
import { ITableItem } from "../../../../../components/core/Table/types";

type TaxFormNonStandardTableProps = {
  data: ITableItem[];
};

export const TaxFormNonStandardTable = ({
  data,
}: TaxFormNonStandardTableProps) => {
  const {
    items,
    setItems,
    onRowSelect,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
  } = useEntityTable({
    getService: getNonStandardTaxList,
    columns,
  });

  useEffect(() => {
    setItems(data);
  }, [data, setItems]);

  return (
    <TaxTableBase
      items={items}
      page={page}
      setRowsPerPage={setRowsPerPage}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
      columns={columns}
      onRowSelect={onRowSelect}
      additionalHeader={
        <TableRow>
          <TableCell colSpan={3}></TableCell>
          <TableCell align="center" colSpan={2}>
            <Box sx={{ ml: "20%" }}>Paragraph 491</Box>
          </TableCell>
          <TableCell align="center" colSpan={2}>
            <Box sx={{ ml: "20%" }}> Paragraph 492</Box>
          </TableCell>
        </TableRow>
      }
    />
  );
};
