import { UserPanel } from "../../features/user/components/UserPanel";
import { Breadcrumbs } from "../../components/core/Breadcrumbs";
import { AuthenticatedLayout } from "../../features/user/layouts/AuthendicatedLayout";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useEffect } from "react";

export const Home = () => {
  const [, setCountryTypeId] = useLocalStorage(
    "countryTypeId",
    "setCountryTypeId"
  );

  useEffect(() => {
    setCountryTypeId("");
  }, [setCountryTypeId]);

  return (
    <AuthenticatedLayout>
      <Breadcrumbs />
      <UserPanel />
    </AuthenticatedLayout>
  );
};
