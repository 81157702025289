import React from "react";
import { AuthenticatedLayout } from "../../features/user/layouts/AuthendicatedLayout";
import { Breadcrumbs } from "../../components/core/Breadcrumbs";
import { ExemptionCodeMappingTable } from "../../features/parameters/exemption-code-mapping/ExemptionCodeMappingTable";

export const ExemptionCodeMapping = (): React.ReactElement => {
  return (
    <AuthenticatedLayout>
      <Breadcrumbs />
      <ExemptionCodeMappingTable />
    </AuthenticatedLayout>
  );
};

export default ExemptionCodeMapping;
