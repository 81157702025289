import type { ReactNode } from "react";
import AppHeader from "../../../../components/core/AppHeader";
import { PageLayout } from "../../../../components/layouts/PageLayout";

export interface AuthenticatedLayoutProps {
  children: ReactNode;
}

export const AuthenticatedLayout = (props: AuthenticatedLayoutProps) => {
  const { children } = props;

  return (
    <PageLayout>
      <AppHeader authenticated={true} />
      {children}
    </PageLayout>
  );
};
