import { ReactNode } from "react";
import { Box } from "@mui/material";
import { SearchBar } from "../SearchBar";
import { ButtonWrapper, StyledButton } from "./index.style";
import { OnActionCb } from "./types";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import BackButton from "../BackButton";

interface EntityTableActionsProps {
  hideSearchBar?: boolean;
  enabledActions: boolean;
  canEditItem: boolean;
  onSearchTermChange: (searched: string) => void;
  onEditItem: OnActionCb;
  onDeleteItem: OnActionCb;
  onAddItem: OnActionCb;
  onBackItem: OnActionCb;
  handleOpenModal: OnActionCb;
  canDeleteItem: boolean;
  canAddItem: boolean;
  additionalActions?: ReactNode;
}

export const EntityTableActions = (props: EntityTableActionsProps) => {
  const {
    hideSearchBar = false,
    onSearchTermChange,
    enabledActions,
    onAddItem,
    onEditItem,
    onDeleteItem,
    onBackItem,
    handleOpenModal,
    canDeleteItem,
    canAddItem,
    canEditItem,
    additionalActions,
  } = props;

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box style={{ marginRight: "15px" }}>
        {!hideSearchBar && <SearchBar setSearchQuery={onSearchTermChange} />}
      </Box>

      {additionalActions}

      <ButtonWrapper>
        {onAddItem && (
          <StyledButton
            variant="contained"
            onClick={onAddItem}
            disabled={!canAddItem}
            endIcon={<AddCircleOutlineOutlinedIcon />}
          >
            Add
          </StyledButton>
        )}
        {onEditItem && (
          <StyledButton
            variant="outlined"
            color="secondary"
            onClick={onEditItem}
            disabled={!enabledActions || !canEditItem}
            endIcon={<EditOutlinedIcon />}
          >
            Edit
          </StyledButton>
        )}
        {onDeleteItem && (
          <StyledButton
            variant="contained"
            color="error"
            onClick={handleOpenModal}
            disabled={!enabledActions || !canDeleteItem}
            endIcon={<DeleteOutlineOutlinedIcon />}
          >
            Delete
          </StyledButton>
        )}
        {onBackItem && <BackButton onClick={onBackItem} />}
      </ButtonWrapper>
    </Box>
  );
};
