export const columns = [
  {
    label: "Exclusions Code",
    id: "exclusionsCode",
    numeric: false,
  },
  {
    label: "Exclusions Code Description",
    id: "exclusionsDescription",
    numeric: false,
  },
  {
    label: "Number of transactions",
    id: "numberOfTransactions491",
    numeric: true,
  },
  {
    label: "Taxable amount",
    id: "taxableAmount491",
    numeric: true,
    fillWithZeros: true,
  },
  {
    label: "Number of transactions",
    id: "numberOfTransactions492",
    numeric: true,
  },
  {
    label: "Notional value",
    id: "notionalValue492",
    numeric: true,
    fillWithZeros: true,
  },
];
