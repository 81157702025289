import React, { useMemo, ReactNode } from "react";
import { Box, Table as TableBase, TableContainer } from "@mui/material";
import { ITableItem, ITableHeader } from "./types";
import { Pagination } from "./Pagination";
import { TableContext } from "./Context";
import { Head } from "./Head";
import { Body } from "./Body";

export interface TableProps {
  items: ITableItem[];
  columns: ITableHeader[];
  children: ReactNode;
  page: number;
  rowsPerPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
}

export const Table = (props: TableProps) => {
  const {
    items,
    columns,
    children,
    page = 0,
    setPage,
    rowsPerPage = 10,
    setRowsPerPage,
  } = props;

  const value = useMemo(
    () => ({
      items,
      columns,
      page,
      setPage,
      rowsPerPage,
      setRowsPerPage,
    }),
    [columns, items, page, rowsPerPage, setPage, setRowsPerPage]
  );

  return (
    <TableContext.Provider value={value}>
      <Box sx={{ width: "100%" }}>
        <TableContainer>{children}</TableContainer>
      </Box>
    </TableContext.Provider>
  );
};

Table.Head = Head;
Table.Body = Body;
Table.Wrapper = TableBase;
Table.Pagination = Pagination;
