import React from "react";
import { Card } from "../Card";
import { Box, Grid } from "@mui/material";
import { StyledSection } from "./index.style";
import { ITableHeader, SortConfig } from "../Table/types";
import { useEntityTable } from "./useEntityTable";
import { EntityTableActions } from "./EntityTableActions";
import { DeleteModal } from "./DeleteModal";
import { Table } from "../Table";

export type EntityTableProps = {
  title: string;
  editRoute?: string;
  addRoute?: string;
  deleteRoute?: string;
  hideSearchBar?: boolean;
  columns: ITableHeader[];
  deleteService?: any;
  getService: any;
  sortConfig?: SortConfig;
};

export const EntityTable = (props: EntityTableProps) => {
  const {
    title,
    editRoute,
    deleteRoute,
    columns,
    getService,
    deleteService,
    addRoute,
    hideSearchBar,
    sortConfig,
  } = props;

  const {
    onSearchTermChange,
    enabledActions,
    canEditItem,
    onEditItem,
    items,
    onRowSelect,
    setPage,
    onPageSizeChange,
    totalItems,
    onSortOrderChange,
    sortOrder,
    orderByField,
    onAddItem,
    onDeleteItem,
    handleCloseModal,
    handleOpenModal,
    openModal,
    canDeleteItem,
    canAddItem,
    onBackItem,
    error,
    isDeleting,
    rowsPerPage,
    page,
    setRowsPerPage,
  } = useEntityTable({
    getService,
    editRoute,
    addRoute,
    deleteRoute,
    deleteService,
    columns,
    sortConfig,
  });

  return (
    <React.Fragment>
      <DeleteModal
        isDeleting={isDeleting}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        onDelete={onDeleteItem}
        error={error}
      />
      <Card
        title={title}
        Component={StyledSection}
        style={{ width: "100%" }}
        actions={
          <EntityTableActions
            hideSearchBar={hideSearchBar}
            onEditItem={editRoute ? onEditItem : undefined}
            onDeleteItem={deleteService ? onDeleteItem : undefined}
            onAddItem={addRoute ? onAddItem : undefined}
            onBackItem={onBackItem}
            handleOpenModal={handleOpenModal}
            onSearchTermChange={onSearchTermChange}
            enabledActions={enabledActions}
            canEditItem={canEditItem}
            canDeleteItem={canDeleteItem}
            canAddItem={canAddItem}
          />
        }
      >
        <Grid item xs={12}>
          <Box sx={{ width: "100%" }}>
            <Grid container spacing={3}>
              <Table
                items={items}
                columns={columns}
                page={page}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
              >
                <Table.Wrapper>
                  <Table.Head
                    sortConfig={{
                      order: sortOrder,
                      field: orderByField,
                      onOrderChange: onSortOrderChange,
                    }}
                  />
                  <Table.Body
                    readOnly={!canEditItem && !canDeleteItem}
                    onRowSelect={onRowSelect}
                  />
                </Table.Wrapper>
                <Table.Pagination
                  onPageChange={setPage}
                  onPageSizeChange={onPageSizeChange}
                  totalItems={totalItems}
                />
              </Table>
            </Grid>
          </Box>
        </Grid>
      </Card>
    </React.Fragment>
  );
};
