import { Box, Alert, Grid } from "@mui/material";
import { Card } from "../../../../components/core/Card";
import { Spinner } from "../../../../components/core/Spinner";
import { FormInput } from "../../../../components/hook-form/FormInput";
import { FormFieldLabel } from "../../../../components/hook-form/FormFieldLabel";
import { AlertMessage } from "../../../../components/core/AlertMessage";
import { useUpsertReconciliationMappingForm } from "./useUpsertReconciliationMappingForm";
import EditActionButtons from "../../../../components/core/EditActionButtons";

export interface UpsertReconciliationMappingFormProps {
  id?: string;
}

export const UpsertReconciliationMappingForm = (
  props: UpsertReconciliationMappingFormProps
) => {
  const { id } = props;

  const {
    reconciliationMapping,
    reconciliationMappingError,
    reconciliationMappingIsFetching,
    submitSuccessMessage,
    cardTitle,
    onSubmit,
    onBack,
    submitSuccessfull,
    isSubmitting,
    submitError,
    control,
  } = useUpsertReconciliationMappingForm({ id });

  return (
    <Card
      title={cardTitle}
      style={{ width: "1512px" }}
      actions={
        (reconciliationMapping || !id) && (
          <EditActionButtons
            form="upsert-reconciliation-mapping-form"
            loading={isSubmitting}
            onBack={onBack}
          />
        )
      }
      Component={() => (
        <Box sx={{ margin: "20px" }}>
          {reconciliationMappingError && (
            <Alert severity="error">{reconciliationMappingError}</Alert>
          )}
          {reconciliationMappingIsFetching && !reconciliationMappingError && (
            <Spinner height="353px" />
          )}
          {(reconciliationMapping || !id) && (
            <form
              id="upsert-reconciliation-mapping-form"
              data-testid="upsert-reconciliation-mapping-form"
              onSubmit={onSubmit}
            >
              <Grid container columnSpacing={2} rowSpacing={5}>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="country"
                    label={<FormFieldLabel>Country</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="bookingCenter"
                    label={<FormFieldLabel>Booking Center</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    rules={{ required: "Please insert a Booking Center." }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="fileType"
                    label={<FormFieldLabel>File Type</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    type="number"
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="account"
                    label={<FormFieldLabel>Account</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    type="number"
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="comment"
                    label={<FormFieldLabel>Comment</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="fileBookingCenter"
                    label={<FormFieldLabel>File Booking Center</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
            </form>
          )}

          {submitError && (
            <Alert severity="error" sx={{ mt: "10px" }}>
              {submitError}
            </Alert>
          )}

          <AlertMessage
            show={submitSuccessfull}
            message={submitSuccessMessage}
          />
        </Box>
      )}
    />
  );
};
