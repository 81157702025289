import { Box, Alert, Grid } from "@mui/material";
import { Card } from "../../../../components/core/Card";
import { Spinner } from "../../../../components/core/Spinner";
import { FormInput } from "../../../../components/hook-form/FormInput";
import { FormFieldLabel } from "../../../../components/hook-form/FormFieldLabel";
import { AlertMessage } from "../../../../components/core/AlertMessage";
import { useUpsertAdjustmentForm } from "./useUpsertAdjustmentForm";
import { FormCheckbox } from "../../../../components/hook-form/FormCheckbox";
import { ConfirmModal } from "./ConfirmModal";
import { FormSelect } from "../../../../components/hook-form/FormSelect";
import { FormCurrencyInput } from "../../../../components/hook-form/FormCurrencyInput/FormCurrencyInput";
import EditActionButtons from "../../../../components/core/EditActionButtons";
import { isValidDate } from "../../../../utils/dateUtils";

export interface UpsertAdjustmentFormProps {
  id?: string;
  countryTypeId?: string;
}

export const UpsertAdjustmentForm = ({
  id,
  countryTypeId,
}: UpsertAdjustmentFormProps) => {
  const {
    canRender,
    safekeepCountryOptions,
    transactionSource,
    submitError,
    fetchingError,
    isLoading,
    isSubmitting,
    submitSuccessful,
    onSubmit,
    onBack,
    control,
    isTaxFlagY,
    isTaxFlagN,
    taxableOptions,
    exomappingOptions,
    submitSuccessMessage,
    isConfirmModalOpen,
    alertMessage,
    handleCreation,
    handleAbortCreation,
    isNettedFlagRequired,
    isNettedTaFlagDisabled,
    country,
    nettedTaFlag,
  } = useUpsertAdjustmentForm({ id, countryTypeId });

  return (
    <>
      <ConfirmModal
        openModal={isConfirmModalOpen}
        handleCloseModal={handleCreation}
        handleAbortCreation={handleAbortCreation}
        children={alertMessage}
      />
      <Card
        title={countryTypeId ? "Add Adjustment" : "Edit Adjustment"}
        style={{ width: "1512px" }}
        actions={
          !isLoading && (
            <EditActionButtons
              form="edit-adjustment-form"
              loading={isSubmitting}
              onBack={onBack}
            />
          )
        }
        Component={() => (
          <Box sx={{ margin: "20px" }}>
            {fetchingError && <Alert severity="error">{fetchingError}</Alert>}
            {isLoading && <Spinner height="335px" />}
            {canRender && (
              <form
                id="edit-adjustment-form"
                data-testid="edit-adjustment-form"
                onSubmit={onSubmit}
              >
                <Grid container columnSpacing={2} rowSpacing={5}>
                  <Grid item xs={4}>
                    <FormInput
                      control={control}
                      name="recordSource"
                      label={<FormFieldLabel>RCS</FormFieldLabel>}
                      fullWidth
                      autoComplete="off"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormInput
                      control={control}
                      rules={{ required: isTaxFlagY || isTaxFlagN }}
                      name="recordType"
                      label={
                        <FormFieldLabel isRequired={isTaxFlagY || isTaxFlagN}>
                          RCT
                        </FormFieldLabel>
                      }
                      fullWidth
                      autoComplete="off"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormInput
                      control={control}
                      rules={{ required: isTaxFlagY || isTaxFlagN }}
                      name="trxReference"
                      label={
                        <FormFieldLabel isRequired={isTaxFlagY || isTaxFlagN}>
                          TRX Reference
                        </FormFieldLabel>
                      }
                      fullWidth
                      autoComplete="off"
                      disabled={transactionSource === "I"}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormInput
                      control={control}
                      name="typeOfTrans"
                      label={
                        <FormFieldLabel isRequired={isTaxFlagY || isTaxFlagN}>
                          Type of Transact.
                        </FormFieldLabel>
                      }
                      fullWidth
                      autoComplete="off"
                      rules={{
                        required: isTaxFlagY || isTaxFlagN,
                        validate: (value: string | boolean | number) =>
                          (value as string).length <= 1 ||
                          "Type of Transact. length must be 1.",
                      }}
                      disabled={transactionSource === "I"}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormInput
                      control={control}
                      rules={{ required: isTaxFlagY || isTaxFlagN }}
                      name="bc"
                      label={
                        <FormFieldLabel isRequired={isTaxFlagY || isTaxFlagN}>
                          Booking Center
                        </FormFieldLabel>
                      }
                      fullWidth
                      autoComplete="off"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormCheckbox
                      control={control}
                      name="nettedTaFlag"
                      required={isNettedFlagRequired}
                      label={
                        <FormFieldLabel isRequired={isNettedFlagRequired}>
                          Netted TA-Flag
                        </FormFieldLabel>
                      }
                      fullWidth
                      variant="outlined"
                      disabled={isNettedTaFlagDisabled}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormInput
                      control={control}
                      name="isin"
                      label={
                        <FormFieldLabel isRequired={isTaxFlagY}>
                          ISIN
                        </FormFieldLabel>
                      }
                      fullWidth
                      rules={{
                        required: isTaxFlagY,
                        validate: (value: string | boolean | number) =>
                          (value as string).length <= 12 ||
                          "ISIN max length must be 12",
                      }}
                      autoComplete="off"
                      disabled={transactionSource === "I"}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormInput
                      control={control}
                      rules={{
                        required: isTaxFlagY || isTaxFlagN,
                        validate: (value) =>
                          value === "" ||
                          isValidDate(String(value), "YYYYMMDD") ||
                          "Please insert a date with the format YYYYMMDD.",
                      }}
                      name="tradeDate"
                      label={
                        <FormFieldLabel isRequired={isTaxFlagY || isTaxFlagN}>
                          Trade Date
                        </FormFieldLabel>
                      }
                      fullWidth
                      autoComplete="off"
                      disabled={transactionSource === "I"}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormInput
                      control={control}
                      rules={{
                        required: isTaxFlagY || isTaxFlagN,
                        validate: (value) =>
                          value === "" ||
                          isValidDate(String(value), "YYYYMMDD") ||
                          "Please insert a date with the format YYYYMMDD.",
                      }}
                      name="setImpDate"
                      label={
                        <FormFieldLabel isRequired={isTaxFlagY || isTaxFlagN}>
                          Setlm. Date
                        </FormFieldLabel>
                      }
                      fullWidth
                      autoComplete="off"
                      disabled={transactionSource === "I"}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormCurrencyInput
                      control={control}
                      rules={{ required: isTaxFlagY || isTaxFlagN }}
                      name="quantity"
                      label={
                        <FormFieldLabel isRequired={isTaxFlagY || isTaxFlagN}>
                          Quantity
                        </FormFieldLabel>
                      }
                      fullWidth
                      allowNegative={true}
                      autoComplete="off"
                      decimalScale={2}
                      disabled={transactionSource === "I"}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormInput
                      control={control}
                      name="unit"
                      label={
                        <FormFieldLabel isRequired={isTaxFlagY || isTaxFlagN}>
                          Unit
                        </FormFieldLabel>
                      }
                      fullWidth
                      rules={{
                        required: isTaxFlagY || isTaxFlagN,
                        validate: (value: string | boolean | number) =>
                          (value as string).length <= 3 ||
                          "Unit max length must be 3",
                      }}
                      autoComplete="off"
                      disabled={transactionSource === "I"}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormInput
                      control={control}
                      name="placeOfTrade"
                      label={
                        <FormFieldLabel isRequired={isTaxFlagY || isTaxFlagN}>
                          Place Of Trade
                        </FormFieldLabel>
                      }
                      fullWidth
                      rules={{
                        required: isTaxFlagY || isTaxFlagN,
                        validate: (value: string | boolean | number) =>
                          (value as string).length <= 4 ||
                          "Place Of Trade max length must be 4",
                      }}
                      autoComplete="off"
                      disabled={transactionSource === "I"}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormCurrencyInput
                      control={control}
                      rules={{ required: isTaxFlagY || isTaxFlagN }}
                      name="amount"
                      label={
                        <FormFieldLabel isRequired={isTaxFlagY || isTaxFlagN}>
                          Amount
                        </FormFieldLabel>
                      }
                      fullWidth
                      allowNegative={true}
                      autoComplete="off"
                      decimalScale={2}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormInput
                      control={control}
                      name="exoCode"
                      label={
                        <FormFieldLabel isRequired={isTaxFlagN}>
                          Exo-Code
                        </FormFieldLabel>
                      }
                      fullWidth
                      autoComplete="off"
                      rules={{
                        required: isTaxFlagN,
                        validate: (value: any) => {
                          const valueText = value as string;
                          if (valueText.length > 1) {
                            return "The value should have length 1";
                          } else if (
                            !isTaxFlagN &&
                            (valueText === "3" || valueText === "6")
                          ) {
                            return "Please control the Data Entry, if Exoneration Code = 3 or 6 then the Taxable Flag should be 'N'";
                          }
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormSelect
                      control={control}
                      name="taxFlag"
                      label={<FormFieldLabel>Tax-Flag</FormFieldLabel>}
                      fullWidth
                      autoComplete="off"
                      options={taxableOptions}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormCurrencyInput
                      name="taxAmount"
                      control={control}
                      rules={{ required: isTaxFlagY }}
                      label={
                        <FormFieldLabel isRequired={isTaxFlagY}>
                          Tax Amount
                        </FormFieldLabel>
                      }
                      fullWidth
                      allowNegative={true}
                      autoComplete="off"
                      decimalScale={2}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormCurrencyInput
                      name="quantityTransferred"
                      control={control}
                      label={
                        <FormFieldLabel>Quantity Transferred</FormFieldLabel>
                      }
                      fullWidth
                      allowNegative={false}
                      autoComplete="off"
                      decimalScale={2}
                      disabled={
                        transactionSource === "I" &&
                        country !== "ES" &&
                        !nettedTaFlag
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormCurrencyInput
                      name="quantityTransferredNet"
                      control={control}
                      label={
                        <FormFieldLabel>Quantity Trans. Net</FormFieldLabel>
                      }
                      fullWidth
                      allowNegative={false}
                      autoComplete="off"
                      decimalScale={2}
                      disabled={
                        transactionSource === "I" &&
                        country !== "ES" &&
                        !nettedTaFlag
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormCurrencyInput
                      name="amountOfAcquisition"
                      control={control}
                      label={
                        <FormFieldLabel>Amount of Acquisition</FormFieldLabel>
                      }
                      fullWidth
                      allowNegative={false}
                      autoComplete="off"
                      decimalScale={2}
                      disabled={
                        transactionSource === "I" &&
                        country !== "ES" &&
                        !nettedTaFlag
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormCurrencyInput
                      name="taxBaseDeclared"
                      control={control}
                      label={<FormFieldLabel>TaxBase Declared</FormFieldLabel>}
                      fullWidth
                      allowNegative={false}
                      autoComplete="off"
                      decimalScale={2}
                      disabled={
                        transactionSource === "I" &&
                        country !== "ES" &&
                        !nettedTaFlag
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormInput
                      control={control}
                      rules={{ required: isTaxFlagY || isTaxFlagN }}
                      name="land"
                      label={
                        <FormFieldLabel isRequired={isTaxFlagY || isTaxFlagN}>
                          Country
                        </FormFieldLabel>
                      }
                      fullWidth
                      autoComplete="off"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormInput
                      control={control}
                      name="reportDate"
                      label={
                        <FormFieldLabel isRequired={isTaxFlagY || isTaxFlagN}>
                          Report Date
                        </FormFieldLabel>
                      }
                      fullWidth
                      autoComplete="off"
                      rules={{
                        required: isTaxFlagY || isTaxFlagN,
                        validate: (value) =>
                          value === "" ||
                          isValidDate(String(value), "YYYYMMDD") ||
                          "Please insert a date with the format YYYYMMDD.",
                      }}
                      disabled={transactionSource === "I"}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormInput
                      control={control}
                      rules={{ required: isTaxFlagY || isTaxFlagN }}
                      name="fileBc"
                      label={
                        <FormFieldLabel isRequired={isTaxFlagY || isTaxFlagN}>
                          File BC
                        </FormFieldLabel>
                      }
                      fullWidth
                      autoComplete="off"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormSelect
                      control={control}
                      name="safekeepCountry"
                      label={<FormFieldLabel>Safekeep. Country</FormFieldLabel>}
                      fullWidth
                      autoComplete="off"
                      options={safekeepCountryOptions}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormSelect
                      control={control}
                      name="exoMapping"
                      label={<FormFieldLabel>Exo Mapping</FormFieldLabel>}
                      fullWidth
                      autoComplete="off"
                      options={exomappingOptions}
                      disabled={transactionSource === "I"}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormInput
                      control={control}
                      name="loadDate"
                      rules={{
                        validate: (value) =>
                          value === "" ||
                          isValidDate(String(value), "YYYYMMDD") ||
                          "Please insert a date with the format YYYYMMDD.",
                      }}
                      label={<FormFieldLabel>Load Date</FormFieldLabel>}
                      fullWidth
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormInput
                      control={control}
                      name="intCom"
                      label={<FormFieldLabel>Internal Comment</FormFieldLabel>}
                      fullWidth
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormInput
                      control={control}
                      name="extCom"
                      label={<FormFieldLabel>External Comment</FormFieldLabel>}
                      fullWidth
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
              </form>
            )}

            {submitError && (
              <Alert severity="error" sx={{ mt: "10px" }}>
                {submitError}
              </Alert>
            )}

            <AlertMessage
              show={submitSuccessful}
              message={submitSuccessMessage}
            />
          </Box>
        )}
      />
    </>
  );
};
