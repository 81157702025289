import { useTheme } from "@mui/material";
import { ReactNode } from "react";

export interface FormFieldLabelProps {
  children: ReactNode;
  isRequired?: boolean;
}

export const FormFieldLabel = ({
  isRequired,
  children,
}: FormFieldLabelProps) => {
  const theme = useTheme();

  return (
    <span>
      {isRequired && (
        <span style={{ color: theme.palette.error.main }}>* </span>
      )}
      {children}
    </span>
  );
};
