import React, { useCallback } from "react";
import { Checkbox } from "@mui/material";
import { TableBody, TableRow, TableCell, Radio } from "@mui/material";
import { OnRowSelectedCb, ITableItem, ITableHeader } from "./types";
import { useTable } from "./useTable";
import { formatNumber } from "../../../utils/numbersUtils";

export type BodyProps = {
  readOnly?: boolean;
  onRowSelect?: OnRowSelectedCb;
};

export const Body = (props: BodyProps) => {
  const [selectedRow, setSelectedRow] = React.useState<string>("");
  const { onRowSelect, readOnly } = props;
  const { items, columns } = useTable();

  const handleClick = useCallback(
    (
      _event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
      entity: ITableItem
    ) => {
      const removeSelection = selectedRow === entity.id;

      if (removeSelection) {
        setSelectedRow("");
      } else {
        setSelectedRow(entity.id);
      }

      if (onRowSelect) {
        onRowSelect(entity, !removeSelection);
      }
    },
    [onRowSelect, selectedRow]
  );

  const renderCell = (entity: ITableItem, column: ITableHeader) => {
    const columnIds = column.id.split("|");

    if (columnIds.length === 1 && columnIds.includes("")) {
      return null;
    }

    const values = columnIds.map((columnId) => {
      const ids = columnId.split(".");
      const value: string =
        ids.length > 1 ? entity[ids[0]]?.[ids[1]] : entity[columnId];
      return value ?? "";
    });

    const maybeTranslate = (value: any) => {
      if (!column.translate) {
        return value;
      }

      const translations = column.translate.split(",");

      let _default = "";
      let found = false;

      for (const translation of translations) {
        const [from, to] = translation.split("|");

        if (from === value) {
          value = to;
          found = true;
          break;
        }

        if (from === "default") {
          _default = to;
        }
      }

      if (!found && _default !== "") {
        value = _default;
      }

      return value;
    };

    return (
      <TableCell
        key={"table-cell-" + column.id}
        align={column.numeric ? "right" : "left"}
      >
        {values?.map((value: any, index: number) => {
          value = maybeTranslate(value);
          return (
            <React.Fragment key={"fragment-" + value + "-" + index}>
              {column.numeric
                ? formatNumber(parseFloat(value), {
                    decimals: 2,
                    fillWithZeros: !!column.fillWithZeros,
                  })
                : !column.checkbox && value}
              {column.checkbox && <Checkbox checked={!!value} disabled />}
              {values.length > index + 1 && <br />}
            </React.Fragment>
          );
        })}
      </TableCell>
    );
  };

  const isSelected = (name: string) => selectedRow === name;

  return (
    <TableBody>
      {items?.map((entity, index) => {
        const isItemSelected = isSelected(entity.id);
        const labelId = `enhanced-table-checkbox-${index}`;

        return (
          <TableRow
            hover
            onClick={(event) => handleClick(event, entity)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={"table-row-" + entity.id}
            selected={isItemSelected}
            style={{ cursor: "pointer" }}
          >
            <TableCell padding="checkbox">
              {!readOnly && (
                <Radio
                  color="primary"
                  checked={isItemSelected}
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              )}
            </TableCell>
            {columns.map((column) => renderCell(entity, column))}
          </TableRow>
        );
      })}
    </TableBody>
  );
};
