/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExportParameterMappingDto } from '../models/ExportParameterMappingDto';
import type { PagedModelEntityModelExportParameterMapping } from '../models/PagedModelEntityModelExportParameterMapping';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExportParameterMappingControllerService {

    /**
     * Returns a paged list of ExportParameterMapping records
     * @returns PagedModelEntityModelExportParameterMapping Paged list of ExportParameterMapping records
     * @throws ApiError
     */
    public static getExportParameterMappings({
authorization,
page,
size = 10,
sort,
search,
exportOk,
client,
exportName,
id,
liableAccountableParty,
euVatReference,
creditDebitCode,
referenceName,
}: {
authorization: string,
/**
 * Zero-based page index (0..N)
 */
page?: number,
/**
 * The size of the page to be returned
 */
size?: number,
/**
 * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
 */
sort?: Array<string>,
search?: string,
exportOk?: boolean,
client?: string,
exportName?: string,
id?: number,
liableAccountableParty?: string,
euVatReference?: string,
creditDebitCode?: 'CREDIT' | 'DEBIT',
referenceName?: string,
}): CancelablePromise<PagedModelEntityModelExportParameterMapping> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/mapping/export-parameter',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'page': page,
                'size': size,
                'sort': sort,
                'search': search,
                'exportOk': exportOk,
                'client': client,
                'exportName': exportName,
                'id': id,
                'liableAccountableParty': liableAccountableParty,
                'euVatReference': euVatReference,
                'creditDebitCode': creditDebitCode,
                'referenceName': referenceName,
            },
            errors: {
                403: `Unauthorized user`,
            },
        });
    }

    /**
     * Adds an ExportParameterMapping record
     * @returns PagedModelEntityModelExportParameterMapping Record inserted successfully
     * @throws ApiError
     */
    public static addExportParameterMapping({
authorization,
requestBody,
}: {
authorization: string,
requestBody: ExportParameterMappingDto,
}): CancelablePromise<PagedModelEntityModelExportParameterMapping> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/mapping/export-parameter',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Unauthorized user`,
            },
        });
    }

    /**
     * Delete an existing ExportParameterMapping record
     * @returns void 
     * @throws ApiError
     */
    public static deleteExportParameterMapping({
authorization,
id,
}: {
authorization: string,
id: number,
}): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/mapping/export-parameter/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                403: `Unauthorized user`,
                404: `Record not found`,
            },
        });
    }

    /**
     * Updates an existing ExportParameterMapping record
     * @returns void 
     * @throws ApiError
     */
    public static updateExportParameterMapping({
authorization,
id,
requestBody,
}: {
authorization: string,
id: number,
requestBody: ExportParameterMappingDto,
}): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/mapping/export-parameter/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Unauthorized user`,
                404: `Record not found`,
            },
        });
    }

}
