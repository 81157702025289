import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AuthenticatedLayout } from "../../features/user/layouts/AuthendicatedLayout";
import { Breadcrumbs } from "../../components/core/Breadcrumbs";
import { UpsertImportFileMappingForm } from "../../features/parameters/import-file-mapping/UpsertImportFileMappingForm";

export const EditImportFileMapping = (): React.ReactElement => {
  const { id } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!id) {
      navigate("/");
    }
  }, [id, navigate]);

  return (
    <AuthenticatedLayout>
      <Breadcrumbs />
      <UpsertImportFileMappingForm id={id} />
    </AuthenticatedLayout>
  );
};

export default EditImportFileMapping;
