import { NonStandardTaxDto, StandardTaxDto } from "../../../../openapi";
import {
  AggregatedStandardTaxDto,
  PreparedNonStandardTaxDto,
} from "./services";

export const aggregateStandardData = (
  list?: Array<StandardTaxDto>
): Array<AggregatedStandardTaxDto> | undefined => {
  if (!list) {
    return undefined;
  }

  const result: Array<AggregatedStandardTaxDto> = [];

  for (let id = 0; id < list.length; id++) {
    const entity = list[id];
    const index = result.findIndex((r) => r.month === entity.month);

    let object: AggregatedStandardTaxDto = {
      id,
      month: entity.month,
      exclusionsCode: entity.exclusionsCode,
      exclusionsDescription: entity.exclusionsDescription,
      p1: "Share",
      p2: "Derivate",
    };

    if (entity.standardTypeTax === "Share") {
      object = {
        ...object,
        ...{
          p1CashAmountCnt: entity.transactionsNumber,
          p1CashAmount: entity.taxableAmount,
          p1TaxAmount: entity.amountOwed,
        },
      };
    } else {
      object = {
        ...object,
        ...{
          p2CashAmountCnt: entity.transactionsNumber,
          p2CashAmount: entity.taxableAmount,
          p2TaxAmount: entity.amountOwed,
        },
      };
    }

    if (index === -1) {
      result.push(object);
    } else {
      result[index] = {
        ...result[index],
        ...object,
      };
    }
  }

  return result;
};

export const prepareNonStandardData = (
  list?: Array<NonStandardTaxDto>
): Array<PreparedNonStandardTaxDto> | undefined => {
  if (!list) {
    return undefined;
  }

  const result: Array<PreparedNonStandardTaxDto> = [];

  for (let id = 0; id < list.length; id++) {
    const entity = list[id];

    result.push({
      ...entity,
      ...{
        id,
      },
    });
  }

  return result;
};

export const parseFiscalYear = (date: string | null | undefined) => {
  if (!date || date.length < 4) return "";

  return date.substring(0, 4);
};
