import { useState, useEffect, useCallback, useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  EntityModelSafeKeepingMapping,
  SafeKeepingMappingControllerService,
  SafeKeepingMappingDto,
} from "../../../../openapi";
import authorizedCall from "../../../../utils/authorizedCall";
import { useNavigate } from "react-router-dom";

export interface useUpsertSafekeepingMappingFormParams {
  id?: string;
}

export interface UpsertSafekeepingMappingFormInputs {
  country: string;
  countrySourceCode: string;
  countryTargetCode: string;
  safekeepingSwiftAddress: string;
}

export const useUpsertSafekeepingMappingForm = (
  params: useUpsertSafekeepingMappingFormParams
) => {
  const navigate = useNavigate();
  const [safekeepingMapping, setSafekeepingMapping] =
    useState<EntityModelSafeKeepingMapping>();
  const [safekeepingMappingError, setSafekeepingMappingError] = useState("");
  const [safekeepingMappingIsFetching, setSafekeepingMappingIsFetching] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccessfull, setIsSubmitSuccessfull] = useState(false);
  const [submitError, setSubmittingError] = useState("");

  const { id } = params;

  const defaultValues = useMemo(
    () => ({
      country: "",
      countrySourceCode: "",
      countryTargetCode: "",
      safekeepingSwiftAddress: "",
    }),
    []
  );

  const form = useForm<UpsertSafekeepingMappingFormInputs>({
    defaultValues,
  });

  const { reset, handleSubmit, control } = form;

  const handleBack = useCallback(() => {
    navigate("/table/safekeeping-mapping");
  }, [navigate]);

  useEffect(() => {
    if (id) {
      setSafekeepingMappingIsFetching(true);
      authorizedCall(
        SafeKeepingMappingControllerService.getSafeKeepingMapping,
        {
          id: Number(id),
        }
      )
        .then((data) => {
          const _safekeepingMapping = (
            data as EntityModelSafeKeepingMapping[]
          )[0];
          setSafekeepingMappingIsFetching(false);
          setSafekeepingMapping(_safekeepingMapping ?? {});

          reset({
            country: _safekeepingMapping.country ?? "",
            countrySourceCode: String(
              _safekeepingMapping.sourceCountryMicList?.id ?? ""
            ),
            countryTargetCode: String(
              _safekeepingMapping.sourceCountryMicList?.id ?? ""
            ),
            safekeepingSwiftAddress: _safekeepingMapping.swiftAddress ?? "",
          });
        })
        .catch((error) => {
          setSafekeepingMappingIsFetching(false);
          setSafekeepingMappingError(error.message);
        });
    }
  }, [id, reset]);

  useEffect(() => {
    if (submitSuccessfull && !id) {
      reset(defaultValues);
    }
  }, [reset, submitSuccessfull, defaultValues, id]);

  const submitSuccessMessage = safekeepingMapping
    ? "Data has been updated correctly"
    : "Record inserted";

  const cardTitle = safekeepingMapping
    ? "Edit Safekeeping Mapping"
    : "Add Safekeeping Mapping";

  const onSubmit: SubmitHandler<UpsertSafekeepingMappingFormInputs> =
    useCallback(
      (data) => {
        const {
          country,
          countrySourceCode,
          countryTargetCode,
          safekeepingSwiftAddress,
        } = data;

        setIsSubmitSuccessfull(false);
        setIsSubmitting(true);
        setSubmittingError("");

        const bodyReq: any = {
          country: country ? country : null,
          sourceCountryMicListId: countrySourceCode
            ? Number(countrySourceCode)
            : null,
          targetCountryMicListId: countryTargetCode
            ? Number(countryTargetCode)
            : null,
          swiftAddress: safekeepingSwiftAddress
            ? safekeepingSwiftAddress
            : null,
        };

        if (id) {
          authorizedCall(
            SafeKeepingMappingControllerService.updateSafeKeepingMapping,
            {
              id: Number(id),
              requestBody: bodyReq as SafeKeepingMappingDto,
            }
          )
            .then(() => {
              setIsSubmitting(false);
              setIsSubmitSuccessfull(true);
            })
            .catch((err) => {
              setIsSubmitting(false);
              setSubmittingError(err.body.message);
            });
        } else {
          authorizedCall(
            SafeKeepingMappingControllerService.addSafeKeepingMapping,
            {
              requestBody: bodyReq as SafeKeepingMappingDto,
            }
          )
            .then(() => {
              setIsSubmitting(false);
              setIsSubmitSuccessfull(true);
            })
            .catch((err) => {
              setIsSubmitting(false);
              setSubmittingError(err.body.message);
            });
        }
      },

      [id]
    );

  return {
    isSubmitting,
    submitSuccessfull,
    submitError,
    control,
    cardTitle,
    submitSuccessMessage,
    safekeepingMapping,
    safekeepingMappingError,
    safekeepingMappingIsFetching,
    setSafekeepingMappingError,
    onSubmit: handleSubmit(onSubmit),
    onBack: handleBack,
  };
};
