import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import type { TextFieldProps } from "@mui/material";
import type { UseControllerProps } from "react-hook-form";
import type { FieldValues } from "react-hook-form/dist/types/fields";

export type FormInputProps<T extends FieldValues = FieldValues> = Omit<
  UseControllerProps<T>,
  "defaultValue"
> &
  Omit<TextFieldProps, "onChange" | "value" | "error"> & {
    textarea?: boolean;
    hideArrows?: boolean;
    handleChange?: (
      value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
  };

const hiddenArrowsStyle = {
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
};

export const FormInput = <T extends FieldValues = FieldValues>(
  props: FormInputProps<T>
) => {
  const {
    name,
    control,
    defaultValue,
    rules,
    shouldUnregister,
    textarea,
    inputProps,
    handleChange,
    hideArrows = false,
    ...restProps
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        let errorText = error?.message;
        if (error?.type === "required" && error?.message === "") {
          errorText = "This field is required";
        }

        let helperText = null;
        if (textarea && inputProps?.maxLength) {
          helperText = `${value.length}/${inputProps?.maxLength} characters inserted`;
        }

        return (
          <TextField
            {...restProps}
            id={name}
            name={name}
            value={value}
            onChange={(value) => {
              onChange(value);
              handleChange?.(value);
            }}
            sx={{
              ...(restProps.sx ?? {}),
              ...(hideArrows ? hiddenArrowsStyle : {}),
            }}
            onBlur={onBlur}
            error={Boolean(error)}
            helperText={errorText ?? helperText}
          />
        );
      }}
    />
  );
};
