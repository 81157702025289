import React from "react";
import { Box, Alert, Grid } from "@mui/material";
import { Card } from "../../../../components/core/Card";
import { Spinner } from "../../../../components/core/Spinner";
import { FormInput } from "../../../../components/hook-form/FormInput";
import { FormFieldLabel } from "../../../../components/hook-form/FormFieldLabel";
import { AlertMessage } from "../../../../components/core/AlertMessage";
import useUpsertImportFileMapping from "./useUpsertImportFileMappingForm";
import EditActionButtons from "../../../../components/core/EditActionButtons";

export interface UpsertImportFileMappingFormProps {
  id?: string;
}

export const UpsertImportFileMappingForm = (
  props: UpsertImportFileMappingFormProps
): React.ReactElement => {
  const { id } = props;

  const {
    importFileMapping,
    importFileMappingError,
    importFileMappingIsFetching,
    submitSuccessMessage,
    cardTitle,
    onSubmit,
    onBack,
    isSubmitting,
    submitSuccessfull,
    submitError,
    control,
  } = useUpsertImportFileMapping({ id });

  return (
    <Card
      title={cardTitle}
      style={{ width: "1512px" }}
      actions={
        (importFileMapping || !id) && (
          <EditActionButtons
            form="upsert-import-file-mapping-form"
            loading={isSubmitting}
            onBack={onBack}
          />
        )
      }
      Component={() => (
        <Box sx={{ margin: "20px" }}>
          {importFileMappingError && (
            <Alert severity={"error"}>{importFileMappingError}</Alert>
          )}
          {importFileMappingIsFetching && !importFileMapping && (
            <Spinner height={"353px"} />
          )}
          {(importFileMapping || !id) && (
            <form
              id={"upsert-import-file-mapping-form"}
              data-testid={"upsert-import-file-mapping-form"}
              onSubmit={onSubmit}
            >
              <Grid container columnSpacing={2} rowSpacing={5}>
                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    name="importName"
                    label={<FormFieldLabel>Import Name</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    name="fileName"
                    label={<FormFieldLabel>File Name</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    name="country"
                    label={<FormFieldLabel>Country</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    name="bookingCenter"
                    label={<FormFieldLabel>Booking Center</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    rules={{ required: "Please insert a Booking Center." }}
                  />
                </Grid>
              </Grid>
            </form>
          )}

          {submitError && (
            <Alert severity="error" sx={{ mt: "10px" }}>
              {submitError}
            </Alert>
          )}

          <AlertMessage
            show={submitSuccessfull}
            message={submitSuccessMessage}
          />
        </Box>
      )}
    />
  );
};

export default UpsertImportFileMappingForm;
