import { AuthenticatedLayout } from "../../features/user/layouts/AuthendicatedLayout";
import { TaxFormTable } from "../../features/tax-form/components/TaxFormTable";
import { Breadcrumbs } from "../../components/core/Breadcrumbs";

export const TaxForm = () => (
    <AuthenticatedLayout>
        <Breadcrumbs />
        <TaxFormTable />
    </AuthenticatedLayout>
);
