import { ButtonProps, Button, Box } from "@mui/material";

export const UploadButton = (props: ButtonProps) => {
  return (
    <Box style={{ width: "100%" }}>
      <label htmlFor="upload-button">
        <input
          style={{ display: "none" }}
          id="upload-button"
          name="upload-button"
          type="file"
        />
        <Button
          color="secondary"
          variant="outlined"
          component="span"
          sx={{
            borderRadius: 0,
            textTransform: "none",
            fontWeight: "bold",
            width: "300px",
            ...props.sx,
          }}
        >
          {props.children}
        </Button>
      </label>
    </Box>
  );
};
