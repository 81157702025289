import { Box, Grid } from "@mui/material";

import { Card } from "../../../../components/core/Card";
import { DeleteModal } from "../../../../components/core/EntityTable/DeleteModal";
import { EntityTableActions } from "../../../../components/core/EntityTable/EntityTableActions";
import { StyledSection } from "../../../../components/core/EntityTable/index.style";
import { useEntityTable } from "../../../../components/core/EntityTable/useEntityTable";
import { Select } from "../../../../components/core/Select";
import { Table } from "../../../../components/core/Table";
import { TransactionControllerService } from "../../../../openapi";
import columns from "./config.json";
import { useAdjustmentTable } from "./useAdjustmentTable";

export const AdjustmentTable = () => {
  const { getTransactions, deleteTransaction } = TransactionControllerService;

  const {
    appliedFilters,
    countryTypeId,
    handleCountryTypeChange,
    formatsFieldOptions,
  } = useAdjustmentTable();

  const {
    onSearchTermChange,
    enabledActions,
    canEditItem,
    onEditItem,
    items,
    sortOrder,
    onRowSelect,
    setPage,
    onPageSizeChange,
    totalItems,
    onSortOrderChange,
    orderByField,
    onAddItem,
    onDeleteItem,
    onBackItem,
    handleCloseModal,
    handleOpenModal,
    openModal,
    canDeleteItem,
    canAddItem,
    error,
    isDeleting,
    page,
    setRowsPerPage,
    rowsPerPage,
  } = useEntityTable({
    columns,
    getService: getTransactions,
    editRoute: "/table/adjustment/edit",
    addRoute: `/table/adjustment/add`,
    addRoutePathParams: countryTypeId,
    deleteRoute: "/table/adjustment/delete",
    deleteService: deleteTransaction,
    filters: appliedFilters,
    fetchDataOnMount: false,
    sortConfig: { order: "desc", field: "id" },
  });

  return (
    <>
      <DeleteModal
        isDeleting={isDeleting}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        onDelete={onDeleteItem}
        error={error}
      />
      <Card
        title="TTDF Adjustment"
        Component={StyledSection}
        style={{ width: "100%" }}
        actions={
          <EntityTableActions
            onEditItem={onEditItem}
            onDeleteItem={onDeleteItem}
            onAddItem={onAddItem}
            onBackItem={onBackItem}
            handleOpenModal={handleOpenModal}
            onSearchTermChange={onSearchTermChange}
            enabledActions={enabledActions}
            canEditItem={canEditItem && !!countryTypeId}
            canDeleteItem={canDeleteItem}
            canAddItem={canAddItem && !!countryTypeId}
            additionalActions={
              formatsFieldOptions.length > 0 && (
                <Select
                  value={countryTypeId}
                  name="countryType"
                  onChange={handleCountryTypeChange}
                  label="Country / Type"
                  options={formatsFieldOptions}
                  fullWidth
                  size="small"
                  autoComplete="off"
                  sx={{ mb: "-4px", width: "220px", marginRight: "15px" }}
                />
              )
            }
          />
        }
      >
        <Grid item xs={12}>
          <Box sx={{ width: "100%" }}>
            <Grid container spacing={3}>
              <Table
                items={items}
                columns={columns}
                page={page}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
              >
                <Table.Wrapper>
                  <Table.Head
                    sortConfig={{
                      order: sortOrder,
                      field: orderByField,
                      onOrderChange: onSortOrderChange,
                    }}
                  />
                  <Table.Body
                    readOnly={!canEditItem && !canDeleteItem}
                    onRowSelect={onRowSelect}
                  />
                </Table.Wrapper>
                <Table.Pagination
                  onPageChange={setPage}
                  onPageSizeChange={onPageSizeChange}
                  totalItems={totalItems}
                />
              </Table>
            </Grid>
          </Box>
        </Grid>
      </Card>
    </>
  );
};
