import { Breadcrumbs } from "../../components/core/Breadcrumbs";
import { ReconciliationMappingTable } from "../../features/parameters/reconciliation-mapping/ReconciliationMappingTable";
import { AuthenticatedLayout } from "../../features/user/layouts/AuthendicatedLayout";

export const ReconciliationMapping = () => {
  return (
    <AuthenticatedLayout>
      <Breadcrumbs />
      <ReconciliationMappingTable />
    </AuthenticatedLayout>
  );
};
