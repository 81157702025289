import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Typography } from "@mui/material";
import { StyledDivider } from "../Card/index.style";

export type IModalProps = {
  title: React.ReactNode;
  open: boolean;
  children: React.ReactNode;
  actions?: React.ReactNode;
  handleClose: () => void;
  minWidth?: number;
};

export const Modal = (props: IModalProps) => {
  const { title, children, open, handleClose, actions, minWidth } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      PaperProps={{ sx: { minWidth: minWidth ? minWidth : "650px" } }}
      data-testid="modal"
    >
      <DialogTitle>
        <Typography component="span" variant="h5" color="primary">
          {title}
        </Typography>

        <StyledDivider />
      </DialogTitle>

      <DialogContent>{children}</DialogContent>

      {actions && (
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <StyledDivider />
          <Box sx={{ margin: "revert", height: "1px", width: "100%" }} />
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};
