import { useState, useEffect } from "react";
import { Select, SelectOption } from "../../../../components/core/Select";
import { FormFieldLabel } from "../../../../components/hook-form/FormFieldLabel";
import { Box } from "@mui/material";
import {
  ButtonWrapper,
  StyledButton,
} from "../../../../components/core/EntityTable/index.style";
import { getBookingCenters, getFiscalYears } from "./services";
import BackButton from "../../../../components/core/BackButton";

interface TaxFormTableActionsProps {
  onSearchTermChange: (searched: string) => void;
  onRecalculateClick: (bookingCenter: string, fiscalYear: string) => void;
  handleBack: () => void;
}

type TaxFormTableActionsState = {
  bookingCenter: string;
  fiscalYear: string;
};

const INITIAL_STATE: TaxFormTableActionsState = {
  bookingCenter: "",
  fiscalYear: "",
};

export const TaxFormTableActions = ({
  onSearchTermChange,
  onRecalculateClick,
  handleBack,
}: TaxFormTableActionsProps) => {
  const [bookingCenters, setBookingCenters] = useState<SelectOption[]>([]);
  const [fiscalYears, setFiscalYears] = useState<SelectOption[]>([]);
  const [state, setState] = useState<TaxFormTableActionsState>(INITIAL_STATE);

  useEffect(() => {
    getBookingCenters().then((bcs) => setBookingCenters(bcs));
  }, []);

  const onBookingCenterSelected = (evt: any) => {
    const id: string = evt.target.value;
    getFiscalYears().then((fys) => setFiscalYears(fys));
    setState({ ...state, bookingCenter: id });
  };

  const onFiscalYearSelected = (evt: any) => {
    const id: string = evt.target.value;
    setState({ ...state, fiscalYear: id });
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Select
        value={state.bookingCenter}
        options={bookingCenters}
        name={"bookingCenter"}
        size={"small"}
        sx={{ mb: "-4px", width: "200px", marginRight: "15px" }}
        label={<FormFieldLabel>Booking Center</FormFieldLabel>}
        onChange={onBookingCenterSelected}
      />
      <Select
        value={state.fiscalYear}
        options={fiscalYears}
        name={"fiscalYear"}
        size={"small"}
        sx={{ mb: "-4px", width: "200px", marginRight: "15px" }}
        label={<FormFieldLabel>Fiscal year</FormFieldLabel>}
        onChange={onFiscalYearSelected}
      />
      <ButtonWrapper>
        <StyledButton
          variant="contained"
          disabled={!state.fiscalYear}
          onClick={() =>
            onRecalculateClick(state.bookingCenter, state.fiscalYear)
          }
        >
          Recalculate
        </StyledButton>
        <BackButton onClick={handleBack} />
      </ButtonWrapper>
    </Box>
  );
};
