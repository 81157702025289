import ModalConfig from "../types/ModalConfig";
import { FileImportModal } from "../features/import/components/FileImportModal";
import { ArchiveDataESModal } from "../features/archiving/components/ArchiveDataESModal";
import { ArchiveDataFRModal } from "../features/archiving/components/ArchiveDataFRModal";
import { ArchiveDataITModal } from "../features/archiving/components/ArchiveDataITModal";
import { FileExportModal } from "../features/import/components/FileExportModal";

const modals: ModalConfig[] = [
  {
    name: "Import",
    code: "Import",
    modal: FileImportModal,
  },
  {
    name: "Export",
    code: "Export",
    modal: FileExportModal,
  },
  {
    name: "Archive ES",
    code: "Archive ES",
    modal: ArchiveDataESModal,
  },
  {
    name: "Archive FR",
    code: "Archive FR",
    modal: ArchiveDataFRModal,
  },
  {
    name: "Archive IT",
    code: "Archive IT",
    modal: ArchiveDataITModal,
  },
];

export default modals;
