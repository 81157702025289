import { useState, useEffect, useCallback, useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  EntityModelExportParameterMapping,
  ExportParameterMappingControllerService,
} from "../../../../openapi";
import authorizedCall from "../../../../utils/authorizedCall";
import { useNavigate } from "react-router-dom";

export interface useUpsertExportParameterFormArg {
  id?: string;
}

export interface UpsertExportParameterFormInputs {
  clientAccount: string;
  accountCurrency: string;
  partecipantCode: string;
  nameLiableAccountableParty: string;
  addressLiableAccountableParty: string;
  clientBICode: string;
  referenceOfDeclaration: string;
  codeOfLiable: string;
  countryOfLiable: string;
  exportName: string;
  placeOfBooking: string;
  cdCode: string;
  euVat: string;
  taxablePersonTaxNumber: string;
  userCreate: string;
  dateCreate: string;
  userUpdate: string;
  dateUpdate: string;
}

export const useUpsertExportParameterForm = (
  params: useUpsertExportParameterFormArg
) => {
  const navigate = useNavigate();
  const [exportParameterMapping, setExportParameterMapping] =
    useState<EntityModelExportParameterMapping>();
  const [exportParameterMappingError, setExportParameterMappingError] =
    useState("");
  const [
    exportParameterMappingIsFetching,
    setExportParameterMappingIsFetching,
  ] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccessfull, setIsSubmitSuccessfull] = useState(false);
  const [submitError, setSubmittingError] = useState("");

  const { id } = params;

  const defaultValues = useMemo(
    () => ({
      clientAccount: "",
      accountCurrency: "",
      partecipantCode: "",
      nameLiableAccountableParty: "",
      addressLiableAccountableParty: "",
      clientBICode: "",
      referenceOfDeclaration: "",
      codeOfLiable: "",
      countryOfLiable: "",
      exportName: "",
      placeOfBooking: "",
      cdCode: "",
      euVat: "",
      taxablePersonTaxNumber: "",
      userCreate: "",
      dateCreate: "",
      userUpdate: "",
      dateUpdate: "",
    }),
    []
  );

  const form = useForm<UpsertExportParameterFormInputs>({
    defaultValues,
  });

  const { reset, handleSubmit, control } = form;

  const handleBack = useCallback(() => {
    navigate("/table/export-parameter");
  }, [navigate]);

  useEffect(() => {
    if (id) {
      setExportParameterMappingIsFetching(true);
      authorizedCall(
        ExportParameterMappingControllerService.getExportParameterMappings,
        {
          id: Number(id),
        }
      )
        .then((data) => {
          const _exportParameterMapping = (
            data as EntityModelExportParameterMapping[]
          )[0];
          setExportParameterMappingIsFetching(false);
          setExportParameterMapping(_exportParameterMapping ?? {});

          reset({
            clientAccount: _exportParameterMapping?.client?.cashAccount ?? "",
            accountCurrency:
              _exportParameterMapping?.client?.cashAccountCurrency ?? "",
            nameLiableAccountableParty:
              _exportParameterMapping.liableAccountableParty?.name ?? "",
            addressLiableAccountableParty:
              _exportParameterMapping?.liableAccountableParty?.address ?? "",
            clientBICode: _exportParameterMapping?.client?.bicCode ?? "",
            referenceOfDeclaration:
              _exportParameterMapping.client?.declarationReference ?? "",
            codeOfLiable:
              _exportParameterMapping.liableAccountableParty?.code ?? "",
            countryOfLiable:
              _exportParameterMapping.liableAccountableParty?.country ?? "",
            exportName: _exportParameterMapping.exportName ?? "",
            placeOfBooking: _exportParameterMapping.client?.bookingPlace ?? "",
            cdCode: _exportParameterMapping.creditDebitCode ?? "",
            euVat: _exportParameterMapping.euVatReference ?? "",
            taxablePersonTaxNumber:
              _exportParameterMapping.client?.taxablePersonTaxNumber ?? "",
            userCreate: _exportParameterMapping.createUser ?? "",
            dateCreate: _exportParameterMapping.createTs ?? "",
            userUpdate: _exportParameterMapping.updateUser ?? "",
            dateUpdate: _exportParameterMapping.updateTs ?? "",
          });
        })
        .catch((error) => {
          setExportParameterMappingIsFetching(false);
          setExportParameterMappingError(error.message);
        });
    }
  }, [id, reset]);

  useEffect(() => {
    if (submitSuccessfull && !id) {
      reset(defaultValues);
    }
  }, [reset, submitSuccessfull, defaultValues, id]);

  const submitSuccessMessage = exportParameterMapping
    ? "Data has been updated correctly"
    : "Record inserted";

  const cardTitle = exportParameterMapping
    ? "Edit Export Parameter"
    : "Add Export Parameter";

  const onSubmit: SubmitHandler<UpsertExportParameterFormInputs> = useCallback(
    (data) => {
      const {
        nameLiableAccountableParty,
        addressLiableAccountableParty,
        partecipantCode,
        accountCurrency,
        countryOfLiable,
        clientAccount,
        codeOfLiable,
        dateCreate,
        userCreate,
        dateUpdate,
        userUpdate,
        clientBICode,
        placeOfBooking,
        taxablePersonTaxNumber,
        referenceOfDeclaration,
        cdCode,
        euVat,
        exportName,
      } = data;

      setIsSubmitSuccessfull(false);
      setIsSubmitting(true);
      setSubmittingError("");

      const bodyReq: any = {
        createTs: dateCreate ? dateCreate : null,
        createUser: userCreate ? userCreate : null,
        updateTs: dateUpdate ? dateUpdate : null,
        updateUser: userUpdate ? userUpdate : null,
        liableAccountableParty: {
          name: nameLiableAccountableParty ? nameLiableAccountableParty : null,
          address: addressLiableAccountableParty
            ? addressLiableAccountableParty
            : null,
          code: codeOfLiable ? codeOfLiable : null,
          bookingCenter: exportParameterMapping?.liableAccountableParty
            ?.bookingCenter
            ? exportParameterMapping?.liableAccountableParty?.bookingCenter
            : null,
          country: countryOfLiable ? countryOfLiable : null,
        },
        client: {
          country: exportParameterMapping?.client?.country
            ? exportParameterMapping?.client?.country
            : null,
          cashAccount: clientAccount ? clientAccount : null,
          cashAccountCurrency: accountCurrency ? accountCurrency : null,
          participantCode: partecipantCode ? partecipantCode : null,
          bicCode: clientBICode ? clientBICode : null,
          bookingPlace: placeOfBooking ? placeOfBooking : null,
          taxablePersonTaxNumber: taxablePersonTaxNumber
            ? taxablePersonTaxNumber
            : null,
          declarationReference: referenceOfDeclaration
            ? referenceOfDeclaration
            : null,
          safeKeepingPlace: exportParameterMapping?.client?.safeKeepingPlace
            ? exportParameterMapping?.client?.safeKeepingPlace
            : null,
        },
        creditDebitCode: cdCode ? cdCode : null,
        euVatReference: euVat ? euVat : null,
        exportName: exportName ? exportName : null,
      };

      if (id) {
        if (exportParameterMapping?.liableAccountableParty?.id) {
          bodyReq.liableAccountableParty.id =
            exportParameterMapping?.liableAccountableParty?.id;
        }

        if (exportParameterMapping?.client?.id) {
          bodyReq.client.id = exportParameterMapping.client.id;
        }

        authorizedCall(
          ExportParameterMappingControllerService.updateExportParameterMapping,
          {
            id: Number(id),
            requestBody: bodyReq,
          }
        )
          .then(() => {
            setIsSubmitting(false);
            setIsSubmitSuccessfull(true);
          })
          .catch((err) => {
            setIsSubmitting(false);
            setSubmittingError(err.body.message);
          });
      } else {
        authorizedCall(
          ExportParameterMappingControllerService.addExportParameterMapping,
          {
            requestBody: bodyReq,
          }
        )
          .then(() => {
            setIsSubmitting(false);
            setIsSubmitSuccessfull(true);
          })
          .catch((err) => {
            setIsSubmitting(false);
            setSubmittingError(err.body.message);
          });
      }
    },

    [
      exportParameterMapping?.client?.country,
      exportParameterMapping?.client?.id,
      exportParameterMapping?.client?.safeKeepingPlace,
      exportParameterMapping?.liableAccountableParty?.bookingCenter,
      exportParameterMapping?.liableAccountableParty?.id,
      id,
    ]
  );

  return {
    isSubmitting,
    submitSuccessfull,
    submitError,
    control,
    cardTitle,
    submitSuccessMessage,
    exportParameterMapping,
    exportParameterMappingError,
    exportParameterMappingIsFetching,
    setExportParameterMapping,
    onSubmit: handleSubmit(onSubmit),
    onBack: handleBack,
  };
};
