import { Breadcrumbs } from "../../components/core/Breadcrumbs";
import { ExportParameterTable } from "../../features/parameters/export-parameter/ExportParameterTable";

import { AuthenticatedLayout } from "../../features/user/layouts/AuthendicatedLayout";

export const ExportParameter = () => {
  return (
    <AuthenticatedLayout>
      <Breadcrumbs />
      <ExportParameterTable />
    </AuthenticatedLayout>
  );
};
