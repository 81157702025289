export const columns = [
  { label: "", id: "", sortDisabled: true },
  {
    label: "ID",
    id: "id",
    numeric: false,
  },
  {
    label: "Export Country",
    id: "country",
    numeric: false,
  },
  {
    label: "Export Name",
    id: "name",
    numeric: false,
  },
  {
    label: "Export File Timestamp",
    id: "fileTimestamp",
    numeric: false,
  },
  {
    label: "Export User",
    id: "user",
    numeric: false,
  },
  {
    label: "Export User Timestamp",
    id: "userTimestamp",
    numeric: false,
  },
  {
    label: "Export Record",
    id: "recordType.name",
    numeric: false,
  },
  {
    label: "Export Run (s)",
    id: "runInSeconds",
    numeric: true,
    sortDisabled: true,
  },
];
