import { Alert, Grid, Box } from "@mui/material";
import { Modal, IModalProps } from "../../../../components/core/Modal";
import { FormSelect } from "../../../../components/hook-form/FormSelect";
import { FormFieldLabel } from "../../../../components/hook-form/FormFieldLabel";
import { useArchiveDataModal } from "../useArchiveDataModal";
import { ConfirmModal } from "../ConfirmModal";
import Button from "@mui/material/Button";
import { Spinner } from "../../../../components/core/Spinner";
import BackButton from "../../../../components/core/BackButton";
import { ButtonWrapper } from "../../../../components/core/EntityTable/index.style";
import { FormFieldInfo } from "../../../../components/core/FormFieldInfo/FormFieldInfo";

export interface ArchiveDataITModalProps {
  open: IModalProps["open"];
  onClose: IModalProps["handleClose"];
}

export const ArchiveDataITModal = (props: ArchiveDataITModalProps) => {
  const { open, onClose } = props;

  const {
    onSubmit,
    years,
    isOpen,
    control,
    executeRes,
    yearsSelectOptions,
    bookingCentersSelectOptions,
    yearsError,
    bookingCentersError,
    handleCloseArchiveModal,
    isExecuting,
    handleChange,
    handleCloseConfirmModal,
    handleOpenConfirmModal,
    executeError,
    bookingCenterIsFetching,
    handleBookingCenterChange,
    shouldRenderExecuteBtn,
  } = useArchiveDataModal({
    country: "IT",
    onClose: onClose,
  });

  return (
    <Modal
      title="TTDF Archive Data IT"
      open={open}
      handleClose={onClose}
      actions={
        <ButtonWrapper>
          {shouldRenderExecuteBtn && (
            <Button variant="contained" onClick={handleOpenConfirmModal}>
              Execute
            </Button>
          )}
          <BackButton
            onClick={handleCloseArchiveModal}
            disabled={isExecuting}
          />
        </ButtonWrapper>
      }
    >
      <ConfirmModal
        openModal={isOpen}
        handleCloseModal={handleCloseConfirmModal}
        isExecuting={isExecuting}
        error={executeError}
        executeRes={executeRes}
        form="archive-data-it"
      />
      {yearsError && (
        <Alert severity="error" sx={{ width: "100%" }}>
          {yearsError}
        </Alert>
      )}
      {bookingCentersError && (
        <Alert severity="error" sx={{ width: "100%" }}>
          {bookingCentersError}
        </Alert>
      )}
      {bookingCenterIsFetching && <Spinner height="125px" width="600px" />}
      {!bookingCenterIsFetching && !bookingCentersError && (
        <form
          id="archive-data-it"
          data-testid="archive-data-it"
          onSubmit={onSubmit}
          style={{ marginTop: "20px" }}
        >
          {bookingCentersSelectOptions.length > 0 ? (
            <Grid container justifyContent="center">
              <Grid item container xs={6} flexDirection="column" rowSpacing={2}>
                <Grid item>
                  <FormSelect
                    control={control}
                    name="chooseBookingCenter"
                    handleChange={handleBookingCenterChange}
                    label={
                      <FormFieldLabel isRequired>
                        Choose Booking Center
                      </FormFieldLabel>
                    }
                    options={bookingCentersSelectOptions}
                    fullWidth
                    size="small"
                    autoComplete="off"
                    rules={{ required: "Please select a booking center." }}
                  />
                </Grid>
                <Grid item>
                  <FormSelect
                    control={control}
                    handleChange={handleChange}
                    name="chooseYear"
                    label={
                      <FormFieldLabel isRequired>Choose Year</FormFieldLabel>
                    }
                    options={yearsSelectOptions}
                    fullWidth
                    size="small"
                    autoComplete="off"
                    rules={{ required: "Please select a year." }}
                  />
                </Grid>
                {years && years.length === 0 && (
                  <FormFieldInfo children="No data IT to archive" />
                )}
              </Grid>
            </Grid>
          ) : (
            <Box sx={{ width: "450px", height: "103px" }} />
          )}
        </form>
      )}
    </Modal>
  );
};
