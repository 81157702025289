import { Box, Alert, Grid } from "@mui/material";
import { Card } from "../../../../components/core/Card";
import { Spinner } from "../../../../components/core/Spinner";
import { FormInput } from "../../../../components/hook-form/FormInput";
import { FormFieldLabel } from "../../../../components/hook-form/FormFieldLabel";
import { AlertMessage } from "../../../../components/core/AlertMessage";
import { useUpsertSafekeepingMappingForm } from "./useUpsertSafekeepingMappingForm";
import EditActionButtons from "../../../../components/core/EditActionButtons";

export interface UpsertSafekeepingMappingFormProps {
  id?: string;
}

export const UpsertSafekeepingMappingForm = (
  props: UpsertSafekeepingMappingFormProps
) => {
  const { id } = props;

  const {
    safekeepingMapping,
    safekeepingMappingError,
    safekeepingMappingIsFetching,
    submitSuccessMessage,
    cardTitle,
    onSubmit,
    onBack,
    submitError,
    isSubmitting,
    submitSuccessfull,
    control,
  } = useUpsertSafekeepingMappingForm({ id });

  return (
    <Card
      title={cardTitle}
      style={{ width: "1512px" }}
      actions={
        (safekeepingMapping || !id) && (
          <EditActionButtons
            form="upsert-safekeeping-mapping-form"
            loading={isSubmitting}
            onBack={onBack}
          />
        )
      }
      Component={() => (
        <Box sx={{ margin: "20px" }}>
          {safekeepingMappingError && (
            <Alert severity="error">{safekeepingMappingError}</Alert>
          )}
          {safekeepingMappingIsFetching && !safekeepingMappingError && (
            <Spinner height="353px" />
          )}
          {(safekeepingMapping || !id) && (
            <form
              id="upsert-safekeeping-mapping-form"
              data-testid="upsert-safekeeping-mapping-form"
              onSubmit={onSubmit}
            >
              <Grid container columnSpacing={2} rowSpacing={5}>
                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    name="country"
                    label={<FormFieldLabel>Country</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    name="countrySourceCode"
                    label={<FormFieldLabel>Country Source Code</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    rules={{ required: "Please insert a Country Source Code." }}
                    type="number"
                    hideArrows
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    name="countryTargetCode"
                    label={<FormFieldLabel>Country Target Code</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    rules={{ required: "Please insert a Country Target Code." }}
                    type="number"
                    hideArrows
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormInput
                    control={control}
                    name="safekeepingSwiftAddress"
                    label={
                      <FormFieldLabel>Safekeeping Swift Address</FormFieldLabel>
                    }
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
            </form>
          )}

          {submitError && (
            <Alert severity="error" sx={{ mt: "10px" }}>
              {submitError}
            </Alert>
          )}

          <AlertMessage
            show={submitSuccessfull}
            message={submitSuccessMessage}
          />
        </Box>
      )}
    />
  );
};
