import { Navigate, useLocation, useParams } from "react-router-dom";
import { Spinner } from "../../components/core/Spinner";

import { useUserPermissions } from "../../features/user/hooks/useUserPermissions";
import { AuthenticatedLayout } from "../../features/user/layouts/AuthendicatedLayout";
import { hasRoutePermission } from "../../features/user/utils/userPermissionUtils";

export interface ProtectedRouteProps {
  children: React.ReactNode;
}

export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { userPermissions } = useUserPermissions();
  const { pathname } = useLocation();
  const params = useParams();

  const path = Object.values(params).reduce(
    (path, param) => path?.replace("/" + param, ""),
    pathname
  );

  const route = path ?? pathname;
  const isAllowed = hasRoutePermission(userPermissions, route);

  const { children } = props;

  if (!userPermissions) {
    return (
      <AuthenticatedLayout>
        <Spinner width="100vw" height="calc(100vh - 264px)" />
      </AuthenticatedLayout>
    );
  }

  if (isAllowed) {
    return <div>{children}</div>;
  }

  return <Navigate to={"/"} />;
};
