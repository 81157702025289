import React, { ReactNode } from "react";
import { Table } from "../../../../../../components/core/Table";
import {
  ITableHeader,
  ITableItem,
  SortConfig,
} from "../../../../../../components/core/Table/types";

type TaxTableBaseProps = {
  items: ITableItem[];
  columns: ITableHeader[];
  sortConfig?: SortConfig;
  onRowSelect: (newSelected: ITableItem, enabled: boolean) => void;
  page: number;
  rowsPerPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  additionalHeader?: ReactNode;
};

export const TaxTableBase = (props: TaxTableBaseProps) => {
  const {
    items,
    columns,
    onRowSelect,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    additionalHeader,
  } = props;

  return (
    <Table
      items={items}
      columns={columns}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
    >
      <Table.Wrapper>
        <Table.Head
          additionalHeader={additionalHeader}
          sortConfig={{ enabled: false }}
        />
        <Table.Body readOnly onRowSelect={onRowSelect} />
      </Table.Wrapper>
    </Table>
  );
};
