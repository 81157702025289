import { LoadingButton } from "@mui/lab";
import BackButton from "../BackButton";
import { ButtonWrapper } from "../EntityTable/index.style";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";

type EditActionButtonsProps = {
  form: string;
  loading: boolean;
  onBack: () => void;
};

const EditActionButtons = ({
  form,
  loading,
  onBack,
}: EditActionButtonsProps) => {
  return (
    <ButtonWrapper>
      <LoadingButton
        variant="contained"
        endIcon={<SaveAltOutlinedIcon />}
        form={form}
        type="submit"
        sx={{ textTransform: "none" }}
        disabled={loading}
        loading={loading}
      >
        Save
      </LoadingButton>
      <BackButton onClick={onBack} />
    </ButtonWrapper>
  );
};

EditActionButtons.defaultProps = {
  form: "",
  loading: false,
  onBack: () => {},
};

export default EditActionButtons;
