/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InsertFileImportMappingDto } from '../models/InsertFileImportMappingDto';
import type { PagedModelEntityModelEarliestFile } from '../models/PagedModelEntityModelEarliestFile';
import type { PagedModelEntityModelFileImportMapping } from '../models/PagedModelEntityModelFileImportMapping';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FileImportControllerService {

    /**
     * Returns a paged list of file import records
     * @returns PagedModelEntityModelFileImportMapping Paged list of file import
     * @throws ApiError
     */
    public static getFileImportMapping({
authorization,
page,
size = 10,
sort,
search,
country,
fileName,
adjustmentName,
onlyGg,
exportName,
reconciliation,
taxformBookingCenter,
adjustmentBookingCenter,
fileBookingCenter,
bookingCenter,
importName,
id,
taxformName,
fileType,
}: {
authorization: string,
/**
 * Zero-based page index (0..N)
 */
page?: number,
/**
 * The size of the page to be returned
 */
size?: number,
/**
 * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
 */
sort?: Array<string>,
search?: string,
country?: string,
fileName?: string,
adjustmentName?: string,
onlyGg?: boolean,
exportName?: string,
reconciliation?: boolean,
taxformBookingCenter?: string,
adjustmentBookingCenter?: string,
fileBookingCenter?: string,
bookingCenter?: string,
importName?: string,
id?: number,
taxformName?: string,
fileType?: number,
}): CancelablePromise<PagedModelEntityModelFileImportMapping> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/mapping/file-import',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'page': page,
                'size': size,
                'sort': sort,
                'search': search,
                'country': country,
                'fileName': fileName,
                'adjustmentName': adjustmentName,
                'onlyGG': onlyGg,
                'exportName': exportName,
                'reconciliation': reconciliation,
                'taxformBookingCenter': taxformBookingCenter,
                'adjustmentBookingCenter': adjustmentBookingCenter,
                'fileBookingCenter': fileBookingCenter,
                'bookingCenter': bookingCenter,
                'importName': importName,
                'id': id,
                'taxformName': taxformName,
                'fileType': fileType,
            },
            errors: {
                403: `Unauthorized user`,
            },
        });
    }

    /**
     * Adds a new FileImportMapping record
     * @returns PagedModelEntityModelFileImportMapping Record added successfully
     * @throws ApiError
     */
    public static addFileImportMapping({
authorization,
requestBody,
}: {
authorization: string,
requestBody: InsertFileImportMappingDto,
}): CancelablePromise<PagedModelEntityModelFileImportMapping> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/mapping/file-import',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Unauthorized user`,
            },
        });
    }

    /**
     * Physically deletes an existing record matching the given id
     * @returns void 
     * @throws ApiError
     */
    public static deleteFileImportMapping({
authorization,
id,
}: {
authorization: string,
id: number,
}): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/mapping/file-import/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                403: `Unauthorized user`,
            },
        });
    }

    /**
     * Updates an existing FileImportMapping record matching the given id
     * @returns void 
     * @throws ApiError
     */
    public static updateFileImportMapping({
authorization,
id,
requestBody,
}: {
authorization: string,
id: number,
requestBody: InsertFileImportMappingDto,
}): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/mapping/file-import/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Unauthorized user`,
            },
        });
    }

    /**
     * Returns a paged list of earliest files to import. The list size must be 0 or 1.
     * @returns PagedModelEntityModelEarliestFile Paged list of earliest file
     * @throws ApiError
     */
    public static getEarliestFileToImport({
authorization,
id,
}: {
authorization: string,
id: number,
}): CancelablePromise<PagedModelEntityModelEarliestFile> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/mapping/file-import/{id}/earliest-file',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                403: `Unauthorized user`,
            },
        });
    }

    /**
     * Returns a paged list of file import records
     * @returns PagedModelEntityModelFileImportMapping Paged list of file import
     * @throws ApiError
     */
    public static getFileImportMappingGrouped({
authorization,
_case,
country,
fileName,
adjustmentName,
onlyGg,
exportName,
reconciliation,
taxformBookingCenter,
adjustmentBookingCenter,
fileBookingCenter,
bookingCenter,
importName,
id,
taxformName,
fileType,
}: {
authorization: string,
_case: 'IMPORT' | 'COUNTRY_TYPE',
country?: string,
fileName?: string,
adjustmentName?: string,
onlyGg?: boolean,
exportName?: string,
reconciliation?: boolean,
taxformBookingCenter?: string,
adjustmentBookingCenter?: string,
fileBookingCenter?: string,
bookingCenter?: string,
importName?: string,
id?: number,
taxformName?: string,
fileType?: number,
}): CancelablePromise<PagedModelEntityModelFileImportMapping> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/mapping/file-import/grouped',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'case': _case,
                'country': country,
                'fileName': fileName,
                'adjustmentName': adjustmentName,
                'onlyGG': onlyGg,
                'exportName': exportName,
                'reconciliation': reconciliation,
                'taxformBookingCenter': taxformBookingCenter,
                'adjustmentBookingCenter': adjustmentBookingCenter,
                'fileBookingCenter': fileBookingCenter,
                'bookingCenter': bookingCenter,
                'importName': importName,
                'id': id,
                'taxformName': taxformName,
                'fileType': fileType,
            },
            errors: {
                403: `Unauthorized user`,
            },
        });
    }

}
