import { useState, useEffect, useCallback, useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  EntityModelExemptionCode,
  EntityModelExemptionCodeMapping,
  ExemptionCodeMappingControllerService,
  ExemptionCodeMappingDto,
} from "../../../../openapi";
import authorizedCall from "../../../../utils/authorizedCall";
import { useNavigate } from "react-router-dom";

export interface UseUpsertExemptionCodeMappingFormParams {
  id?: string;
}

export interface UpsertExemptionCodeMappingFormInputs {
  exemptionCodeCountry: string;
  exemptionCodeTc: string;
  exemptionCodeId: string;
}

export const useUpsertExemptionCodeMappingForm = (
  params: UseUpsertExemptionCodeMappingFormParams
) => {
  const navigate = useNavigate();
  const [exemptionCodeMapping, setExemptionCodeMapping] =
    useState<EntityModelExemptionCodeMapping>();
  const [exemptionCodeMappingError, setExemptionCodeMappingError] =
    useState("");
  const [exemptionCodeMappingIsFetching, setExemptionCodeMappingIsFetching] =
    useState(false);
  const [exemptionCodes, setExemptionCodes] =
    useState<EntityModelExemptionCode[]>();
  const [exemptionCodesIsFetching, setExemptionCodesIsFetching] =
    useState(false);
  const [exemptionCodesError, setExemptionCodesError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccessfull, setIsSubmitSuccessfull] = useState(false);
  const [submitError, setSubmittingError] = useState("");

  const { id } = params;

  const defaultValues = useMemo(
    () => ({
      exemptionCodeCountry: "",
      exemptionCodeTc: "",
      exemptionCodeId: "",
    }),
    []
  );

  const form = useForm<UpsertExemptionCodeMappingFormInputs>({
    defaultValues,
  });

  const { reset, handleSubmit, control, setValue } = form;

  const handleBack = useCallback(() => {
    navigate("/table/exemption-code-mapping");
  }, [navigate]);

  useEffect(() => {
    if (id) {
      setExemptionCodeMappingIsFetching(true);
      authorizedCall(
        ExemptionCodeMappingControllerService.getExemptionCodeMapping,
        {
          id: Number(id),
        }
      )
        .then((data) => {
          const _exemptionCodeMapping = (
            data as EntityModelExemptionCodeMapping[]
          )[0];
          setExemptionCodeMappingIsFetching(false);
          setExemptionCodeMapping(_exemptionCodeMapping ?? {});

          reset({
            exemptionCodeId:
              "" + _exemptionCodeMapping?.exemptionCode?.id ?? "",
            exemptionCodeTc: _exemptionCodeMapping?.exemptionCodeTc,
            exemptionCodeCountry:
              _exemptionCodeMapping.exemptionCode?.country ?? "",
          });
        })
        .catch((error) => {
          setExemptionCodeMappingIsFetching(false);
          setExemptionCodeMappingError(error.message);
        });
    }

    setExemptionCodesIsFetching(true);

    authorizedCall(ExemptionCodeMappingControllerService.getExemptionCodes, {
      size: 100,
    })
      .then((data) => {
        setExemptionCodesIsFetching(false);
        setExemptionCodes(data);
      })
      .catch((err) => {
        setExemptionCodesIsFetching(false);
        setExemptionCodesError(err.body.message);
      });
  }, [id, reset]);

  useEffect(() => {
    if (submitSuccessfull && !id) {
      reset(defaultValues);
    }
  }, [reset, submitSuccessfull, defaultValues, id]);

  const exemptionCodesOptions = useMemo(() => {
    return (
      exemptionCodes?.map((ec) => {
        return {
          value: String(ec.id) ?? "",
          label: String(ec.code) ?? "",
        };
      }) ?? []
    );
  }, [exemptionCodes]);

  const submitSuccessMessage = exemptionCodeMapping
    ? "Data has been updated correctly"
    : "Record inserted";

  const cardTitle = exemptionCodeMapping
    ? "Edit Exemption Code Mapping"
    : "Add Exemption Code Mapping";

  const onExemptionCodeSelected = useCallback(
    (evt: any) => {
      const id: string = evt.target.value;
      const country =
        exemptionCodes?.find((ec) => ec.id === Number(id))?.country ?? "";
      setValue("exemptionCodeCountry", country);
    },
    [exemptionCodes, setValue]
  );

  const onSubmit: SubmitHandler<UpsertExemptionCodeMappingFormInputs> =
    useCallback(
      (data) => {
        const { exemptionCodeId, exemptionCodeTc } = data;

        setIsSubmitSuccessfull(false);
        setSubmittingError("");
        setIsSubmitting(true);

        const bodyReq: ExemptionCodeMappingDto = {
          exemptionCodeTc: exemptionCodeTc,
          exemptionCode: {
            id: Number(exemptionCodeId),
          },
        };

        if (id) {
          authorizedCall(
            ExemptionCodeMappingControllerService.updateExemptionCodeMapping,
            {
              id: Number(id),
              requestBody: bodyReq,
            }
          )
            .then(() => {
              setIsSubmitting(false);
              setIsSubmitSuccessfull(true);
            })
            .catch((err) => {
              setIsSubmitting(false);
              setSubmittingError(err.body.message);
            });
        } else {
          authorizedCall(
            ExemptionCodeMappingControllerService.addExemptionCodeMapping,
            {
              requestBody: bodyReq,
            }
          )
            .then(() => {
              setIsSubmitting(false);
              setIsSubmitSuccessfull(true);
            })
            .catch((err) => {
              setIsSubmitting(false);
              setSubmittingError(err.body.message);
            });
        }
      },

      [id]
    );

  return {
    isSubmitting,
    submitError,
    exemptionCodesError,
    submitSuccessfull,
    exemptionCodesIsFetching,
    control,
    cardTitle,
    exemptionCodesOptions,
    submitSuccessMessage,
    exemptionCodeMapping,
    exemptionCodeMappingError,
    exemptionCodeMappingIsFetching,
    setExemptionCodeMappingError,
    onExemptionCodeSelected,
    onSubmit: handleSubmit(onSubmit),
    onBack: handleBack,
  };
};
