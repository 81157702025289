import React from "react";
import { EntityTable } from "../../../../components/core/EntityTable";
import columns from "./config.json";
import { FileImportControllerService } from "../../../../openapi";

export const ImportFileMapping = (): React.ReactElement => (
  <EntityTable
    title={"TTDF Import File Mapping"}
    columns={columns}
    editRoute="/table/import-file-mapping/edit"
    addRoute="/table/import-file-mapping/add"
    deleteRoute="/table/import-file-mapping/delete"
    getService={FileImportControllerService.getFileImportMapping}
    deleteService={FileImportControllerService.deleteFileImportMapping}
  />
);

export default ImportFileMapping;
