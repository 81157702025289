import TablePagination from "@mui/material/TablePagination";
import { useTable } from "./useTable";
import { OnPageChangeCb, onPageSizeChangeCb } from "./types";
import { useCallback } from "react";

export interface PaginationProps {
  onPageChange: OnPageChangeCb;
  onPageSizeChange: onPageSizeChangeCb;
  totalItems?: number;
}

export const Pagination = (props: PaginationProps) => {
  const { onPageChange, onPageSizeChange, totalItems } = props;
  const { setPage, setRowsPerPage, page, rowsPerPage, items } = useTable();

  const handleChangePage = useCallback(
    (
      _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
      newPage: number
    ) => {
      setPage(newPage);
      if (onPageChange) {
        onPageChange(newPage);
      }
    },
    [onPageChange, setPage]
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const size = parseInt(event.target.value, 10);

      setRowsPerPage(size);
      if (onPageSizeChange) {
        onPageSizeChange(size);
      }
      setPage(0);
      if (onPageChange) {
        onPageChange(0);
      }
    },
    [onPageChange, onPageSizeChange, setPage, setRowsPerPage]
  );

  return (
    <TablePagination
      component="div"
      rowsPerPageOptions={[10, 25, 50, 100]}
      count={totalItems ?? items.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};
