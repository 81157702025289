import { columns } from "./standard-config";
import { TaxTableBase } from "../components/TaxTableBase";
import { useEntityTable } from "../../../../../components/core/EntityTable/useEntityTable";
import { getStandardTaxList } from "../services";
import { ITableItem } from "../../../../../components/core/Table/types";
import { useEffect } from "react";

type TaxFormStandardTableProps = {
  data: ITableItem[];
};

export const TaxFormStandardTable = ({ data }: TaxFormStandardTableProps) => {
  const {
    items,
    setItems,
    onRowSelect,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
  } = useEntityTable({
    getService: getStandardTaxList,
    columns,
  });

  useEffect(() => {
    setItems(data);
  }, [data, setItems]);

  return (
    <TaxTableBase
      columns={columns}
      items={items}
      onRowSelect={onRowSelect}
      page={page}
      setRowsPerPage={setRowsPerPage}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
    />
  );
};
