import { Button, Box } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Modal } from "../../../../components/core/Modal";
import BackButton from "../../../../components/core/BackButton";
import { ButtonWrapper } from "../../../../components/core/EntityTable/index.style";

export interface ConfirmModalProps {
  handleCloseModal: () => void;
  handleAbortCreation: () => void;
  openModal: boolean;
  children: React.ReactNode;
}

export const ConfirmModal = ({
  openModal,
  handleCloseModal,
  handleAbortCreation,
  children,
}: ConfirmModalProps) => {
  return (
    <Modal
      title={<Box sx={{ display: "flex", alignItems: "center" }}>TTDF</Box>}
      open={openModal}
      minWidth={500}
      handleClose={handleCloseModal}
      actions={
        <ButtonWrapper>
          <Button
            autoFocus
            variant="contained"
            color="primary"
            onClick={handleCloseModal}
            type="submit"
            form="edit-adjustment-form"
          >
            Ok
          </Button>
          <BackButton onClick={handleAbortCreation} />
        </ButtonWrapper>
      }
    >
      <Box sx={{ display: "flex", alignItems: "top" }}>
        <div style={{ margin: "0.5em" }} />
        <InfoIcon color="primary" fontSize="large" />
        <div style={{ margin: "1em" }} />
        <div>{children}</div>
      </Box>
    </Modal>
  );
};
