import { RequireAtLeastOne } from "../types/Utility";

export enum Separator {
  DOT = ".",
  COMMA = ",",
  APOSTROPHE = "'",
}

interface Options {
  decimals?: number;
  thousandsSeparator?: Separator;
  fillWithZeros?: boolean;
}

export const formatNumber = (
  n: number,
  options?: RequireAtLeastOne<Options>
) => {
  const {
    decimals,
    thousandsSeparator = Separator.APOSTROPHE,
    fillWithZeros = false,
  } = options ?? {};

  if (isNaN(n)) return "NaN";

  const thousandsRegex = /\B(?=(\d{3})+(?!\d))/g;
  const parts = n.toString().split(".");

  let truncatedDecimalPart =
    parts[1] && decimals && decimals > 0
      ? `.${parts[1].substring(0, decimals)}`
      : null;

  if (fillWithZeros) {
    if (!truncatedDecimalPart) {
      truncatedDecimalPart = ".00";
    }

    console.log(truncatedDecimalPart);

    if (truncatedDecimalPart?.length === 2) {
      truncatedDecimalPart += "0";
    }
  }

  const decimalPart = parts[1] && `.${parts[1]}`;

  const formattedIntegerPart = parts[0].replace(
    thousandsRegex,
    thousandsSeparator
  );

  return decimals === 0
    ? formattedIntegerPart
    : `${formattedIntegerPart}${truncatedDecimalPart ?? decimalPart ?? ""}`;
};
