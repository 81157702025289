/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagedModelEntityModelImportExportLog } from '../models/PagedModelEntityModelImportExportLog';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LogControllerService {

    /**
     * Returns a paged list of import or export logs
     * @returns PagedModelEntityModelImportExportLog Paged list of import or export logs
     * @throws ApiError
     */
    public static getLogs({
authorization,
type,
page,
size = 10,
sort,
search,
country,
userTimestamp,
recordType,
record,
name,
start,
recordUpdate,
end,
id,
fileTimestamp,
user,
}: {
authorization: string,
type: 'IMPORT' | 'EXPORT',
/**
 * Zero-based page index (0..N)
 */
page?: number,
/**
 * The size of the page to be returned
 */
size?: number,
/**
 * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
 */
sort?: Array<string>,
search?: string,
country?: string,
userTimestamp?: string,
recordType?: string,
record?: number,
name?: string,
start?: string,
recordUpdate?: number,
end?: string,
id?: number,
fileTimestamp?: string,
user?: string,
}): CancelablePromise<PagedModelEntityModelImportExportLog> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/log',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'page': page,
                'size': size,
                'sort': sort,
                'type': type,
                'search': search,
                'country': country,
                'userTimestamp': userTimestamp,
                'recordType': recordType,
                'record': record,
                'name': name,
                'start': start,
                'recordUpdate': recordUpdate,
                'end': end,
                'id': id,
                'fileTimestamp': fileTimestamp,
                'user': user,
            },
            errors: {
                400: `type parameter is null`,
                403: `Unauthorized user`,
            },
        });
    }

}
