import React from "react";
import { useAuth } from "react-oidc-context";

export const UnauthenticatedTemplate = (props: React.PropsWithChildren) => {
  const auth = useAuth();

  if (
    auth.activeNavigator === "signinSilent" ||
    auth.activeNavigator === "signinRedirect" ||
    auth.activeNavigator === "signoutRedirect" ||
    auth.isLoading ||
    auth.isAuthenticated
  ) {
    return <></>;
  }

  return <>{props.children}</>;
};

export const AuthenticatedTemplate = (props: React.PropsWithChildren) => {
  const auth = useAuth();

  if (!auth.isAuthenticated) {
    return <></>;
  }

  return <>{props.children}</>;
};
