import { Breadcrumbs } from "../../components/core/Breadcrumbs";
import { AdjustmentTable } from "../../features/adjustment/components/AdjustmentTable";
import AppHeader from "../../components/core/AppHeader";
import { Box } from "@mui/material";

export const Adjustment = () => (
  <Box sx={{ display: "flex" }}>
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Box sx={{ mt: 16, mb: 4 }}>
        <Box sx={{ margin: "0px 40px 0 40px", display: "flex" }}>
          <AppHeader authenticated={true} />
          <Breadcrumbs />
        </Box>
        <Box sx={{ margin: "0px 40px 0 40px" }}>
          <AdjustmentTable />
        </Box>
      </Box>
    </Box>
  </Box>
);
