import { useState, useEffect, useCallback, useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  EntityModelReconciliationMapping,
  ReconciliationMappingControllerService,
  ReconciliationMappingDto,
} from "../../../../openapi";
import { WithNull } from "../../../../types/Utility";
import authorizedCall from "../../../../utils/authorizedCall";
import { useNavigate } from "react-router-dom";

export interface useUpsertReconciliationMappingFormParams {
  id?: string;
}

export interface UpsertReconciliationMappingFormInputs {
  country: string;
  bookingCenter: string;
  fileType: string;
  account: string;
  comment: string;
  fileBookingCenter: string;
}

export const useUpsertReconciliationMappingForm = (
  params: useUpsertReconciliationMappingFormParams
) => {
  const navigate = useNavigate();
  const [reconciliationMapping, setReconciliationMapping] =
    useState<EntityModelReconciliationMapping>();
  const [reconciliationMappingError, setReconciliationMappingError] =
    useState("");
  const [reconciliationMappingIsFetching, setReconciliationMappingIsFetching] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccessfull, setIsSubmitSuccessfull] = useState(false);
  const [submitError, setSubmittingError] = useState("");

  const { id } = params;

  const defaultValues = useMemo(
    () => ({
      country: "",
      bookingCenter: "",
      fileType: "",
      account: "",
      comment: "",
      fileBookingCenter: "",
    }),
    []
  );

  const form = useForm<UpsertReconciliationMappingFormInputs>({
    defaultValues,
  });

  const { reset, handleSubmit, control } = form;

  const handleBack = useCallback(() => {
    navigate("/table/reconciliation-mapping");
  }, [navigate]);

  useEffect(() => {
    if (id) {
      setReconciliationMappingIsFetching(true);
      authorizedCall(
        ReconciliationMappingControllerService.getReconciliationMappings,
        {
          id: Number(id),
        }
      )
        .then((data) => {
          const _reconciliationMapping = (
            data as EntityModelReconciliationMapping[]
          )[0];
          setReconciliationMappingIsFetching(false);
          setReconciliationMapping(_reconciliationMapping ?? {});

          reset({
            country:
              _reconciliationMapping.country !== null
                ? _reconciliationMapping.country
                : "",
            bookingCenter:
              _reconciliationMapping.bookingCenter !== null
                ? _reconciliationMapping.bookingCenter
                : "",
            fileType:
              _reconciliationMapping.fileType !== null
                ? String(_reconciliationMapping.fileType)
                : "",
            account:
              _reconciliationMapping.account !== null
                ? _reconciliationMapping.account
                : "",
            comment:
              _reconciliationMapping.comment !== null
                ? _reconciliationMapping.comment
                : "",
            fileBookingCenter:
              _reconciliationMapping.comment !== null
                ? _reconciliationMapping.comment
                : "",
          });
        })
        .catch((error) => {
          setReconciliationMappingIsFetching(false);
          setReconciliationMappingError(error.message);
        });
    }
  }, [id, reset]);

  useEffect(() => {
    if (submitSuccessfull && !id) {
      reset(defaultValues);
    }
  }, [reset, submitSuccessfull, defaultValues, id]);

  const submitSuccessMessage = reconciliationMapping
    ? "Data has been updated correctly"
    : "Record inserted";

  const cardTitle = reconciliationMapping
    ? "Edit Reconciliation Mapping"
    : "Add Reconciliation Mapping";

  const onSubmit: SubmitHandler<UpsertReconciliationMappingFormInputs> =
    useCallback(
      (data) => {
        const {
          fileBookingCenter,
          fileType,
          country,
          bookingCenter,
          account,
          comment,
        } = data;

        setIsSubmitSuccessfull(false);
        setIsSubmitting(true);
        setSubmittingError("");

        const bodyReq: WithNull<ReconciliationMappingDto> = {
          fileBookingCenter: fileBookingCenter ? fileBookingCenter : null,
          fileType: fileType ? Number(fileType) : null,
          country: country ? country : null,
          bookingCenter: bookingCenter ? bookingCenter : null,
          account: account ? account : null,
          comment: comment ? comment : null,
        };

        if (id) {
          authorizedCall(
            ReconciliationMappingControllerService.updateReconciliationMapping,
            {
              id: Number(id),
              requestBody: bodyReq as ReconciliationMappingDto,
            }
          )
            .then(() => {
              setIsSubmitting(false);
              setIsSubmitSuccessfull(true);
            })
            .catch((err) => {
              setIsSubmitting(false);
              setSubmittingError(err.body?.message);
            });
        } else {
          authorizedCall(
            ReconciliationMappingControllerService.addReconciliationMapping,
            {
              requestBody: bodyReq as ReconciliationMappingDto,
            }
          )
            .then(() => {
              setIsSubmitting(false);
              setIsSubmitSuccessfull(true);
            })
            .catch((err) => {
              setIsSubmitting(false);
              setSubmittingError(err.body?.message);
            });
        }
      },
      [id]
    );

  return {
    isSubmitting,
    submitError,
    submitSuccessfull,
    control,
    cardTitle,
    submitSuccessMessage,
    reconciliationMapping,
    reconciliationMappingError,
    reconciliationMappingIsFetching,
    setReconciliationMappingError,
    onSubmit: handleSubmit(onSubmit),
    onBack: handleBack,
  };
};
