import React from "react";
import { AuthenticatedLayout } from "../../features/user/layouts/AuthendicatedLayout";
import { Breadcrumbs } from "../../components/core/Breadcrumbs";
import ImportFileMappingTable from "../../features/parameters/import-file-mapping/ImportFileMappingTable/ImportFileMappingTable";

export const ImportFileMapping = (): React.ReactElement => {
  return (
    <AuthenticatedLayout>
      <Breadcrumbs />
      <ImportFileMappingTable />
    </AuthenticatedLayout>
  );
};

export default ImportFileMapping;
