import { LoadingButton } from "@mui/lab";
import { Box, Alert } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Modal } from "../../../../components/core/Modal";
import BackButton from "../../../../components/core/BackButton";
import { ButtonWrapper } from "../../../../components/core/EntityTable/index.style";

export interface ConfirmModalProps {
  handleCloseModal: () => void;
  openModal: boolean;
  error: string;
  isExecuting: boolean;
  executeRes: any;
}

export const ConfirmModal = (props: ConfirmModalProps) => {
  const { openModal, handleCloseModal, error, isExecuting, executeRes } = props;

  return (
    <Modal
      title={<Box sx={{ display: "flex", alignItems: "center" }}>TTDF</Box>}
      open={openModal}
      minWidth={500}
      handleClose={handleCloseModal}
      actions={
        <ButtonWrapper>
          {!error && !executeRes && (
            <LoadingButton
              autoFocus
              variant="contained"
              color="primary"
              loading={isExecuting}
              type="submit"
              form="file-import-form"
            >
              Execute
            </LoadingButton>
          )}
          <BackButton onClick={handleCloseModal} disabled={isExecuting} />
        </ButtonWrapper>
      }
    >
      {error && (
        <Alert severity="error" sx={{ whiteSpace: "pre" }}>
          {error}
        </Alert>
      )}
      {executeRes && (
        <Box>
          {executeRes?.imported ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <InfoIcon color="primary" sx={{ mr: "5px" }} fontSize="large" />
              {executeRes?.message}
            </Box>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ErrorOutlineIcon
                color="error"
                sx={{ mr: "5px" }}
                fontSize="large"
              />
              <Box sx={{ whiteSpace: "pre" }}>{executeRes?.message}</Box>
            </Box>
          )}
        </Box>
      )}
      {!executeRes && !error && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <InfoIcon color="primary" sx={{ mr: "5px" }} fontSize="large" />
          Would you like to IMPORT the data ?
        </Box>
      )}
    </Modal>
  );
};
