import { Box, Alert, Grid } from "@mui/material";
import { Card } from "../../../../components/core/Card";
import { Spinner } from "../../../../components/core/Spinner";
import { FormInput } from "../../../../components/hook-form/FormInput";
import { FormFieldLabel } from "../../../../components/hook-form/FormFieldLabel";
import { AlertMessage } from "../../../../components/core/AlertMessage";
import { useUpsertExportParameterForm } from "./useUpsertExportParameterForm";
import { FormSelect } from "../../../../components/hook-form/FormSelect";
import EditActionButtons from "../../../../components/core/EditActionButtons";

export interface UpsertExportParameterFormProps {
  id?: string;
}

export const UpsertExportParameterForm = (
  props: UpsertExportParameterFormProps
) => {
  const { id } = props;

  const {
    exportParameterMapping,
    exportParameterMappingError,
    exportParameterMappingIsFetching,
    submitSuccessMessage,
    cardTitle,
    onSubmit,
    onBack,
    control,
    submitError,
    isSubmitting,
    submitSuccessfull,
  } = useUpsertExportParameterForm({ id });

  return (
    <Card
      title={cardTitle}
      style={{ width: "1512px" }}
      actions={
        (exportParameterMapping || !id) && (
          <EditActionButtons
            form="upsert-export-mapping-form"
            loading={isSubmitting}
            onBack={onBack}
          />
        )
      }
      Component={() => (
        <Box sx={{ margin: "20px" }}>
          {exportParameterMappingError && (
            <Alert severity="error">{exportParameterMappingError}</Alert>
          )}
          {exportParameterMappingIsFetching && !exportParameterMappingError && (
            <Spinner height="353px" />
          )}
          {(exportParameterMapping || !id) && (
            <form
              id="upsert-export-mapping-form"
              data-testid="upsert-export-mapping-form"
              onSubmit={onSubmit}
            >
              <Grid container columnSpacing={2} rowSpacing={5}>
                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="clientAccount"
                    label={<FormFieldLabel>Client Account</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="accountCurrency"
                    label={<FormFieldLabel>Account Currency</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="partecipantCode"
                    label={<FormFieldLabel>Partecipant Code</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="nameLiableAccountableParty"
                    label={
                      <FormFieldLabel>
                        Name Liable Accountable Party
                      </FormFieldLabel>
                    }
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="addressLiableAccountableParty"
                    label={
                      <FormFieldLabel>
                        Address Liable Accountable Party
                      </FormFieldLabel>
                    }
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="clientBICode"
                    label={<FormFieldLabel>Client BI Code</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="referenceOfDeclaration"
                    label={
                      <FormFieldLabel>Reference Of Declaration</FormFieldLabel>
                    }
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="codeOfLiable"
                    label={<FormFieldLabel>Code Liable</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="countryOfLiable"
                    label={<FormFieldLabel>Country of Liable</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="exportName"
                    label={<FormFieldLabel>Export Name</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    rules={{ required: "Please insert the Export Name" }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="placeOfBooking"
                    label={<FormFieldLabel>Place of Booking</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormSelect
                    control={control}
                    name="cdCode"
                    label={<FormFieldLabel>Credit / Debit Code</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    rules={{
                      required: "Please insert the Credit / Debit Code",
                    }}
                    options={[
                      { value: "CREDIT", label: "C" },
                      { value: "DEBIT", label: "D" },
                    ]}
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="euVat"
                    label={<FormFieldLabel>Eu Vat Reference</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="taxablePersonTaxNumber"
                    label={
                      <FormFieldLabel>Taxable Person Tax Number</FormFieldLabel>
                    }
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="userCreate"
                    label={<FormFieldLabel>User Create</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="dateCreate"
                    label={<FormFieldLabel>Date Create</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="userUpdate"
                    label={<FormFieldLabel>User Update</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormInput
                    control={control}
                    name="dateUpdate"
                    label={<FormFieldLabel>Date Update</FormFieldLabel>}
                    fullWidth
                    autoComplete="off"
                    disabled
                  />
                </Grid>
              </Grid>
            </form>
          )}

          {submitError && (
            <Alert severity="error" sx={{ mt: "10px" }}>
              {submitError}
            </Alert>
          )}

          <AlertMessage
            show={submitSuccessfull}
            message={submitSuccessMessage}
          />
        </Box>
      )}
    />
  );
};
