import { Grid } from "@mui/material";
import { FormSelect } from "../../../../components/hook-form/FormSelect";
import { FormFieldLabel } from "../../../../components/hook-form/FormFieldLabel";
import { SelectOption } from "../../../../components/core/Select";
import { Control } from "react-hook-form";
import { FormFieldInfo } from "../../../../components/core/FormFieldInfo/FormFieldInfo";

export interface IFileExportForm {
  handleSubmit?: () => void;
  control: Control<any>;
  formatsFieldOptions: SelectOption[];
  handleChange: () => void;
}

export const FileExportForm = (props: IFileExportForm) => {
  const { handleSubmit, control, formatsFieldOptions, handleChange } = props;

  return (
    <form
      id="file-export-form"
      data-testid="file-export-form"
      onSubmit={handleSubmit}
      style={{ marginTop: "20px" }}
    >
      <Grid container columnSpacing={2} justifyContent="center">
        <Grid item xs={6}>
          <FormSelect
            name="formatId"
            control={control}
            handleChange={handleChange}
            label={<FormFieldLabel isRequired>Choose a file</FormFieldLabel>}
            options={formatsFieldOptions}
            fullWidth
            size="small"
            autoComplete="off"
            rules={{ required: "Please select a file." }}
          />
          {formatsFieldOptions.length === 0 && (
            <FormFieldInfo children="No file to export." />
          )}
        </Grid>
      </Grid>
    </form>
  );
};
