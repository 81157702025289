import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  ReconciliationControllerService,
  EntityModelReconciliation,
  ReconciliationDto,
} from "../../../../openapi";
import { WithNull } from "../../../../types/Utility";
import authorizedCall from "../../../../utils/authorizedCall";
import { EditReconciliationFormProps } from "./EditReconciliationForm";
import {
  calculateAdjustedBalance,
  calculateDifference,
} from "../../utils/reconcililiation";

export interface EditReconciliationFormInputs {
  saldoImport: string;
  difference: string;
  dailyBalance: string;
  saldoAdjusted: string;
  offsetZrh: string;
  offsetLux: string;
  offsetSg: string;
  offsetMco: string;
  offsetHk: string;
  offsetBhs: string;
}

export const useReconciliationForm = (params: EditReconciliationFormProps) => {
  const navigate = useNavigate();
  const [reconciliation, setReconciliation] =
    useState<EntityModelReconciliation>();
  const [reconciliationError, setReconciliationError] = useState("");
  const [reconciliationIsFetching, setReconciliationIsFetching] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccessfull, setIsSubmitSuccessfull] = useState(false);
  const [submitError, setSubmittingError] = useState("");

  const { id } = params;

  const form = useForm<EditReconciliationFormInputs>({
    defaultValues: {
      saldoImport: "",
      difference: "",
      dailyBalance: "",
      saldoAdjusted: "",
      offsetZrh: "",
      offsetLux: "",
      offsetSg: "",
      offsetMco: "",
      offsetHk: "",
      offsetBhs: "",
    },
  });

  const { reset, handleSubmit, control, setValue, getValues } = form;

  const handleOffsetChange = useCallback(() => {
    const {
      offsetZrh,
      offsetLux,
      offsetSg,
      offsetMco,
      offsetHk,
      offsetBhs,
      saldoImport,
      dailyBalance,
    } = getValues();

    const newAdjustedBalance = calculateAdjustedBalance({
      zhr: offsetZrh ? Number(offsetZrh) : 0,
      lux: offsetLux ? Number(offsetLux) : 0,
      sg: offsetSg ? Number(offsetSg) : 0,
      mco: offsetMco ? Number(offsetMco) : 0,
      hk: offsetHk ? Number(offsetHk) : 0,
      bhs: offsetBhs ? Number(offsetBhs) : 0,
      dailyBalance: Number(dailyBalance),
    });

    const newDifference = calculateDifference({
      balanceImport: Number(saldoImport),
      adjustedBalance: newAdjustedBalance,
    });

    setValue("saldoAdjusted", String(newAdjustedBalance));
    setValue("difference", String(newDifference));
  }, [getValues, setValue]);

  const isFetching = reconciliationIsFetching && !reconciliationError;
  const canRender = reconciliation && !reconciliationError;

  const handleBack = useCallback(() => {
    navigate("/table/reconciliation");
  }, [navigate]);

  const getReconciliation = useCallback(() => {
    setReconciliationIsFetching(true);

    authorizedCall(ReconciliationControllerService.getReconciliations, {
      id: Number(id),
    })
      .then((data) => {
        const _reconciliation = (data as EntityModelReconciliation[])[0];
        setReconciliationIsFetching(false);
        setReconciliation(_reconciliation ?? {});

        reset({
          saldoImport: String(_reconciliation?.balanceImport ?? ""),
          difference: String(_reconciliation?.difference ?? ""),
          dailyBalance: String(_reconciliation?.dailyBalance ?? ""),
          saldoAdjusted: String(_reconciliation?.adjustedBalance ?? ""),
          offsetZrh: String(_reconciliation?.offsetZrh ?? ""),
          offsetLux: String(_reconciliation?.offsetLux ?? ""),
          offsetSg: String(_reconciliation?.offsetSg ?? ""),
          offsetMco: String(_reconciliation?.offsetMco ?? ""),
          offsetHk: String(_reconciliation?.offsetHk ?? ""),
          offsetBhs: String(_reconciliation?.offsetBhs ?? ""),
        });
      })
      .catch((error) => {
        setReconciliationIsFetching(false);
        setReconciliationError(error.message);
      });
  }, [id, reset]);

  useEffect(() => {
    getReconciliation();
  }, [getReconciliation]);

  const onSubmit: SubmitHandler<EditReconciliationFormInputs> = useCallback(
    (data) => {
      if (id) {
        const {
          offsetBhs,
          offsetHk,
          offsetLux,
          offsetMco,
          offsetSg,
          offsetZrh,
        } = data;

        setIsSubmitting(true);
        setSubmittingError("");

        const bodyReq: WithNull<ReconciliationDto> = {
          offsetBHS: Number(offsetBhs),
          offsetHK: Number(offsetHk),
          offsetLUX: Number(offsetLux),
          offsetMCO: Number(offsetMco),
          offsetSG: Number(offsetSg),
          offsetZRH: Number(offsetZrh),
        };

        authorizedCall(ReconciliationControllerService.updateReconciliation, {
          reconciliationId: Number(id),
          requestBody: bodyReq as ReconciliationDto,
        })
          .then(() => {
            getReconciliation();
            setIsSubmitting(false);
            setIsSubmitSuccessfull(true);
          })
          .catch((err) => {
            setIsSubmitting(false);
            setSubmittingError(err.body.message);
          });
      }
    },
    [getReconciliation, id]
  );

  return {
    reconciliation,
    canRender,
    submitSuccessfull,
    isSubmitting,
    submitError,
    error: reconciliationError,
    isFetching,
    control,
    onSubmit: handleSubmit(onSubmit),
    onBack: handleBack,
    handleOffsetChange,
  };
};
